import React, { Component } from 'react';
import { connect } from "react-redux";
import userAccountApi from '../../api/user-account-api';
import SurveyPageField from './survey-page-field';
import ActivityIndicator from '../activity-indicator';
// @ts-ignore
import { cloneDeep } from 'lodash';
import CategoryModal from './category-modal';
import { createUUID } from '../react-flow-pedigree/utils';
import AddSurveyModal from './add-survey-modal';

interface Props{
    surveyName: string;
    surveyId: number | null;
    surveyAction: string;
    questionList: Array<any>;
    surveyList: Array<any>;
    hideSurveyBuilderScreen: () => void;
    setSurveyList: (surveyList: Array<any>) => void;
  }

interface State{
  loading: boolean;
  survey_id: number | null;
  survey_name: string;
  surveyInfo: any;
  questionChoices: Array<QuestionChoice>;
  draggedItemIndex: {
    type: string;
    pageIndex?: number | null;
    categoryIndex?: number | null;
    questionIndex?: number | null;
  }
  categoryModalInfo: {
    showCategoryModal: boolean;
    name: string;
    label: string;
    translation_key: string;
    pageIndex?: number | null;
    categoryIndex?: number | null;
  };
  showSurveyNameModal: boolean;
}

interface QuestionChoice{
  value: string;
  label: string;
}

const page_item = {
    categories: [
        {
            id: null,
            name: 'Category 1',
            label: '',
            translation_key: '',
            questions: [
                {
                    id: null,
                    master_question_id: null,
                }
            ]
        }
    ]
}

const category_item = {
  id: null,
  name: '',
  label: '',
  translation_key: '',
  questions: [
      {
          id: null,
          master_question_id: null,
      }
  ]
}

const question_item = {
  id: null,
  master_question_id: null,
}


class SurveyBuilder extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            survey_id: null,
            survey_name: this.props.surveyName,
            surveyInfo: {
                pages: [
                    {
                      categories: [
                          {
                              id: null,
                              name: 'Category 1',
                              label: '',
                              translation_key: '',
                              questions: [
                                  {
                                      id: null,
                                      master_question_id: null,
                                  }
                              ]
                          }
                      ]
                    }
                ],
            },
            questionChoices: [],
            draggedItemIndex: {
              type: '',
              pageIndex: null,
              categoryIndex: null,
              questionIndex: null
            },
            categoryModalInfo: {
              showCategoryModal: false,
              name: '',
              label: '',
              translation_key: '',
              pageIndex: null,
              categoryIndex: null
            },
            showSurveyNameModal: false,
        };

      this.componentDidMount = this.componentDidMount.bind(this);
      this.handleAddPage = this.handleAddPage.bind(this);
      this.handleAddCategory = this.handleAddCategory.bind(this);
      this.handleAddQuestion = this.handleAddQuestion.bind(this);
      this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
      this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
      this.setShowCategoryModal = this.setShowCategoryModal.bind(this);
      this.onCategorySave = this.onCategorySave.bind(this);
      this.onPageDrop = this.onPageDrop.bind(this);
      this.onCategoryDrop = this.onCategoryDrop.bind(this);
      this.onQuestionDrop = this.onQuestionDrop.bind(this);
      this.onSave = this.onSave.bind(this);
      this.handleSurveyNameChange = this.handleSurveyNameChange.bind(this);
    //   this.fetchSurveys = this.fetchSurveys.bind(this);

    }
  async componentDidMount(){
    if(this.props.surveyAction == "edit"){
      try{
        this.setState({ loading: true, survey_id: this.props.surveyId, survey_name: this.props.surveyName })
        let pages = await userAccountApi.get_survey_info(this.props.surveyId);
        this.setState({ surveyInfo: { pages: pages } })
      }
      catch(e){
        console.log(e);
      }
      finally{
        this.setState({ loading: false });
      }
    }
    let questionChoices: Array<QuestionChoice> = [];
    this.props.questionList.map((question: any) => {
      let label = question.notification_label;
      if(!label){
        label = question.question_label;
      }
      if(!label){
        label = question.question_uuid.split('_').join(' ');
        label = label.charAt(0).toUpperCase() + label.slice(1);
      }

      questionChoices.push({value: question.id, label: label})

    })
    questionChoices = questionChoices.sort((a, b) =>
      a.label.localeCompare(b.label));
    this.setState({ questionChoices: questionChoices })
  }

//   async fetchSurveys(){
//     let payload = {
//       search_text: this.props.searchFilter.search_text
//     }
//     try{
//       let surveys = await userAccountApi.get_surveys_with_orgs(payload)
//       console.log(surveys)
//     }catch(err){
//       console.dir(err)
//     }
//   }

async onSave(){
  try {
    this.setState({ loading: true });
    let payload = cloneDeep(this.state.surveyInfo);
    payload.survey_id = this.state.survey_id;
    payload.survey_name = this.state.survey_name;

    payload.add_or_edit = this.props.surveyAction;

    let survey = await userAccountApi.survey_builder(payload);
    if (!('organizations' in survey)){
      survey.organizations = "";
    }

    let surveyList = cloneDeep(this.props.surveyList);
    if (this.props.surveyAction == "add"){
      surveyList.push(survey);
      this.props.setSurveyList(surveyList);
    }
    else{
      for (let surveyItem of surveyList){
        if (surveyItem.id == survey.id){
          surveyItem.name = survey.name;
          surveyItem.translation_key = survey.translation_key;
          surveyItem.organizations = survey.organizations;
        }
      }
      this.props.setSurveyList(surveyList);
    }
    this.props.hideSurveyBuilderScreen();
  }
  catch(e){
    console.log(e);
  }
  finally{
    this.setState({ loading: false });
  }

  {/*API ENDPOINT NOTES:
  if survey_id is null, then means it is a new survey
  if id is null or empty string for any (page, category), then it means it is a new record
  if id is not null or empty string for any (page, category), then it means it is an existing record, and should be just updated

  for questions, check if question is already existing for specific page id, and category id. if yes, do nothing, if no, create new record

  take note of orders of pages, categories, and questions

  this would be in python
  Categories would be for SurveyCategory model
    - survey_id
    - page num

  for pageIndex, page in enumerate(payload["pages"]):
    current_categories = SurveyCategory.objects.filter(survey_id=survey_id, page_num=page_num)

    for current_category in current_categories:
      category_should_still_exist = [c for c in page["categories"] if c.id == current_category.id]
      if len(should_still_exist) == 0:
        current_category.delete()

    for categoryIndex, category in enumerate(page["categories"]):
      survey_id = payload["survey_id"]
      page_num = pageIndex + 1;
      order = categoryIndex + 1;
      category_name = category["name"]
      category_label = category["label"]
      translation_key = category["translation_key"]

      if category["id"] is not None:
        saved_category = ***update category to db***
      else:
        saved_category = ***save new record category to db***

      current_questions = QuestionWorkflow.objects.filter(survey_category_id=saved_category["id"])

      for current_question in current_questions:
        question_should_still_exist = [q for q in category["questions"] if q.id == current_question.id]
        if len(question_should_still_exist) == 0:
          current_question.delete()

      for questionIndex, question in enumerate(category["questions"]):
        survey_category_id = saved_category["id"]
        master_question_id = question["id"]
        order = questionIndex + 1
        sub_order = ???
        show = ???

        if question["id"] is not None:
          ***update question to db***
        else:
          ***save new record question***


  for (let i=1; i<=.length; i++){
    for (let category of payload["pages"][i-1]["categories"]){
      survey_id = payload["survey_id"]
      page_num = i;
      category_name = category["name"]
      category_label = category["label"]
    }
  }

  or

  maybe a foreach is avai
  Pages would be a field on SurveyCategory model
  Questions would be for QuestionWorkflow model
    - survey_category_id

  */}
}

handleAddPage() {
    let surveyPages = this.state.surveyInfo.pages
    let surveyPages_copy = cloneDeep(surveyPages)
    surveyPages_copy.push(page_item)
    this.setState({ surveyInfo: { pages: surveyPages_copy } })
  }

handleAddCategory(pageIndex: number, categoryInfo: any) {
  let surveyPages = this.state.surveyInfo.pages
  let surveyPages_copy = cloneDeep(surveyPages)

  let category_item_copy = cloneDeep(category_item)
  category_item_copy.name = categoryInfo.name ? categoryInfo.name : 'Category ' + (surveyPages_copy[pageIndex].categories.length + 1)
  category_item_copy.label = categoryInfo.label ? categoryInfo.label : ''
  category_item_copy.translation_key = categoryInfo.translation_key ? categoryInfo.translation_key : ''

  surveyPages_copy[pageIndex].categories.push(category_item_copy)

  this.setState({ surveyInfo: { pages: surveyPages_copy } })
}

handleAddQuestion(pageIndex: number, categoryIndex: number) {
  let surveyPages = this.state.surveyInfo.pages
  let surveyPages_copy = cloneDeep(surveyPages)
  surveyPages_copy[pageIndex].categories[categoryIndex].questions.push(question_item)
  this.setState({ surveyInfo: { pages: surveyPages_copy } })
}

handleDeleteQuestion(pageIndex: number, categoryIndex: number, questionIndex: number) {
  let surveyPages = this.state.surveyInfo.pages
  let surveyPages_copy = cloneDeep(surveyPages)
  surveyPages_copy[pageIndex].categories[categoryIndex].questions.splice(questionIndex, 1)

  if (surveyPages_copy[pageIndex].categories[categoryIndex].questions.length === 0) {
    surveyPages_copy[pageIndex].categories.splice(categoryIndex, 1)
    if (surveyPages_copy[pageIndex].categories.length === 0) {
      surveyPages_copy.splice(pageIndex, 1)
    }
  }

  this.setState({ surveyInfo: { pages: surveyPages_copy } })
}

handleChangeQuestion(pageIndex: number, categoryIndex: number, questionIndex: number, master_question_id: any) {
  let surveyPages = this.state.surveyInfo.pages
  let surveyPages_copy = cloneDeep(surveyPages)
  surveyPages_copy[pageIndex].categories[categoryIndex].questions[questionIndex].master_question_id = master_question_id
  this.setState({ surveyInfo: { pages: surveyPages_copy } })
}

setShowCategoryModal(showCategoryModal: boolean, pageIndex?: number, categoryIndex?: number) {
  let categoryModalInfo = this.state.categoryModalInfo;
  categoryModalInfo.showCategoryModal = showCategoryModal;

  // in either add or edit category, pageIndex is required
  categoryModalInfo.pageIndex = pageIndex;

  if (pageIndex !== undefined && categoryIndex !== undefined) {
    categoryModalInfo.name = this.state.surveyInfo.pages[pageIndex].categories[categoryIndex].name;
    categoryModalInfo.label = this.state.surveyInfo.pages[pageIndex].categories[categoryIndex].label;
    categoryModalInfo.translation_key = this.state.surveyInfo.pages[pageIndex].categories[categoryIndex].translation_key;
    // in edit category, categoryIndex is required
    categoryModalInfo.categoryIndex = categoryIndex;
  }
  else{
    categoryModalInfo.name = '';
    categoryModalInfo.label = '';
    categoryModalInfo.translation_key = '';
    categoryModalInfo.categoryIndex = undefined;
  }

  this.setState({ categoryModalInfo })
}

onCategorySave(categoryInfo: any) {
  if(categoryInfo.add_or_edit == 'add' && this.state.categoryModalInfo.pageIndex !== null && this.state.categoryModalInfo.pageIndex !== undefined){
    this.handleAddCategory(this.state.categoryModalInfo.pageIndex, categoryInfo);
  }
  else{
    let surveyPages = this.state.surveyInfo.pages
    let surveyPages_copy = cloneDeep(surveyPages)
    surveyPages_copy[this.state.categoryModalInfo.pageIndex].categories[this.state.categoryModalInfo.categoryIndex].name = categoryInfo.name
    surveyPages_copy[this.state.categoryModalInfo.pageIndex].categories[this.state.categoryModalInfo.categoryIndex].label = categoryInfo.label
    surveyPages_copy[this.state.categoryModalInfo.pageIndex].categories[this.state.categoryModalInfo.categoryIndex].translation_key = categoryInfo.translation_key
    this.setState({ surveyInfo: { pages: surveyPages_copy } })
  }

  let categoryModalInfo = this.state.categoryModalInfo;
  categoryModalInfo.showCategoryModal = false;
  this.setState({ categoryModalInfo })
}

onDrag(event: any, type: string, pageIndex?: number, categoryIndex?: number, questionIndex?: number) {
  this.setState({
    draggedItemIndex: {
      pageIndex: pageIndex,
      categoryIndex: categoryIndex,
      questionIndex: questionIndex,
      type: type
    }
  })
}

onDragOver(event: any) {
  event.preventDefault();
  const currentItemPageIndex = Number(event.currentTarget.id.split('-')[0]);
  const currentItemCategoryIndex = Number(event.currentTarget.id.split('-')[1]);
  const currentItemTargetType = event.currentTarget.dataset.type;

  const draggedItemPageIndex = this.state.draggedItemIndex.pageIndex;
  const draggedItemCategoryIndex = this.state.draggedItemIndex.categoryIndex;
  const draggedItemType = this.state.draggedItemIndex.type;

  // visual restrictions
  if(currentItemTargetType !== draggedItemType) {
    event.dataTransfer.dropEffect = 'none';
    return;
  }
  else{
    if(draggedItemType === 'category'){
      if(draggedItemPageIndex !== currentItemPageIndex){
        event.dataTransfer.dropEffect = 'none';
        return;
      }
    }
    else if(draggedItemType === 'question'){
      if(draggedItemPageIndex !== currentItemPageIndex || draggedItemCategoryIndex !== currentItemCategoryIndex){
        event.dataTransfer.dropEffect = 'none';
        return;
      }
    }
  }

  event.dataTransfer.dropEffect = 'move'
}

onPageDrop(event: any){
  // restrictions would be that you can only drop to items that are of the same type (page)
  const droppedItemPageIndex = Number(event.currentTarget.id);
  const droppedItemType = event.currentTarget.dataset.type;

  const draggedItemPageIndex = this.state.draggedItemIndex.pageIndex;
  const draggedItemType = this.state.draggedItemIndex.type;

  if(draggedItemType !== droppedItemType) return;

  // @ts-ignore
  const draggedItem = this.state.surveyInfo.pages[draggedItemPageIndex]

  // Get the pages array
  let pages = [...this.state.surveyInfo.pages];

  // Remove the dragged item from its original position
  // @ts-ignore
  pages.splice(draggedItemPageIndex, 1);

  // Insert the dragged item at the new position
  pages.splice(droppedItemPageIndex, 0, draggedItem);

  // Update the state with the new pages order
  let surveyInfo = { ...this.state.surveyInfo };
  surveyInfo.pages = pages;

  this.setState({ surveyInfo });
}

onCategoryDrop(event: any){

  // restrictions would be that you can only drop to items that are of the same type (category), and that you can only drop items within the same page

  const droppedItemPageIndex = Number(event.currentTarget.id.split('-')[0]);
  const droppedItemCategoryIndex = Number(event.currentTarget.id.split('-')[1]);
  const droppedItemType = event.currentTarget.dataset.type;

  const draggedItemPageIndex = this.state.draggedItemIndex.pageIndex;
  const draggedItemCategoryIndex = this.state.draggedItemIndex.categoryIndex;
  const draggedItemType = this.state.draggedItemIndex.type;

  if(draggedItemPageIndex !== droppedItemPageIndex || draggedItemType !== droppedItemType) return;

  // @ts-ignore
  const draggedItem = this.state.surveyInfo.pages[draggedItemPageIndex].categories[draggedItemCategoryIndex];

  // Get the categories array
  let categories = [...this.state.surveyInfo.pages[droppedItemPageIndex].categories];

  // Remove the dragged item from its original position
  // @ts-ignore
  categories.splice(draggedItemCategoryIndex, 1);

  // Insert the dragged item at the new position
  categories.splice(droppedItemCategoryIndex, 0, draggedItem);

  // Update the state with the new categories order
  let surveyInfo = { ...this.state.surveyInfo };
  surveyInfo.pages[droppedItemPageIndex].categories = categories;

  this.setState({ surveyInfo });
}

onQuestionDrop(event: any){
  // restrictions would be that you can only drop to items that are of the same type (question), and that you can only drop items within the same category

  const droppedItemPageIndex = Number(event.currentTarget.id.split('-')[0]);
  const droppedItemCategoryIndex = Number(event.currentTarget.id.split('-')[1]);
  const droppedItemQuestionIndex = Number(event.currentTarget.id.split('-')[2]);
  const droppedItemType = event.currentTarget.dataset.type;

  const draggedItemPageIndex = this.state.draggedItemIndex.pageIndex;
  const draggedItemCategoryIndex = this.state.draggedItemIndex.categoryIndex;
  const draggedItemQuestionIndex = this.state.draggedItemIndex.questionIndex;
  const draggedItemType = this.state.draggedItemIndex.type;

  if(draggedItemPageIndex !== droppedItemPageIndex || draggedItemCategoryIndex !== droppedItemCategoryIndex || draggedItemType !== droppedItemType) return;

  // @ts-ignore
  const draggedItem = this.state.surveyInfo.pages[draggedItemPageIndex].categories[draggedItemCategoryIndex].questions[draggedItemQuestionIndex];

  // Get the categories array
  let questions = [...this.state.surveyInfo.pages[droppedItemPageIndex].categories[droppedItemCategoryIndex].questions];

  // Remove the dragged item from its original position
  // @ts-ignore
  questions.splice(draggedItemQuestionIndex, 1);

  // Insert the dragged item at the new position
  questions.splice(droppedItemQuestionIndex, 0, draggedItem);

  // Update the state with the new categories order
  let surveyInfo = { ...this.state.surveyInfo };
  surveyInfo.pages[droppedItemPageIndex].categories[droppedItemCategoryIndex].questions = questions;

  this.setState({ surveyInfo });
}

handleSurveyNameChange(survey_name: string){
  this.setState({ survey_name })
}

render() {
    return(
      <React.Fragment>

        {this.state.loading && <ActivityIndicator loading={this.state.loading} modal={true} />}

        <div className="row margin-one"></div>
        <div className="row vcenter">
            <div className="col-md-6 col-xs-12" style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ color: '#6A358A' }}>{this.state.survey_name}</h2>
                <a style={{ marginLeft: 10 }} onClick={() => this.setState({ showSurveyNameModal: true })}>
                    <i className="fa fa-lg fa-edit"></i>
                </a>
            </div>
            <div className="col-md-4 col-md-offset-2 justify-right hidden"></div>
        </div>

        {this.state.surveyInfo.pages.map((page: any, pageIndex: number) => {
            return (
              <div key={createUUID()}>
                <div className="row margin-one"></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SurveyPageField
                    page={page}
                    handleAddQuestion={(categoryIndex: number) => this.handleAddQuestion(pageIndex, categoryIndex)}
                    handleDeleteQuestion={(categoryIndex: number, questionIndex: number) => this.handleDeleteQuestion(pageIndex, categoryIndex, questionIndex)}
                    handleChangeQuestion={(categoryIndex: number, questionIndex: number, master_question_id: number) => this.handleChangeQuestion(pageIndex, categoryIndex, questionIndex, master_question_id)}
                    setShowCategoryModal={(showCategoryModal: boolean, categoryIndex?: number) => this.setShowCategoryModal(showCategoryModal, pageIndex, categoryIndex)}
                    // totalSets={this.state.filterSets.length}
                    pageIndex={pageIndex}
                    questionChoices={this.state.questionChoices}
                    onDragOver={(event: any) => this.onDragOver(event)}
                    onDrag={(event, type, pageIndex, categoryIndex, questionIndex) => this.onDrag(event, type, pageIndex, categoryIndex, questionIndex)}
                    onCategoryDrop={(event: any) => this.onCategoryDrop(event)}
                    onQuestionDrop={(event: any) => this.onQuestionDrop(event)}
                    // showDeleteButtonOnSetOne={showDeleteButtonOnSetOne}
                    // onChangeSet={(andOr) => this.handleChangeSet(andOr, setIndex)}
                    // onChangeItem={(item, itemIndex) => this.handleFilterChange(setIndex, itemIndex, item)}
                    // onAddItemClick={() => this.handleAddItem(setIndex)}
                    // onDeleteItemClick={(itemIndex) => this.handleDeleteItem(setIndex, itemIndex)}
                  />
                  <a
                    data-type="page"
                    id={`${pageIndex}`}
                    onDragOver={(event: any) => this.onDragOver(event)}
                    onDrop={(event) => this.onPageDrop(event)}
                    onDrag={(event) => this.onDrag(event, 'page', pageIndex)}
                    style={{ marginLeft: 15 }}
                    draggable>
                    <svg className="list-icon" xmlns="http://www.w3.org/2000/svg" height="3em" width="3em" viewBox="0 0 180 600">
                      <path d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z" />
                    </svg>
                  </a>
                </div>

              </div>
            )
        })}

        {/* need icon for drag and drop here */}

        <div className="margin-two">
            <a onClick={this.handleAddPage} className="btn btn-teal btn-xs">Add Page</a>
        </div>

        <div className="section-footer">
          <button
            onClick={() => this.onSave()}
            className={"btn btn-dark btn-sm"} ><i className="fa fa-arrow-right text-white"></i>Save</button>
          <button
            style={{marginRight: -5}}
            onClick={() => this.props.hideSurveyBuilderScreen()}
            className={"btn btn-light btn-sm"} ><i className="fa fa-arrow-left text-white"></i>Cancel</button>
        </div>

        {this.state.categoryModalInfo.showCategoryModal &&
          <CategoryModal
            title='Add Category'
            name={this.state.categoryModalInfo.name}
            label={this.state.categoryModalInfo.label}
            translation_key={this.state.categoryModalInfo.translation_key}
            add_or_edit={this.state.categoryModalInfo.pageIndex !== null &&
              this.state.categoryModalInfo.pageIndex !== undefined &&
              this.state.categoryModalInfo.categoryIndex !== null &&
              this.state.categoryModalInfo.categoryIndex !== undefined ? 'edit' : 'add'}
            onClose={() => {
              let categoryModalInfo = this.state.categoryModalInfo;
              categoryModalInfo.showCategoryModal = false;
              this.setState({ categoryModalInfo })
            }}
            onCategorySave={(categoryInfo: any) => this.onCategorySave(categoryInfo)}
          />
        }

        {this.state.showSurveyNameModal &&
          <AddSurveyModal
            title='Edit Survey Name'
            surveyName={this.state.survey_name}
            onClose={() => this.setState({ showSurveyNameModal: false })}
            continueAddSurvey={(surveyName) => this.handleSurveyNameChange(surveyName)}
          />
        }
      </React.Fragment>

    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyBuilder);
