import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import Dropdown from 'react-dropdown';
import { createUUID } from "./react-flow-pedigree/utils";
import CreatableSelect from 'react-select/creatable';
import umlsApi from "../api/umls-api";
import gene_api from "../api/genetic-testing-api";
import ErrorSummary from "./error-summary";

const PanelMap = new Map();

const EditCustomPanel = ({ gene_labs, onClose, onSave }) => {
  const [modalContainer] = useState(() => document.createElement("div"));
  const [geneList, setGenes] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [labList, setLabs] = useState([{ value: '', label: '' }]);
  const [labPanels, setOrgPanels] = useState([{ value: '', label: '' }]);
  const [newPanelName, setNewPanelName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLab, setSelectedLab] = useState({ value: '', label: '' });
  const [verifyingGenes, setVerifcationMessage] = useState(false);
  const [previousPanel, setPreviousPanel] = useState({ value: '', label: '' });

  const [matchingCustomGenes, setMatchingCustomGenes] = useState([]);
  const [unmatchedCustomGenes, setUnmatchedCustomGenes] = useState([]);
  const [totalGenesChecked, setTotalGenesChecked] = useState(0);
  const [validatedGenes, setValidatedGenes] = useState(0);
  const contentEditableRef = useRef(null);

  const gene_list_placeholder = "Enter genes separated by a comma, space or one gene on each line.";
  

  useEffect(() => {
    document.body.appendChild(modalContainer);

    return () => {
      document.body.removeChild(modalContainer);
    };
  }, [modalContainer]);


  useEffect(() => {
    const fetchCustomPanels = async () => {
      let org_panels = {};
      try {
        org_panels = await gene_api.get_custom_panels();
      } catch (err) {
        console.error(err);
      }finally{
        const panelList = Object.values(org_panels).map((panel) => ({
          label: panel.panel_name,
          value: panel.panel_id,
        }));

        Object.values(org_panels).forEach((panel) => {
          if (!PanelMap.has(panel.panel_name)) {
            PanelMap[panel.panel_name] = {
              genes: panel.genes,
              labs: panel.labs
            };
          }
        });
        setOrgPanels(panelList)
      }
    };

    if (gene_labs) {
      setLabs(gene_labs.map((lab) => ({ value: lab.id, label: lab.lab })));
    }
  
    fetchCustomPanels();

  }, []);

  useEffect(() => {
    setVerifcationMessage(false);
    setShowResults(false);
  },[previousPanel, geneList]);


  const selectPreviousPanel = (e) => {
    let panelName = e.label;
    let value = e.value;
    let previousLab = null
    let selectedPanel = PanelMap[panelName];
    if(!selectedPanel) resetPanel();
    else{
      let genes = selectedPanel.genes;
      let prevLab = selectedPanel.labs;
      previousLab = labList.find((lab) => prevLab === lab.value);
      createGenestring(genes);
      setCurrentPanel(previousLab, { value: value, label: panelName }, panelName);
    }

  };

  const resetPanel = () => {
    setGenes('');
    setSelectedLab({ value: '', label: '' });
    setPreviousPanel({ value: '', label: '' });
    setNewPanelName('');
  }
  
  const setCurrentPanel = (prevLab, previousPanel, panelName) => {
    setSelectedLab(prevLab);
    setPreviousPanel(previousPanel);
    setNewPanelName(panelName);
  }

  const createGenestring = (genes) => {
    if (genes && Array.isArray(genes)) {
      let geneString = genes.map((item) => item.gene).join(', ');
      setGenes(geneString);
    } else {
      setGenes('');
    }
  };

  

  const formatNewLab = (lab)=>{
    return lab.toLowerCase().replace(/\s+/g, '_');
  }

  const closePanel = () => {
    setShowResults(false);
    onClose();
  }

  const selectLab = (e) => {
    if (!e) {
      setSelectedLab({ value: '', label: ''});
      return
    }
    let labLabel = e.label;
    let labValue = (e && e.__isNew__) ? formatNewLab(e.value): e.value;
    let lab = {label: labLabel, value: labValue};

    if(e && e.__isNew__) setLabs([...labList, lab]);
    
    setSelectedLab(lab);
  };

  const updateNewPanelName = (e) => {
    setNewPanelName(e.target.value);
  };


  const verifyGenes = async () => {
    let geneList = contentEditableRef.current.innerText || '';
    if (geneList === '') setShowResults(false);
    let finalList = geneList.trim().replace(/,+\s*$/, "");
    setShowResults(false);
    setVerifcationMessage(true);
    setGenes(finalList);

    const { matching_genes, unmatched_genes, totalGenes, validatedGenes } = await geneListCheck(finalList);
    updateGeneStateInfo(matching_genes, unmatched_genes, totalGenes, validatedGenes);
  };

  const updateGeneStateInfo = ( matching_genes, unmatched_genes, totalGenes, validatedGenes) => {
    setShowResults(true);
    setMatchingCustomGenes(matching_genes)
    setValidatedGenes(validatedGenes);
    setUnmatchedCustomGenes(unmatched_genes);
    setTotalGenesChecked(totalGenes);
  };

  const setPlaceholderStyles = () => {
    contentEditableRef.current.style.color = "#a9a9a9";
  }

  const setTextStyles = () => {
    contentEditableRef.current.style.color = "#626262";
  }

  const updateGeneList = () => {
    let geneList = contentEditableRef.current.innerText || '';
    let finalList = geneList.trim().replace(/,+\s*$/, "");
    setTextStyles();
    if (finalList === '') {
      setShowResults(false);
      contentEditableRef.current.innerText = gene_list_placeholder;
      setPlaceholderStyles();
    }
    setGenes(finalList);
  }

  const focusedGeneList = () => {
    let geneList = contentEditableRef.current.innerText || '';
    if (geneList === gene_list_placeholder) {
      contentEditableRef.current.innerText = "";
      setTextStyles();
    }
  }

  const saveNewGenePanel = async () => {
    const panelTrimmed = newPanelName?.trim();
  
    // Validate inputs
    const isInvalidInput =
      (selectedLab && !selectedLab.value) &&
      (previousPanel && !previousPanel.value);
  
    if (isInvalidInput) {
      setErrorMessage('Please verify lab and/or test name');
      return;
    }
  
    try {
      const payload = {
        lab: selectedLab?.label?.trim(),
        editPreviousPanel: previousPanel?.value,
        panel_name: panelTrimmed,
      };
  
      if (!validatedGenes) {
        const {
          matching_genes,
          unmatched_genes,
          totalGenes,
          validatedGenes: newValidatedGenes,
        } = await geneListCheck(geneList);
  
        if (unmatched_genes.length > 0) {
          updateGeneStateInfo(matching_genes, unmatched_genes, totalGenes, validatedGenes);
          return;
        }

        payload.genes = matching_genes;
      } else {
        payload.genes = matchingCustomGenes;
      }
  
      saveGenePanel(payload);
      } catch (err) {
      setErrorMessage(err.message);
    }
  };
  
  const saveGenePanel = (payload) => {
    setErrorMessage('');
    onSave(payload);

  };
  

  const getGenesText = (text) => {
    if(text && text.trim() === '') return text;
    let genes = text.split(/[\s,]+/) // Assuming text contains comma-separated values

    // Extract the gene values from unmatchedCustomGenes
    const genesToHighlight = unmatchedCustomGenes.map(g => g.gene.trim());
    const length = genes.length - 1;

    return genes.map((word, i) => {
      let comma = i === length ? '' : ',';
      return genesToHighlight.includes(word.trim()) ? (
        <span key={i} style={{ color: 'red' }}>{word.trim()}{comma} </span>
      ) : (
        <span key={i}>{word.trim()}{comma} </span>
      );
    });
  };


  const geneListCheck = async (geneList) => {
    const payload = { geneList };
    try {
      const response = await umlsApi.search_umls_panel_genes(payload);
      
      return response
    } catch (error) {
      console.error(error);
    }
  };


  const modalContent = (
    <React.Fragment>
      <div style={{ display: 'block', overflow: 'auto' }} className="modal fade in" role="dialog">
        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={closePanel} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Add Custom Test</h4>
            </div>
          
            <div className="modal-body">
            {errorMessage && (
              <ErrorSummary errorMessages={[errorMessage]} />
              )}
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Choose Test to Edit or Add New Below</label>
                    <Dropdown
                      options={[{ label: ' ', value: ' ' }, ...labPanels]}
                      onChange={selectPreviousPanel}
                      value={previousPanel}
                      placeholder="&nbsp;"
                    />
                  </div>
                </div>
                {previousPanel.value && (
                  <div class="col-md-5">
                    <p style={{marginTop: 22, fontSize: 13}}>Editing a lab or test name will update previously entered tests. Updating genes will only be applied to new tests entered.</p>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label>Lab</label>
                    <CreatableSelect
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={selectedLab ? true : false}
                        options={[ ...labList]}
                        onChange={(e)=>selectLab(e)}
                        value={selectedLab}
                        placeholder="&nbsp;"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Test</label>
                    <input
                      type="text"
                      onChange={updateNewPanelName}
                      value={newPanelName}
                      placeholder="Enter Panel Name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <div className="form-group">
                  <label>Genes</label>
                    <div
                      key={createUUID()}
                      contentEditable
                      ref={contentEditableRef}
                      onBlur={updateGeneList}
                      onFocus={focusedGeneList}
                      style={{ border: '1px solid #ccc', padding: '10px', minHeight: '100px', color: (geneList === "") ? "#a9a9a9" : "#626262" }}
                      suppressContentEditableWarning={true}
                      >
                      {(geneList === "") ? gene_list_placeholder : ""}
                      {getGenesText(geneList)}
                    </div>
          
                  </div>
                </div>

                {showResults && (
                  <div className="col-md-4">
                    <div className="umatched_genes">
                      <label className="umatched_genes">Results:</label>
                      <p className="umatched_genes">Validated {validatedGenes} out of {totalGenesChecked}</p>
                      {validatedGenes === totalGenesChecked ? (
                        <p className="all_genes_matched">All genes successfully validated</p>
                      ) : (
                        <>
                          <p className="unmatched_genes unmatched_header">The following genes could not be validated:</p>
                          {unmatchedCustomGenes.map((gene) => (
                            <div className="unmatched_genes" key={createUUID()}>
                              <p style={{ color: 'red', margin: 0, padding: 0 }}>{gene.gene}</p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {!showResults&& verifyingGenes && (
                  <div className="verifying-genes">
                    Verifying Genes...
                  </div>
                )}
              </div>
              <button type="button" onClick={verifyGenes} className="btn btn-teal btn-xs" data-dismiss="modal">Validate Genes</button>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={closePanel} className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={saveNewGenePanel} className="btn btn-dark" data-toggle="modal" data-dismiss="modal">Save</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return ReactDOM.createPortal(modalContent, modalContainer);
};

export default EditCustomPanel;

