import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { components } from 'react-select';

interface SurveyModalProps{
    onClose: () => void;
    continueAddSurvey: (title: string) => void;
    title: string;
    surveyName: string;
  }

interface State{
    surveyName: string;
  }

class AddSurveyModal extends Component<SurveyModalProps, State> {
    constructor(props: SurveyModalProps) {
        super(props);
        this.state ={
          surveyName: this.props.surveyName
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
      }

    handleChange(e: any) {
        // @ts-ignore
        this.setState({ [e.target.name]: e.target.value })
    }

    onSave(){
        this.props.continueAddSurvey(this.state.surveyName);
        this.props.onClose();
    }


render(){
    return ReactDOM.createPortal(
        <div
        onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        >

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
            </div>

            <div className="modal-body">
            <h6 className="modal-title text-white text-capitalize" style={{color: 'grey', fontSize: '13px'}}>Survey Name</h6>
                <div className="modal-confirm">
                    <input
                    name="surveyName"
                    value={this.state.surveyName}
                    type="text"
                    onChange={this.handleChange}
                    />
                </div>
            </div>
            <div className="modal-footer" style={{textAlign: 'center'}}>
              <button onClick={() => this.props.onClose()} className="btn btn-light-outline no-margin-right">Cancel</button>
              <button onClick={this.onSave} className="btn btn-dark" data-dismiss="modal" aria-label="Close">Continue</button>
            </div>
          </div>
        </div>
    </div>,
      document.body
    );
}

}


export default AddSurveyModal;
