import React, { Component, useState, useEffect } from 'react';
import disease_api from "../../api/disease-api";
import authentication from '../../api/authentication';


interface AccountCustomizationProps extends React.PropsWithChildren<{}> {
  org_id: string;
}

const AccountCustomizations: React.FC<AccountCustomizationProps> = ({org_id}) =>{
    const [diseaseFile, setDiseaseFile] = useState({})
    const [diseaseFileName, setDiseaseFileName] = useState("");

    const [samlFileName, setSamlFileName] = useState("");
    const [samlCert, setSamlCert] = useState("");

    const [sp, setSp] = useState("");
    const [idp, setIdp] = useState("");
    const [sso, setSso] = useState("");
    const [acs, setAcs] = useState("");
    const [redirect, setRedirect] = useState("");

    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [scope, setScope] = useState("");
    const [provider, setProvider] = useState("");
    const [pkceEnabled, setPkceEnabled] = useState(false);

    const [successMessage, setSuccessMessage] = useState(""); 

    const [activeTab, setActiveTab] = useState("custom-disease-upload");

    const [error, setError] = useState("");

    useEffect(() => {
      if (activeTab === "saml-config") {
        const fetchSamlProvider = async () => {
            try {
                const response = await authentication.get_saml_provider_by_id({"org_id": org_id});
                if (response) {
                    const { entity_sp, entity_idp, sso_url, acs_url, redirect_url, saml_cert } = response;
                    setSp(entity_sp || "");
                    setIdp(entity_idp || "");
                    setSso(sso_url || "");
                    setAcs(acs_url || "");
                    setRedirect(redirect_url || "");
                    setSamlCert(saml_cert || "");
                    setSamlFileName(saml_cert || "");
                }
            } catch (err) {
                setError("Error fetching SAML provider data: " + err);
                console.error(err);
            }
        };

        fetchSamlProvider();
    }

    if (activeTab === "oauth-config") {
      const fetchOauthProvider = async () => {
          try {
              const response = await authentication.get_oauth_provider_by_id({"org_id": org_id});
              if (response) {
                  const { client_id, client_secret, scope, provider, pkce_enabled } = response;
                  setClientId(client_id || "");
                  setClientSecret(client_secret || "");
                  setScope(scope || "");
                  setProvider(provider || "");
                  setPkceEnabled(pkce_enabled || false);
              }
          } catch (err) {
              setError("Error fetching OAuth provider data: " + err);
              console.error(err);
          }
      };

      fetchOauthProvider();
    }
    },[org_id, activeTab]);

    const handleDiseseListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const selectedFile = event.target.files?.[0]; // Get the selected file
        if (!selectedFile) {
            console.error("No file selected");
            return;
        }

        setDiseaseFileName(selectedFile.name); // Update state with file namz
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
            const csvContent = loadEvent.target?.result as string;
            const jsonData = parseCSV(csvContent);
            setDiseaseFile(jsonData);
        };
    
        reader.readAsText(selectedFile);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0]; 
      if (selectedFile) {
        setSamlFileName(selectedFile.name);
        const reader = new FileReader();
        reader.onload = () => {
            const fileContent = reader.result as string;
        
            const certificate = extractCert(fileContent); 
            setSamlCert(certificate); 
        };
    
        reader.readAsText(selectedFile);
      }
    };

    const parseCSV = (csvContent: string) => {
        const lines = csvContent.trim().split("\n"); 
        const headers = lines[0].split(",").map(header => header.trim());
    
        return lines.slice(1).map(line => {
            const values = line.split(",").map(value => value.trim()); // Trim each value
            const entry: Record<string, string> = {};
    
            headers.forEach((header, index) => {
                entry[header] = values[index] || ""; // Assign value or empty string if missing
            });
    
            return entry;
        });
    };
    
    const extractCert = (certString: any) => {
        const cleanCert = certString.trim().replace(/\r\n/g, '\n');  // Ensure consistent line breaks

        // Regex to match the certificate
        const regex = /-----BEGIN CERTIFICATE-----\s*([\s\S]*?)\s*-----END CERTIFICATE-----/;
      
        // Match the certificate content
        const match = cleanCert.match(regex);
        console.log('match', match)
        if (match) {
          return match[1].trim();  // Return the certificate content without BEGIN/END markers
        } else {
          throw new Error('Invalid certificate format');
        }
    };

    const handleDiseseListSubmit = async() => {
      try{
        let input = (document.getElementById('custom-disease-upload-file') as HTMLInputElement)?.value;

        if (input == "") {
          setError("Please provide a valid custom disease list file");
          return;
        }
        
        let payload = {
            org_id: org_id,
            custom_disease_list: diseaseFile
          }
          
        await disease_api.upload_disease_file(payload);

        setSuccessMessage('Disease list uploaded successfully');
        setError("");
        setTimeout(() => setSuccessMessage(''), 5000);

      }catch(err){
        setError("Error uploading disease list: " + err);
        console.log(err);
      }

    };

    const handleSamlProviderSubmit = async() => {
      try{

        if (samlCert == "" || sp == "" || idp == "" || sso == "" || acs == "") {
          setError("Please fill in Entity SP, Entity IDP, SSO URL, ACS URL, and Certificate fields");
          return;
        }

        let payload = {
          org_id: org_id,
          saml_cert: samlCert,
          entity_sp: sp,
          entity_idp: idp,
          sso_url: sso,
          acs_url: acs,
          redirect_url: redirect || ""
        }

        await authentication.upload_saml_provider(payload);

        setSuccessMessage('Saml provider uploaded successfully');
        setError("");
        setTimeout(() => setSuccessMessage(''), 5000);

      }catch(err){
        setError("Error uploading saml provider: " + err);
        console.log(err);
      }
    }

    const handleOauthProviderSubmit = async() => {
      try{

        if (clientId == "" || clientSecret == "" || scope == "") {
          setError("Please fill in Client ID, Client Secret, and Scope fields");
          return;
        }

        let payload = {
          org_id: org_id,
          client_id: clientId,
          client_secret: clientSecret,
          scope: scope,
          provider: provider,
          pkce_enabled: pkceEnabled
        }

        await authentication.upload_oauth_provider(payload);

        setSuccessMessage('Oauth provider uploaded successfully');
        setError("");
        setTimeout(() => setSuccessMessage(''), 5000);

      }catch(err){
        setError("Error uploading oauth provider: " + err);
        console.log(err);
      }
    }

    return (
    <div>
        <ul className="nav nav-tabs">
          <li className={activeTab === "custom-disease-upload" ? "active" : ""}>
              <button className="btn-link" onClick={() => {
                setActiveTab("custom-disease-upload");
                setError("");
                }}>
                  Custom Disease Upload
              </button>
          </li>
          <li className={activeTab === "saml-config" ? "active" : ""}>
              <button className="btn-link" onClick={() => {
                setActiveTab("saml-config");
                setError("");
                }}>
                  Saml Config
              </button>
          </li>
          <li className={activeTab === "oauth-config" ? "active" : ""}>
              <button className="btn-link" onClick={() => {
                setActiveTab("oauth-config");
                setError("");
                }}>
                  Oauth Config
              </button>
          </li>
        </ul>
      <div className="custom-disease-upload-and-saml-configs">
        {activeTab === "custom-disease-upload" &&
          <div className="custom-disease-upload-container">
            <h3>Upload a Custom Disease List</h3>
            <p>Expected column headings: disease_type, disease_name, short_name, gender_specific, umls_id</p>
            <p style={{color:'green'}}>
            {successMessage}
            </p>
            <div className="validation-inline__error-text validation-warning-age-member-info">
              {error}
            </div>

           <div className="custom-disease-upload-row">
              <div className="custom-disease-upload-col">
                <span className="custom-disease-upload-control-fileupload">
                  <label className="custom-disease-upload-file-label">
                    <br />
                      <input
                        name="file"
                        id="custom-disease-upload-file"
                        type="file"
                        className="custom-disease-upload-file-input"
                       onChange={(event) => handleDiseseListChange(event)}
                     />
               </label>
                </span>
              </div>

              <div className="custom-disease-upload-col-submit">
                <button type="button" onClick={handleDiseseListSubmit} className="custom-disease-upload-submit-btn">
                 Submit
                </button>
              </div>
           </div>
         </div>
        }

        {activeTab === "saml-config" &&
          <div className="saml-configs-container">
            <h4>Saml Configs</h4>
            <p style={{color:'green'}}>
            {successMessage}
            </p>
            <div className="validation-inline__error-text validation-warning-age-member-info">
              {error}
            </div>
            <label className="saml-configs-entity-sp-label">Entity SP</label>
            <input id="saml-configs-entity-sp" type="text" className="saml-configs-input" value={sp} onChange={(e) => setSp(e.target.value)} />
            <label className="saml-configs-entity-idp-label">Entity IDP</label>
            <input id="saml-configs-entity-idp" type="text" className="saml-configs-input" value={idp} onChange={(e) => setIdp(e.target.value)} />
            <label className="saml-configs-entity-sso-url-label">SSO URL</label>
            <input id="saml-configs-entity-sso-url" type="text" className="saml-configs-input" value={sso} onChange={(e) => setSso(e.target.value)} />
            <label className="saml-configs-entity-acs-url-label">ACS URL</label>
            <input id="saml-configs-entity-acs-url" type="text" className="saml-configs-input" value={acs} onChange={(e) => setAcs(e.target.value)} />
            <label className="saml-configs-redirect-url-label">Redirect URL</label>
            <input id="saml-configs-redirect-url" type="text" className="saml-configs-input" value={redirect} onChange={(e) => setRedirect(e.target.value)} />
            <label className="saml-configs-certificate-label">Certificate</label>
            <span className="saml-configs-control-fileupload">
              <label htmlFor="file" className="saml-configs-file-label">
                {samlFileName ? "Uploaded file name: " + samlFileName : "Please choose a file on your computer."}
                    <input
                      name="file"
                      id="saml-configs-file"
                      type="file"
                      className="saml-configs-file-input"
                      onChange={handleFileChange}
                    />
                </label>
            </span>
            <div className="custom-disease-upload-col-submit">
                <button type="button" onClick={handleSamlProviderSubmit} className="custom-disease-upload-submit-btn">
                  Submit
                </button>
              </div>
          </div>
        }

        {activeTab === "oauth-config" &&
          <div className="oauth-configs-container">
            <h4>OAuth Configs</h4>
            <p style={{color:'green'}}>
            {successMessage}
            </p>
            <div className="validation-inline__error-text validation-warning-age-member-info">
              {error}
            </div>
            <label className="oauth-configs-client-id-label">Client ID</label>
            <input id="oauth-configs-client-id" type="text" className="oauth-configs-input" value={clientId} onChange={(e) => setClientId(e.target.value)} />
            <label className="oauth-configs-client-secret-label">Client Secret</label>
            <input id="oauth-configs-client-secret" type="text" className="oauth-configs-input" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} />
            <label className="oauth-configs-scope-label">Scope</label>
            <input id="oauth-configs-scope" type="text" className="oauth-configs-input" value={scope} onChange={(e) => setScope(e.target.value)} />
            <label className="oauth-configs-provider-label">Provider</label>
            <select id="oauth-configs-provider" className="oauth-configs-input" value={provider} onChange={(e) => setProvider(e.target.value)} >
              <option value="">Select a provider</option>
              <option value="microsoft">Microsoft</option>
            </select>
            <label className="oauth-configs-pkce-enabled-label">
              PKCE Enabled
              <input id="oauth-configs-pkce-enabled" type="checkbox" className="oauth-configs-checkbox" checked={pkceEnabled} onChange={(e) => setPkceEnabled(e.target.checked)}/>
            </label>
            <div className="custom-disease-upload-col-submit">
              <button type="button" onClick={handleOauthProviderSubmit} className="custom-disease-upload-submit-btn">
                Submit
              </button>
            </div>
          </div>
        }
      </div>
    </div>

    );
  }

export default AccountCustomizations;
