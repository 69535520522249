import React, { Component } from 'react';
import { connect } from "react-redux";
import SurveyCategoryField from './survey-category-field';
import { createUUID } from '../react-flow-pedigree/utils';

interface Props {
  pageIndex: number;
  page: any;
  questionChoices: Array<any>;
  handleAddQuestion: (categoryIndex: number) => void;
  handleDeleteQuestion: (categoryIndex: number, questionIndex: number) => void;
  handleChangeQuestion: (categoryIndex: number, questionIndex: number, master_question_id: number) => void;
  setShowCategoryModal: (showCategoryModal: boolean, categoryIndex?: number) => void;
  onDragOver: (event: any) => void;
  onDrag: (event: any, type: string, pageIndex?: number, categoryIndex?: number, questionIndex?: number) => void;
  onCategoryDrop: (event: any) => void;
  onQuestionDrop: (event: any) => void;
}

class SurveyPageField extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <React.Fragment>
        <div className="panel-form-3" style={{ width: '100%' }}>
          {this.props.page.categories.map((category: any, categoryIndex: number) => {
            return (
              <div key={createUUID()}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <SurveyCategoryField
                    category={category}
                    categoryIndex={categoryIndex}
                    pageIndex={this.props.pageIndex}
                    totalCategories={this.props.page.categories.length}
                    questionChoices={this.props.questionChoices}
                    handleAddQuestion={(categoryIndex) => this.props.handleAddQuestion(categoryIndex)}
                    handleDeleteQuestion={(categoryIndex, questionIndex) => this.props.handleDeleteQuestion(categoryIndex, questionIndex)}
                    handleChangeQuestion={(categoryIndex, questionIndex, master_question_id) => this.props.handleChangeQuestion(categoryIndex, questionIndex, master_question_id)}
                    setShowCategoryModal={(showCategoryModal: boolean) => this.props.setShowCategoryModal(showCategoryModal, categoryIndex)}
                    onDragOver={(event: any) => this.props.onDragOver(event)}
                    onQuestionDrop={(event) => this.props.onQuestionDrop(event)}
                    onDrag={(event, type, pageIndex, categoryIndex, questionIndex) => this.props.onDrag(event, type, pageIndex, categoryIndex, questionIndex)}
                  />
                  <a
                    data-type="category"
                    id={`${this.props.pageIndex}-${categoryIndex}`}
                    onDragOver={(event: any) => this.props.onDragOver(event)}
                    onDrop={(event) => this.props.onCategoryDrop(event)}
                    onDrag={(event) => this.props.onDrag(event, 'category', this.props.pageIndex, categoryIndex)}
                    draggable>
                    <svg className="list-icon" xmlns="http://www.w3.org/2000/svg" height="2em" width="2em" viewBox="0 0 180 600">
                      <path d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z" />
                    </svg>
                  </a>
                </div>
              </div>
            )
          })
          }

          <div className="margin-two">
            <a onClick={() => this.props.setShowCategoryModal(true)} className="btn btn-teal btn-xs">Add Category</a>
          </div>
        </div>
      </React.Fragment>

    );
  };
}

const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
  dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyPageField);
