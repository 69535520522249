import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { cloneDeep, isEmpty } from "lodash";
import 'custom-event-polyfill'
import ControlSelectDiseases from './control-select-diseases';

import diseasesCancers from "../assets/json/diseases-cancer.json";
import diseasesOthers from "../assets/json/diseases-others.json";

import ErrorSummary from "./error-summary";
import ActivityIndicator from "./activity-indicator";

import * as patient_actions from "../store/patient/actions";
import * as model_health_history from "../model-states/m-health-history";
import ModalConfirmDelete from "./modal-confirm-delete";

import SkipLogicBreastForm from "./skip-logic-breast-form";
import SkipLogicColorectalForm from "./skip-logic-colorectal-form";
import SkipLogicColorectalPolypsForm from "./skip-logic-colorectal-polyps-form";
import SkipLogicDCISForm from "./skip-logic-dcis-form";
import SkipLogicMelanomaForm from "./skip-logic-melanoma-form";
import SkipLogicHodgkinLymphomaForm from "./skip-logic-hodgkin-lymphoma-form";
import SkipLogicPancreaticNeuroendocrineForm from "./skip-logic-pancreatic-neuroendocrine-form";
import SkipLogicProstateForm from "./skip-logic-prostate-form";
import SkipLogicThyroidForm from "./skip-logic-thyroid-form";
import SkipLogicUterineForm from "./skip-logic-uterine-form";
import SkipLogicLeukemiaForm from "./skip-logic-leukemia-form";
import SkipLogicBirthDefectsForm from './skip-logic-birth-defects-form'

import { components } from "react-select";
import SkipLogicOtherCancerForm from "./skip-logic-other-cancer-form";

import disease_api from "../api/disease-api";
import umls_api from "../api/umls-api";

import * as static_list_actions from "../store/static_list/actions";
import { radix_bases } from '../helpers/helper-number-bases';

import { skipLogicUMLS } from '../helpers/diseaseUMLSSkipLogic'
import {
  ageOptions,
  stringToAge,
  yearBirthToAgeString,
  updateYear,
  ageStringToAgeInt } from '../helpers/age-utils'

import { createUUID } from './react-flow-pedigree/utils';

class Expire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      breast_cancer_selected: false
    };
      this.timer = null;
  }

  componentDidMount() {
    this.setTimer();
    this.mounted = true;
  }

  setTimer() {
    // clear any existing timer
    this.timer = (this.timer != null) ? clearTimeout(this.timer) : null;

    // hide after `delay` milliseconds
    this.timer = setTimeout(function(){
      if (this.mounted) {
        this.setState({visible: false});
        this.timer = null;
      }
    }.bind(this), this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.mounted = false;
  }

  render() {
    if (this.state.visible) {
      return (<div>{this.props.children}</div>);
    }

    return (<span />);

    // return this.state.visible
    //        ? <div>{this.props.children}</div>
    //        : <span />;
  }
}

class MenuList extends Component {
  constructor(props) {
    super(props);
  }

  queryUMLS() {
    // trigger event which queries UMLS endpoint
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event = new CustomEvent('query-umls');
    } else {
      event = new Event('query-umls');
    }
    window.dispatchEvent(event);
  }

  queryMoreUMLS() {
    // trigger event which loads more UMLS results
    const isIE = false || !!document.documentMode;
    var event
    if (isIE) {
      event =  new CustomEvent('query-more-umls');
  } else {
      event = new Event('query-more-umls');
    }
    window.dispatchEvent(event);
  }

  render() {
    let search_value = this.props.selectProps.inputValue

    if (Array.isArray(this.props.children)) {

      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            {this.props.children}
            <components.Option {...this.props}>
            {search_value.length >= 3 && (
              <a onClick={this.queryMoreUMLS}>Load More</a>
            )}
            </components.Option>
          </components.MenuList>
        </React.Fragment>
      );

    } else {

      this.queryUMLS();
      return (
        <React.Fragment>
          <components.MenuList {...this.props}>
            <Expire delay={6000}>
              <ActivityIndicator loading={true} />
            </Expire>
          </components.MenuList>
        </React.Fragment>
      );

    }
  }
}

class ModalDiseasesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      umlsLoading: false,
      loading_delete: false,
      errorMessages: [],
      rkey: null,
      id: null,
      disease_id: null,
      disease_name: null,
      disease_short_name: null,
      prev_disease_id: null,
      disease: null, // dropdown selected disease item
      prev_disease: null,
      age_diagnosed: null,
      age_diagnosed_string: null,
      skip_logics: [], // raw state
      skip_logic_valid_input: true,
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      breastSkipLogic: false,
      colorectalSkipLogic: false,
      colorectalPolypsSkipLogic: false,
      melanomaSkipLogic: false,
      hodgkinSkipLogic: false,
      dcisSkipLogic: false,
      pancreaticNeuroendocrineSkipLogic: false,
      prostateSkipLogix: false,
      thyroidSkipLogic: false,
      uterineSkipLogic: false,
      leukemiaSkipLogic: false,
      birthdefectSkipLogic: false,
      otherSkipLogic: false,
      skipLogics: {},
      diseases_options: [],
      static_selected_disease: null, // full copy of selected disease item

      skip_logic_payload: [], // payload for saving to api

      search_val: "",
    };

    // using this vsalue out of state because is used in triggering UMLS
    // we don't want to have the delay of updating redux state, we want it set immediately
    this.previous_search_val = "";
    this.umls_results_start = 0;
    this.timer = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.queryUMLS = this.queryUMLS.bind(this);
    this.searchUMLS = this.searchUMLS.bind(this);
    this.loadUmlsResults = this.loadUmlsResults.bind(this);
    this.queryMoreUMLS = this.queryMoreUMLS.bind(this);
    this.getPatientsDiseases = this.getPatientsDiseases.bind(this);
    this.isDiseaseFromUmls = this.isDiseaseFromUmls.bind(this);
    this.skipLogicCheck = this.skipLogicCheck.bind(this);
    this.clearPreviousSkipLogic = this.clearPreviousSkipLogic.bind(this);
    this.handleAgeDxChange = this.handleAgeDxChange.bind(this);
    this.getAgeDx = this.getAgeDx.bind(this);
    this.normalizeAge = this.normalizeAge.bind(this);
    this.loadSkipLogicFromApi = this.loadSkipLogicFromApi.bind(this);
  }

  async componentDidMount() {
    await this.loadDiseasesFromAPI();
    await this.loadSkipLogicFromApi();
    console.log(this.state.diseases_options)
    if (this.props.disease) {
      await this.setStaticDiseaseItem(this.props.disease.disease_id, this.props.disease.disease_name);
      this.setState({
        rkey: this.props.disease.rkey,
        id: this.props.disease.id,
        disease_id: this.props.disease.disease_id,
        prev_disease_id: this.props.disease.disease_id,
        disease: this.props.disease,
        disease_short_name: this.props.disease.disease_short_name,
        prev_disease: this.props.disease,
        age_diagnosed: this.props.disease.age_diagnosed,
        age_diagnosed_string: this.props.disease.age_diagnosed_string ? this.props.disease.age_diagnosed_string: this.props.disease.age_diagnosed,
        skip_logics: this.props.disease.skip_logics
      });
    } else {
      let disease = model_health_history.createHistoryDisease();
      this.setState({ rkey: disease.rkey });
    }
    this.mounted = true;
    this.timeout = null;
    window.addEventListener('query-umls', this.queryUMLS, false);
    window.addEventListener('query-more-umls', this.queryMoreUMLS, false);
  }

  componentDidUpdate(prevProps) {
    this.loadDiseasesFromRedux();
  }

  async componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('query-umls', this.queryUMLS, false);
    window.removeEventListener('query-more-umls', this.queryMoreUMLS, false);
  }

  queryUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length < 3) {
      return;
    }

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      if (trimmed_search_value !== this.previous_search_val) {

        // using this value out of state because is used in triggering UMLS
        // we don't want to have the delay of updating redux state, we want it set immediately
        this.previous_search_val = trimmed_search_value;
        this.umls_results_start = 0;
        this.searchUMLS(trimmed_search_value, this.umls_results_start);

      }
    }, 400);
  }

  async searchUMLS(term, start) {
    const payload = {term: term, start: start};
    let search_results = null;
    this.setState({umlsLoading: true})
    try {

      search_results = await umls_api.search_umls(payload);
      this.umls_results_start += 10;
      if (search_results) this.loadUmlsResults(search_results);

    } catch (err) {
      // something went wrong with UMLS search
      // console.log(err);
    }
    this.setState({umlsLoading: false})
  }

  loadUmlsResults(search_results) {
    let diseases_options = cloneDeep(this.state.diseases_options);

    let existing_umls = [];
    let had_existing_umls = false;
    for(let i=0; i<diseases_options.length; i++) {
      if (diseases_options[i].label === "UMLS") {
        existing_umls = diseases_options[i].options;
        had_existing_umls = true;
        break;
      }
    }

    let existing_cui_dictionary = {};
    for (let i=0; i<existing_umls.length; i++) {
      existing_cui_dictionary[existing_umls[i].value] = existing_umls[i].value;
    }

    const concept_list = search_results["ConceptList"];
    const search_term_from_results = search_results["Term"];
    if (search_term_from_results === this.state.search_val) {
      if (Array.isArray(concept_list) && concept_list.length > 0) {

        for (let i=0; i<concept_list.length; i++) {
          const c = concept_list[i];
          const cui = c["CUI"];
          const name = c["Name"];
          const preferred_name = c["PreferredName"];

          if (cui in existing_cui_dictionary) {
            for (let x=0; x<existing_umls.length; x++) {
              if (existing_umls[x].value === cui) {
                existing_umls[x].label = name;
                break;
              }
            }
          } else {

            existing_umls.push({
              value: cui,
              label: name,
              fromUMLS: true
            });

          }
        }

      }

      if (had_existing_umls) {

        for(let i=0; i<diseases_options.length; i++) {
          if (diseases_options[i].label === "UMLS") {
            diseases_options[i].options = existing_umls;
            break;
          }
        }

      } else {

        diseases_options.push({
          label: "UMLS",
          options: existing_umls
        });

      }
    }

    this.setState({ diseases_options: diseases_options });
  }

  queryMoreUMLS(event) {
    const search_val = this.state.search_val;
    if (typeof(search_val) !== "string"
        || search_val.trim() === "") {
          return;
        }

    const trimmed_search_value = search_val.trim();
    if(trimmed_search_value.length < 3) {
      return;
    }

    if (trimmed_search_value !== this.previous_search_val) {

      // using this value out of state because is used in triggering UMLS
      // we don't want to have the delay of updating redux state, we want it set immediately
      this.previous_search_val = trimmed_search_value;
      this.umls_results_start = 0;
      this.searchUMLS(trimmed_search_value, this.umls_results_start);

    } else {
      this.searchUMLS(trimmed_search_value, this.umls_results_start);
    }
  }

  getPatientsDiseases() {
    if(this.props.profile.rkey in this.props.history_diseases) {
      let diseases = this.props.history_diseases[this.props.profile.rkey];
      for(let i=0; i<diseases.length; i++) {
        if(diseases[i].rkey === null || diseases[i].rkey === undefined) {
          diseases[i].rkey = createUUID();
        }
      }
      return diseases;
    }
    return []
  }

  isDiseaseFromUmls(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }

  loadDiseasesFromRedux() {
    if (this.state.diseases_options.length > 0) return;

    // Arrange by group
    let diseases = this.props.static_list.diseases;

    // Filter by gender
    let gender = this.props.profile.gender
    diseases = diseases.filter(item => item.gender_specific === null || item.gender_specific === "" || item.gender_specific == gender)

    let diseases_by_category = {};
    for (var disease of diseases) {
      if (disease.disease_type in diseases_by_category) {
        diseases_by_category[disease.disease_type].push(disease);
      } else {
        diseases_by_category[disease.disease_type] = [disease];
      }
    }

    //Parse and Format
    let options = []; //[{ value: '', label: ' ' }]
    for (var category_name in diseases_by_category) {
      let fmt_category_name = category_name.replace("-", " ").replace("_", " ").toUpperCase()
      if(fmt_category_name == 'CANCER POLYPS') fmt_category_name = "CANCER/POLYPS"
      options.push({
        label: fmt_category_name,
        options: diseases_by_category[category_name].map(item => ({
          value: item.id,
          label: item.disease_name,
          fromUMLS: false
        }))
      });
    }

    //Sort the categories
    options = options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    const patient_diseases = this.getPatientsDiseases();
    let umls_options = [];
    for(let i=0; i<patient_diseases.length; i++) {
      const umls_id = patient_diseases[i].umls_id;
      const umls_name = patient_diseases[i].umls_name;

      if( umls_id !== null && umls_id !== undefined) {
        umls_options.push({
          value: umls_id,
          label: umls_name
        });
      }
    }

    if (umls_options.length > 0) {
      options.push({
        label: "UMLS",
        options: umls_options
      });
    }

    if (options.length > 0) {
      this.setState({ diseases_options: options });
    }
  }

  async loadDiseasesFromAPI() {
    try {
      this.setState({ loading: true, errorMessages: [] });
      let diseases = this.props.static_list.diseases;
      if (diseases.length == 0) {
        let data = await disease_api.get_disease();
        diseases = data.diseases.sort((a, b) => {
          if (a.disease_name < b.disease_name) return -1;
          if (a.disease_name > b.disease_name) return 1;
          return 0;
        });
        this.props.dispatch(static_list_actions.initialize_diseases(diseases));
      }
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }
  async loadSkipLogicFromApi(){
    try{
        if(this.props.static_list.skip_logics == 0){
          this.setState({ loading: true, errorMessages: [] });
          let skipLogics = await disease_api.get_all_skip_logic();
          this.props.dispatch(static_list_actions.initialize_skip_logics(skipLogics));
        }
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }finally{
      this.setState({loading: false})
    }
  }

  handleChange(e) {
    if(e.currentTarget.name === 'age_diagnosed') {
      if(e.currentTarget.value === null || e.currentTarget.value === '') {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
      } else {
        let sanatized_val = e.currentTarget.value.replace(/\D/,'');
        let age_diagnosed = parseInt(sanatized_val, radix_bases.base10);
        if( !isNaN(age_diagnosed) && age_diagnosed > 0 && age_diagnosed <= 150) {
          this.setState({ [e.currentTarget.name]: sanatized_val });
        } else if(!isNaN(age_diagnosed) && age_diagnosed === 0) {
          this.setState({ [e.currentTarget.name]: '0' });
        }
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }


  handleAgeDxChange(e){
    this.setState({ [e.target.name]: e.target.value });
  }

  normalizeAge(ageDx){
   let ageString = ''
   let convertedAge = ''

   if(ageDx){
      let ageKey = ''
      let ageOption = ageDx.replace(/\d+/g, '').trim();
      let ageInt = parseInt(ageDx, radix_bases.base10)
      for(let key in ageOptions){
        if(ageOption.startsWith(ageOptions[key])){
          ageKey = key
        }
      }
      ageString = (ageInt && ageKey!== '') ?  `${ageInt}${ageKey}` : ageInt ? `${ageInt}` : ''
      convertedAge = stringToAge(ageInt, ageKey)

   }

   if (!isNaN(convertedAge)) {
     this.setState({
      ['age_diagnosed_string']: ageString,
      ['age_diagnosed']: convertedAge,
     });
   }
}

  getAgeDx(){
      clearTimeout(this.timer);
      let ageDx = this.state.age_diagnosed_string ? this.state.age_diagnosed_string :  ''
      this.timer = setTimeout(() => {
        this.normalizeAge(ageDx)
        clearTimeout(this.timer);
      }, 1000);

      return ageDx
  }

  handleSearchInputChange(search_val, action) {
    let diseases_options = cloneDeep(this.state.diseases_options);
    clearTimeout(this.timeout);
    // Make a new timeout set to go off in 1000ms (1 second), if search value is different than last umls search we clear and
    // display anything returned
    this.timeout = setTimeout(()=> {
      if(this.previous_search_val && search_val !== this.previous_search_val) {
        let array = diseases_options.find(el=>el.label == 'UMLS')
        let umlsIndex = diseases_options.indexOf(array)
        diseases_options.splice(umlsIndex, 1)
        this.setState({diseases_options: diseases_options})
      }
    }, 1000);

     this.setState({ search_val: search_val });
  }

  validate() {
    let errorMessages = [];
    if (this.state.disease_id === null) {
      errorMessages.push("Disease is required");
    }
    //commenting out for now, error message given when no age dx
    // if (this.state.age_diagnosed.trim().length == 0) {
    //   errorMessages.push("Age at Dx is required");
    // }
    this.setState({ errorMessages });
    return errorMessages.length === 0;
  }

  async onClickSave() {
    try {
      if (this.state.skip_logic_valid_input === false) throw new Error('Cannot save due to invalid data.')
      // let person_age = this.props.profile.is_proband ? this.props.patient.patient.age : this.props.profile.age

      let person_age = this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null ? this.props.getPedigreeData().getProfile(this.props.profile.rkey).age : this.props.profile.age;

      // check first if modal diseases form has getMemberInfoAge prop that's only present on the sidebar
      if(this.props.getMemberInfoAge){
        if(!person_age && this.props.getMemberInfoAge()){
          person_age = this.props.getMemberInfoAge()
        }
      }

      if(person_age > 0
        && person_age !== null
        && person_age !== undefined
        && person_age !== ''
        && parseInt(this.state.age_diagnosed, radix_bases.base10) > parseInt(person_age, radix_bases.base10))
          throw new Error('The age of diagnosis cannot be greater than this person’s current age/age of death.');

      this.setState({ errorMessages: [], loading: true });

      let isValid = this.validate();
      if (!isValid) return;

      let disease_payload = {};
      const is_from_umls = this.isDiseaseFromUmls(this.state.disease_id);
      const prev_disease_umls = this.isDiseaseFromUmls(this.state.prev_disease_id);

      var skip_logics = [];
      // Save skip logic
      if (this.state.skip_logic_payload.length > 0) {
        for (var item of this.state.skip_logic_payload) {
          var data_sl = {
            member_id: this.props.profile.id,
            skip_logic_id: item.skip_logic_id,
            answer: item.answer,
            skip_logic_code: item.skip_logic_code,
            short_name: item.short_name
          };
          skip_logics.push(data_sl);
        }
      }

      if (is_from_umls) {

        // its a concept id so its from UMLS
        disease_payload = {
          id: this.state.id,
          member_id: this.props.profile.id,
          disease_id: null,
          umls_id: this.state.disease_id,
          age_diagnosed: this.state.age_diagnosed,
          age_diagnosed_string: this.state.age_diagnosed_string,
          umls_name: this.state.static_selected_disease.disease_name,
          skip_logics: skip_logics
        };

      } else {

        disease_payload = {
          id: this.state.id,
          member_id: this.props.profile.id,
          disease_id: this.state.disease_id,
          age_diagnosed: this.state.age_diagnosed,
          age_diagnosed_string: this.state.age_diagnosed_string,
          umls_id: null,
          umls_name: null,
          skip_logics: skip_logics
        };

      }
      // Save member disease to API
      let new_disease = null;
      if (this.state.id == null) {
        new_disease = true;
      } else {
        new_disease = false;
      }
      let data = await disease_api.save_disease(disease_payload);

      let history_disease_detail = {};
      if (is_from_umls) {

        history_disease_detail = {
          rkey: this.state.rkey,
          id: data.id,
          disease_code: this.state.static_selected_disease.id,
          disease_name: this.state.static_selected_disease.disease_name,
          disease_short_name: this.state.static_selected_disease.disease_name,
          disease_id: this.state.disease_id,
          age_diagnosed: this.state.age_diagnosed,
          age_diagnosed_string: this.state.age_diagnosed_string,
          skip_logics: skip_logics
        };

      } else {

        var specificDiseaseAdded = this.props.static_list.diseases.filter(disease => disease.disease_code === this.state.static_selected_disease.disease_code)
        history_disease_detail = {
          rkey: this.state.rkey,
          id: data.id,
          disease_code: this.state.static_selected_disease.disease_code,
          disease_name: this.state.static_selected_disease.disease_name,
          disease_short_name: specificDiseaseAdded[0].short_name,
          disease_id: this.state.disease_id,
          age_diagnosed: this.state.age_diagnosed,
          age_diagnosed_string: this.state.age_diagnosed_string,
          skip_logics: skip_logics
        };

      }

      // Save disease to Redux
      let diseases_payload = {
        ownerRkey: this.props.patientRkey,
        history_disease_detail
      };
      this.props.dispatch(
        patient_actions.save_history_disease_detail(diseases_payload)
      );


      // ***** TODO: Setting a specific history disease of the member on the new pedigree data store. Consider
      // diseases type if normal or umls etc. *****

      if(new_disease){
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let diseases = cloneDeep(this.props.getPedigreeData().getDiseases(this.props.patientRkey))
          let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
          let new_disease_to_be_pushed = cloneDeep(history_disease_detail)
          if(is_from_umls){
            new_disease_to_be_pushed.umls_id = this.state.disease_id;
            new_disease_to_be_pushed.umls_name = this.state.static_selected_disease.disease_name
          }
          else{
            new_disease_to_be_pushed.umls_id = null
            new_disease_to_be_pushed.umls_name = null
          }
          diseases.push(new_disease_to_be_pushed)
          profile_proband.diseases = diseases;
          await this.props.getPedigreeData().setDiseases(this.props.patientRkey, diseases)
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
        }
      }
      else{
        if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
          let diseases = cloneDeep(this.props.getPedigreeData().getDiseases(this.props.patientRkey))
          let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
          for(let disease of diseases){
            if(disease.id === history_disease_detail.id){
              disease.age_diagnosed = history_disease_detail.age_diagnosed
              disease.age_diagnosed_string = history_disease_detail.age_diagnosed_string
              disease.disease_code = history_disease_detail.disease_code
              disease.disease_id = history_disease_detail.disease_id
              disease.disease_name = history_disease_detail.disease_name
              disease.disease_short_name = history_disease_detail.disease_short_name
              disease.id = history_disease_detail.id
              disease.rkey = history_disease_detail.rkey
              disease.skip_logics = cloneDeep(history_disease_detail.skip_logics)
              if(is_from_umls){
                disease.umls_id = this.state.disease_id;
                disease.umls_name = this.state.static_selected_disease.disease_name
              }
              else{
                disease.umls_id = null
                disease.umls_name = null
              }
            }
          }
          profile_proband.diseases = diseases;
          await this.props.getPedigreeData().setDiseases(this.props.patientRkey, diseases)
          await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
        }
      }


      this.props.onSave({ rkey: this.props.patientRkey, disease: history_disease_detail });
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    } finally {
      if(this.mounted) {
        this.setState({ loading: false });
      }
    }
  }

  handleSkipLogicUpdate(skip_logic, skip_logic_payload = [], skip_logic_valid_input = true) {
    this.setState({ skip_logic, skip_logic_payload, skip_logic_valid_input });
  }

  handleSelectDisease(disease) {
    let disease_id = (disease !== null) ? disease.value : null
    let disease_name = (disease !== null) ? disease.label : null
    this.setState({ skip_logics: [], disease_id, disease_name, skip_logic_valid_input: true, skip_logic_payload: [] });
    this.setStaticDiseaseItem(disease_id, disease_name);
    // loop through static list of diseases and change id to match disease_code
    for (let i = 0; i < this.props.static_list.diseases.length; i++)
    {
        if (disease_id == this.props.static_list.diseases[i].id) {
          disease_id = this.props.static_list.diseases[i].disease_code
        }
    }
    // loop through diseases and check for multiple instances of breast cancer
    for (let i = 0; i < this.props.profile.diseases?.length; i++)
    {
      // changing a previous breast cancer to a new breast cancer does not show the warning message and message will show if disease codes match and they match to breast cancer
      if (this.state.id != this.props.profile.diseases[i].id && disease_id == this.props.profile.diseases[i].disease_code && disease_id == "D002") {
        this.setState({breast_cancer_selected: true});
      }
    }
  }
  clearPreviousSkipLogic(){
    this.setState({
      breastSkipLogic: false,
      colorectalSkipLogic: false,
      colorectalPolypsSkipLogic: false,
      dcisSkipLogic: false,
      hodgkinSkipLogic: false,
      melanomaSkipLogic: false,
      prostateSkipLogix: false,
      thyroidSkipLogic: false,
      leukemiaSkipLogic: false,
      pancreaticNeuroendocrineSkipLogic: false,
      birthdefectSkipLogic: false,
      uterineSkipLogic: false
    })

  }

  skipLogicCheck(disease){
    //Clear state of previous skip logic
    this.clearPreviousSkipLogic()
    let diseaseId = disease.disease_code ? disease.disease_code: disease.id

    if(skipLogicUMLS.breastUMLS.includes(diseaseId))this.setState({ breastSkipLogic: true})
    if(skipLogicUMLS.colorectalUMLS.includes(diseaseId)) this.setState({colorectalSkipLogic: true})
    if(skipLogicUMLS.colorectalPolypsUMLS.includes(diseaseId)) this.setState({colorectalPolypsSkipLogic: true})
    if(skipLogicUMLS.dcisUMLS.includes(diseaseId)) this.setState({dcisSkipLogic: true})
    if(skipLogicUMLS.hodgkinlymphomaUMLS.includes(diseaseId)) this.setState({hodgkinSkipLogic: true})
    if(skipLogicUMLS.melanomaUMLS.includes(diseaseId)) this.setState({melanomaSkipLogic: true})
    if(skipLogicUMLS.prostateUMLS.includes(diseaseId)) this.setState({prostateSkipLogix: true})
    if(skipLogicUMLS.thyroidUMLS.includes(diseaseId)) this.setState({thyroidSkipLogic: true})
    if(skipLogicUMLS.leukemiaUMLS.includes(diseaseId)) this.setState({leukemiaSkipLogic: true})
    if(skipLogicUMLS.pancreaticNeuroendocrineUMLS.includes(diseaseId)) this.setState({pancreaticNeuroendocrineSkipLogic: true})
    if(skipLogicUMLS.birthdefectsUMLS.includes(diseaseId)) this.setState({birthdefectSkipLogic: true})
    if(skipLogicUMLS.uterineUMLS.includes(diseaseId)) this.setState({uterineSkipLogic: true})
  }


  setStaticDiseaseItem(disease_id, disease_name) {
    if (disease_id !== null) {
      let static_selected_disease = this.props.static_list.diseases.find(
        item => item.id === disease_id
      );

      if (static_selected_disease) {
        this.setState({ static_selected_disease });
      } else {

        static_selected_disease = null;
        const is_from_umls = this.isDiseaseFromUmls(disease_id);
        let skip_logic =  this.props.static_list.skip_logics[disease_id] ?  this.props.static_list.skip_logics[disease_id] : {}
        if (is_from_umls) {
          static_selected_disease = {
            id: disease_id,
            disease_name: disease_name,
            skip_logistics: skip_logic
          };
        }

        this.setState({ static_selected_disease: static_selected_disease });
      }
      this.skipLogicCheck(static_selected_disease);
    } else {
      this.setState({ static_selected_disease: null });
    }
  }

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true });

      // delete_member_disease_id
      if (this.state.id !== null) {
        await disease_api.delete_member_disease_id(this.state.id);
      }

      if(this.props.getPedigreeData !== undefined && this.props.getPedigreeData !== null){
        let diseases = cloneDeep(this.props.getPedigreeData().getDiseases(this.props.patientRkey))
        let profile_proband = cloneDeep(this.props.getPedigreeData().getProfile(this.props.patientRkey))
        diseases = diseases.filter(disease => disease.id !== this.state.id)
        profile_proband.diseases = diseases;
        await this.props.getPedigreeData().setDiseases(this.props.patientRkey, diseases)
        await this.props.getPedigreeData().setProfile(this.props.patientRkey, profile_proband)
      }
      else{
        this.props.dispatch(
          patient_actions.delete_history_disease_detail({
            ownerRkey: this.props.patientRkey,
            disease_rkey: this.state.rkey
          })
        );
      }


      if('onDelete' in this.props) {
        this.props.onDelete({
          rkey: this.props.patientRkey,
          disease_rkey: this.state.rkey
        });
      } else {
        this.props.onClose();
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading_delete: false });
    }
  }

  render() {

    let static_selected_disease = this.state.static_selected_disease
    let disease = (static_selected_disease) ? {value: static_selected_disease.id, label: static_selected_disease.disease_name} : null;
    let autoFocus = isEmpty(this.props.disease)
    return ReactDOM.createPortal(
      <React.Fragment>
        {this.state.openModalConfirmDelete && (
          <ModalConfirmDelete
            title="Delete Disease"
            message="Do you want to delete this disease?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        )}

        <div
          style={{ display: "block" }}
          className="modal"
          role="dialog"
        >
          <div
            onClick={e => e.stopPropagation()}
            className="modal-dialog modal-xl disease-modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => this.props.onClose()}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-close" />
                </button>
                <h4 className="modal-title text-white text-capitalize">{this.props.title}</h4>
              </div>

              <div className="modal-body">
                <ErrorSummary
                  transparent={true}
                  errorMessages={this.state.errorMessages}
                />
                {this.state.breast_cancer_selected &&(
                  <div className="alert alert-warning">
                    <p></p>
                    <p>Breast cancer has already been entered for this person. You should edit the original diagnosis to mark ipsilateral or bilateral to reflect another breast cancer, as this will affect risk model calculations.</p>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    <label>Diseases</label>

                    <div className="custom-select">
                      <ControlSelectDiseases
                        options={this.state.diseases_options}
                        onChange={disease => this.handleSelectDisease(disease)}
                        value={disease}
                        placeholder="&nbsp;"
                        isLoading={this.state.umlsLoading}
                        components={{ MenuList }}
                        inputValue={this.state.search_val}
                        onInputChange={(input_val, action) => this.handleSearchInputChange(input_val, action)}
                        autofocus={autoFocus}
                      />
                    </div>

                  </div>
                  <div className="col-lg-2 col-md-3">
                    <label>Age at Dx</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control normal-input-text"
                        name="age_diagnosed_string"
                        onChange={this.handleAgeDxChange}
                        value={this.getAgeDx()}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 col-xs-1">
                    <div className="form-group">
                      <label />

                      {this.props.disease && (
                        <a className="remove-row"
                          onClick={() => this.onClickOpenModalConfirmDelete()}>
                          <i className="fa fa-trash" />{" "}
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                {this.state.static_selected_disease && this.state.breastSkipLogic && (
                    <SkipLogicBreastForm
                      profile={this.props.profile}
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload, is_valid) =>
                        this.handleSkipLogicUpdate(skip_logic, payload, is_valid)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                 this.state.colorectalSkipLogic && (
                    <SkipLogicColorectalForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.colorectalPolypsSkipLogic && (
                    <SkipLogicColorectalPolypsForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.melanomaSkipLogic && (
                    <SkipLogicMelanomaForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.hodgkinSkipLogic && (
                    <SkipLogicHodgkinLymphomaForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.dcisSkipLogic && (
                    <SkipLogicDCISForm
                      profile={this.props.profile}
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload, is_valid) =>
                        this.handleSkipLogicUpdate(skip_logic, payload, is_valid)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.pancreaticNeuroendocrineSkipLogic && (
                    <SkipLogicPancreaticNeuroendocrineForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.prostateSkipLogix && (
                    <SkipLogicProstateForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.thyroidSkipLogic && (
                    <SkipLogicThyroidForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.uterineSkipLogic && (
                    <SkipLogicUterineForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}

                {this.state.static_selected_disease &&
                  this.state.leukemiaSkipLogic && (
                    <SkipLogicLeukemiaForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}
                {this.state.static_selected_disease &&
                  this.state.birthdefectSkipLogic && (
                    <SkipLogicBirthDefectsForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}
                {this.state.static_selected_disease &&
                  this.state.static_selected_disease.disease_code === "D107" && (
                    <SkipLogicOtherCancerForm
                      static_skip_logic={
                        this.state.static_selected_disease.skip_logistics
                      }
                      skip_logics={this.state.skip_logics}
                      onUpdate={(skip_logic, payload) =>
                        this.handleSkipLogicUpdate(skip_logic, payload)
                      }
                    />
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => this.props.onCancel()}
                  className="btn btn-light-outline no-margin-right "
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => this.onClickSave()}
                  className="btn btn-dark "
                  data-toggle="modal"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>

              <ActivityIndicator loading={this.state.loading} modal={true} />
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    );
  }
}

const redux_state = state => ({
  history_diseases: state.patient.history_diseases,
  static_list: state.static_list,
  patient: state.patient
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(ModalDiseasesForm);
