import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import helpers from "../helpers/index";
import NavTop from "../components/nav-top";
import PatientDataTabs from "../components/patient-profile/patient-data-tabs";
import RiskAssessmentTabs from "../components/patient-profile/risk-assessment-tabs";
import PatientUpdates from "../components/patient-profile/patient-updates";
import ActivityIndicator from "../components/activity-indicator";
import notifications_api from "../api/notifications-api";
import family_api from "../api/family-api";
import refinery_api from "../api/refinery-api";
import risk_api from "../api/risk-api";
import { filterNotificationUpdates } from "../helpers/helper-notifications";
import { save_risk_criteria, reset_risk_results } from '../store/patient/actions';
import { risk_results as default_risk_results } from '../store/patient/default';
import route_helper from "../route-helper";
import { isObject } from "lodash";
import { createUUID } from '../components/react-flow-pedigree/utils';

import sdk from '../../src/api/sdk'

import PedigreeWrapper from '../components/react-flow-pedigree/pedigree-wrapper';

import { PedigreeData } from '../components/react-flow-pedigree/pedigree-data';
import { PedigreeDrawingData } from '../components/react-flow-pedigree/pedigree-drawing-data';
import { Connection_Box_Watcher, Intersector } from '../components/react-flow-pedigree/ConnectionWatcher.ts';

class PedigreePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      populating_proband: true,
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
      personalDataOpen: false,
      reRenderTopBar: false,
      notifications: []
    };

    this.pedigreeWrapperRef = React.createRef();
    this.pedigree_wrapper_key = createUUID();
    this.pedigreeData = new PedigreeData();
    this.pedigreeDrawingData = new PedigreeDrawingData();
    this.connectionBoxWatcher = new Connection_Box_Watcher();


    this.fetchProbandTree = this.fetchProbandTree.bind(this);
    this.buildPedigreeData = this.buildPedigreeData.bind(this);
    this.getPedigreeData = this.getPedigreeData.bind(this);
    this.getPedigreeDrawingData = this.getPedigreeDrawingData.bind(this);
    this.getConnectionWatcher = this.getConnectionWatcher.bind(this);
    this.setRiskCriteria = this.setRiskCriteria.bind(this);
    this.setRiskResults = this.setRiskResults.bind(this);
    this.handlePDTabClicked = this.handlePDTabClicked.bind(this);
    this.handleFHTabClicked = this.handleFHTabClicked.bind(this);
    this.handleRATabClicked = this.handleRATabClicked.bind(this);
    this.handleNotificationAction = this.handleNotificationAction.bind(this);
    this.fetchNotificationsByPatient = this.fetchNotificationsByPatient.bind(this);
    this.fetchHiddenDiseaseColors = this.fetchHiddenDiseaseColors.bind(this);
    this.fetchProbandTree = this.fetchProbandTree.bind(this);
    this.handleNotificationAction = this.handleNotificationAction.bind(this);
    this.markAllPendingAsRead = this.markAllPendingAsRead.bind(this);
    this.setNotificationAsRead = this.setNotificationAsRead.bind(this);
    this.fetchRiskCriteria = this.fetchRiskCriteria.bind(this);
    this.reRenderTopBarFunction = this.reRenderTopBarFunction.bind(this);
    this.viewPatient = this.viewPatient.bind(this);
    this.bulkReadNotifications = this.bulkReadNotifications.bind(this);

    const render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree');
    this.render_patient_pedigree = render_patient_pedigree == 'true';
  }

  reRenderTopBarFunction() {
    // this is just switching between true and false to force a re-render
    // so that the probands data is updated on the top bar
    let reRenderTopBar = !this.state.reRenderTopBar
    this.setState({ reRenderTopBar })
  }

  async componentDidMount() {
    helpers.setSiteTitle("Patient Profile");

    // if pedigree comes from mobile patient app
    if (sessionStorage.getItem('patient-pedigree-token')) {
      sessionStorage.setItem('render-patient-pedigree', 'true');
      let payload = {
        token: {
          accessToken: sessionStorage.getItem('patient-pedigree-token')
        }
      }
      sdk.tokens_to_cookie(payload);
      sessionStorage.removeItem('patient-pedigree-token');
    } else {
      sessionStorage.removeItem('render-patient-pedigree');
    }

    try {

      /* Build proband data */
      await this.fetchProbandTree();

      if (this.props.location.hash.startsWith("#family-history") || this.props.location.hash === "") {
        this.setState({ showFamilyHistoryTab: true });
      } else if (this.props.location.hash == "#patient-updates") {
        this.setState({ showPatientUpdates: true });
      }

      this.fetchRiskCriteria();

    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ populating_proband: false });
    }
  }

  componentWillUnmount() {
    window.localStorage.removeItem("famgenix_latestcenter");
    window.localStorage.removeItem("selectedNode");
    window.localStorage.removeItem("famgenix_legend_center");
    localStorage.removeItem('famgenix_last_selected_node');
    sessionStorage.removeItem('member_id_toBeSelected');
    sessionStorage.removeItem('ages_before_update');
    sessionStorage.removeItem('subtextCollection');
  }

  getPedigreeData() {
    return this.pedigreeData;
  }

  getPedigreeDrawingData() {
    return this.pedigreeDrawingData;
  }

  getConnectionWatcher() {
    return this.connectionBoxWatcher;
  }

  buildPedigreeData(patient_tree) {
    this.getPedigreeData().setProband(patient_tree.proband);
    this.getPedigreeData().setAllProfiles(patient_tree.profiles);
    this.getPedigreeData().setAllDiseases(patient_tree.diseases);
    this.getPedigreeData().setAllGenes(patient_tree.genes);
    this.getPedigreeData().setAllGenePanels(patient_tree.gene_panels);
    this.getPedigreeData().setHiddenDiseaseColors(patient_tree.hidden_disease_colors);
    this.getPedigreeData().setPedigreeNotes(patient_tree.pedigree_notes);
    this.getPedigreeData().setRiskSettings(patient_tree.risk_settings)
    this.getPedigreeData().setAllPartners(patient_tree.partners)
    this.getPedigreeData().setAllSonsDaughters(patient_tree.sons_daughters)
    this.getPedigreeData().setAllUnclesAunts(patient_tree.uncles_aunts)
    this.getPedigreeData().setAllSiblings(patient_tree.siblings)
    this.getPedigreeData().setSavedNodePositions(patient_tree.saved_node_positions)
    this.getPedigreeData().setProgenyArchiveData(patient_tree.progeny_archive_data);
    this.getPedigreeData().setProgenyArchivePreferences(patient_tree.progeny_archive_preferences);
    this.getPedigreeData().setClonedMembers(patient_tree.cloned_members);
    this.getPedigreeData().setLinkedMembers(patient_tree.linked_members);
    this.getPedigreeData().setMemberLinks(patient_tree.member_links);
    this.getPedigreeData().setDonors(patient_tree.donors);
    this.getPedigreeData().setSiblingOrders(patient_tree.sibling_orders);
  }

  setRiskCriteria(risk_criteria) {
    this.getPedigreeData().setRiskCriteria(risk_criteria);
  }

  setRiskResults(risk_results) {
    this.getPedigreeData().setRiskResults(risk_results);
  }

  handlePDTabClicked(event) {
    this.setState({
      showPatientDataTab: true,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
    });
  }

  handleFHTabClicked(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: true,
      showRiskAssesmentTab: false,
      showPatientUpdates: false,
    });
  }

  handlePatientUpdatesClick(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: false,
      showPatientUpdates: true,
    });
  }

  handleRATabClicked(event) {
    this.setState({
      showPatientDataTab: false,
      showFamilyHistoryTab: false,
      showRiskAssesmentTab: true,
      showPatientUpdates: false,
    });
  }

  async fetchNotificationsByPatient(proband) {
    let user = this.props.session.user;
    if (proband) {
      try {
        let { id, uuid } = proband;

        // when redux is resetting, skip notification calls
        if (id == null || uuid == null) return;

        let notifications = await notifications_api.post_clinician_notification(
          user.clinician_id,
          proband.id,
          proband.uuid
        );
        this.setState({ notifications });

        //let pending_notis = filterNotificationUpdates(notifications, 'pending');
        //this.markAllPendingAsRead(pending_notis);

      } catch (error) {
        console.log(error.stack);
      }
    }
  }

  async fetchHiddenDiseaseColors(proband_id) {
    let payload = {
      clinician_id: this.props.session.user.clinician_id,
      proband_id: proband_id,
      organization_id: this.props.session.user.organization_id
    };
    const hidden_disease_colors = await family_api.get_hidden_disease_colors_post(payload);
    return hidden_disease_colors;
  }

  async fetchProbandTree() {
    try {

      let redux_tree = await refinery_api.get_redux_tree_deux(this.props.match.params.member_id);
      let proband = redux_tree.proband;
      proband.dial_code = redux_tree.dial_code;
      proband.hash_key = new Date().getTime();

      redux_tree.ancestor.mother.ancestry = redux_tree.ancestor.mother.ancestry ?
        redux_tree.ancestor.mother.ancestry.map(item => {
          return ({ label: item.display_name, value: item.ancestry_id })
        }) : null;


      redux_tree.ancestor.father.ancestry = redux_tree.ancestor.father.ancestry ?
        redux_tree.ancestor.father.ancestry.map(item => {
          return ({ label: item.display_name, value: item.ancestry_id })
        }) : null;

      let proband_with_ancestor = { ...proband, ...redux_tree.ancestor };

      const objectArray = Object.entries(redux_tree.profiles);
      objectArray.forEach(([key, value]) => {
        if (value.twin_set === null) {
          value.twin_type = null;
        }
        if (value.ancestry.length > 0) {
          value.ancestry = value.ancestry.map((item => {
            return ({ label: item.display_name, value: item.ancestry_id })
          }))
        }
      });

      redux_tree.profiles = Object.fromEntries(objectArray);
      const patient_tree = {
        proband: proband_with_ancestor,
        profiles: redux_tree.profiles,
        diseases: redux_tree.diseases,
        genes: redux_tree.genetic_testings,
        gene_panels: redux_tree.gentic_testing_panels,
        partners: redux_tree.partners,
        sons_daughters: redux_tree.sons_daughters,
        uncles_aunts: redux_tree.uncles_aunts,
        siblings: redux_tree.siblings,
        saved_node_positions: redux_tree.saved_node_positions,
        cloned_members: redux_tree.cloned_members,
        linked_members: redux_tree.linked_members,
        member_links: redux_tree.member_links,
        donors: redux_tree.donors,
        sibling_orders: redux_tree.sibling_orders
      };

      // run only when rendering clinician portal pedigree
      if (!this.render_patient_pedigree) {

        let [hidden_disease_colors, notes, progeny_family, progeny_archive_preferences] = await Promise.all([
          this.fetchHiddenDiseaseColors(patient_tree.proband.id),
          family_api.get_family_pedigree_notes(patient_tree.proband.family_id),
          refinery_api.get_progeny_family(patient_tree.proband.family_id),
          refinery_api.get_progeny_archive_preferences(),
          this.fetchNotificationsByPatient(patient_tree.proband),
        ])

        patient_tree.hidden_disease_colors = hidden_disease_colors;
        patient_tree.pedigree_notes = notes.pedigree_notes[0];
        patient_tree.risk_settings = this.props.risk_settings;

        patient_tree.progeny_archive_data = null;
        if (progeny_family !== null && progeny_family !== undefined) {
          if (progeny_family) {
            if (isObject(progeny_family)) {
              if ('family' in progeny_family) {
                patient_tree.progeny_archive_data = progeny_family;
              }
            }
          }
        }

        patient_tree.progeny_archive_preferences = progeny_archive_preferences;
      }
      else {
        const hidden_disease_colors = await this.fetchHiddenDiseaseColors(patient_tree.proband.id);
        patient_tree.hidden_disease_colors = hidden_disease_colors;
      }

      this.buildPedigreeData(patient_tree);

    } catch (error) {
      throw error
    }
  }

  async handleNotificationAction() {
    try {
      // TODO: we need to update the patient tree and notify the pedigree drawer
      await this.fetchProbandTree();
    } catch (error) {
      console.log(error);
    }
  }

  async markAllPendingAsRead(pending_notifications) {
    if (pending_notifications.length > 0) {
      try {
        let { clinician_id } = this.props.session.user;
        this.bulkReadNotifications(pending_notifications, clinician_id)
      } catch (error) {
        console.log(error);
      }
    }
  }


  async bulkReadNotifications(pendingNotifications, clinician_id) {
    try {
      await notifications_api.post_notification_bulk_read(pendingNotifications, clinician_id)
    } catch (error) {
      console.log(error)
    }
  }
  async setNotificationAsRead(notification_id, clinician_id) {
    try {
      await notifications_api.post_notification_notificationid_read(notification_id, clinician_id);
    } catch (error) {
      console.log(error);
    }
  }

  async fetchRiskCriteria() {
    // /* NEW CODE */
    // /* Run risk as a promise */
    if (this.render_patient_pedigree) return
    const dispatch = this.props.dispatch;
    const setRiskCriteria = this.setRiskCriteria;
    risk_api.post_check_risk(this.props.match.params.member_id).then(risk_criteria_result => {
      dispatch(save_risk_criteria(risk_criteria_result.result));
      setRiskCriteria(risk_criteria_result.result);

      if (this.pedigreeWrapperRef.current){
        this.pedigreeWrapperRef.current.reRenderSidebarState();
      }
    }).catch(error => console.log(error));

    // reset risk results when a new proband is loaded
    dispatch(reset_risk_results(default_risk_results));
    this.setRiskResults(default_risk_results);
    // /********************************/
  }

  viewPatient() {
    let url = route_helper.patient.patient_profile.replace(":member_id", this.props.match.params.member_id) + "/#family-history";
    this.props.history.push(url);
  }

  render() {
    let member_id_toBeSelected = sessionStorage.getItem('member_id_toBeSelected')

    const pd_tab_active = this.state.showPatientDataTab ? "active" : "";
    const fh_tab_active = this.state.showFamilyHistoryTab ? "active" : "";
    const ra_tab_active = this.state.showRiskAssesmentTab ? "active" : "";

    let proband = null;
    let gender = 'Unknown';
    let gender_identity = null;
    let assigned_birth = null;

    if (!this.state.populating_proband) {
      proband = this.getPedigreeData().getProband();
      if (proband.gender && proband.gender.toLowerCase() === 'm') {
        gender = 'Male';
      } else if (proband.gender && proband.gender.toLowerCase() === 'f') {
        gender = 'Female';
      }
      if (proband.gender_identity == 'female') {
        gender_identity = 'Female';
      } else if (proband.gender_identity == 'male') {
        gender_identity = 'Male';
      } else if (proband.gender_identity == 'non-binary') {
        gender_identity = 'Non-Binary';
      }
      if (gender == 'Male') {
        assigned_birth = '(AMAB)';
      } else if (gender == 'Female') {
        assigned_birth = '(AFAB)';
      } else if (gender == 'Unknown') {
        assigned_birth = '(AUAB)';
      }
    }

    let { notifications } = this.state;
    let notification_updates = filterNotificationUpdates(notifications);
    let pending_notis = filterNotificationUpdates(notifications, 'pending');
    let session_downgraded_to_read_only = sessionStorage.getItem('downgraded_to_read_only') == 'true' ? true : false
    let display_read_only = this.props.session.user.read_only || session_downgraded_to_read_only ? true : false

    let is_emr_session = sessionStorage.getItem('is_emr_session')
    is_emr_session = is_emr_session == 'true' ? true : false

    let paddingTop = display_read_only == false ? '50px' : '0px';
    const isIE = false || !!document.documentMode;

    if (this.render_patient_pedigree || is_emr_session) {
      paddingTop = '0px'
    }

    return (
      <React.Fragment>
        {(!this.render_patient_pedigree && !is_emr_session) &&
          <NavTop history={this.props.history} onPedigree={true} personalDataOpen={this.state.personalDataOpen} displayReadOnly={display_read_only} />
        }

        {this.state.populating_proband && (
          <ActivityIndicator loading={true} pedigree={true} />
        )}

        {!this.state.populating_proband && (
          <section style={{ paddingTop: paddingTop }} className="wow fadeIn wrap animated">
            {display_read_only == false && !this.render_patient_pedigree && (
              <div className="patient-nav">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="patient-details">
                        <p>
                          <span className="name">{proband.first_name} {proband.last_name}</span>{" "}
                          <span className="number">#{proband.patient_id}: </span>{" "}
                          <span>{(gender_identity && gender_identity !== gender) ? gender_identity : gender} {(gender_identity && gender_identity !== gender) ? assigned_birth : ""}, {proband.age_string ? proband.age_string : proband.age ? proband.age : ''}</span>
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 nav--center">
                      <ul className="nav nav-pills center-pills">
                        <li className={pd_tab_active}>
                          <button
                            className="btn-link"
                            onClick={this.handlePDTabClicked}
                          >
                            Patient Data
                          </button>
                        </li>
                        <li className={fh_tab_active}>
                          <button
                            id="pedigree_link"
                            className="btn-link"
                            onClick={() => this.handleFHTabClicked()}
                          >
                            Family History
                          </button>
                        </li>
                        <li className={ra_tab_active}>
                          <button
                            id="risk_assessment_link"
                            className="btn-link"
                            onClick={this.handleRATabClicked}
                          >
                            Risk Assessment
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-3">
                      <div className="patient-details center-pills">
                        <p className="pr-10">
                          <Link to="#patient-updates" className="patient-details--update" onClick={(e) => this.handlePatientUpdatesClick(e)}>
                            Updates&nbsp;
                            {pending_notis.length > 0 && (
                              <span className="badge badge-success name">
                                {pending_notis.length}
                              </span>
                            )}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            {this.state.showPatientDataTab &&
              <PatientDataTabs
                dispatch={this.props.dispatch}
                session={this.props.session}
                proband_id={this.getPedigreeData().proband.id}
                getPedigreeData={this.getPedigreeData}
              />
            }

            {this.state.showFamilyHistoryTab && this.getPedigreeData().getProband() !== null && (
              <PedigreeWrapper
                ref={this.pedigreeWrapperRef}
                key={this.pedigree_wrapper_key}
                setRiskCriteria={this.setRiskCriteria}
                getPedigreeData={this.getPedigreeData}
                getPedigreeDrawingData={this.getPedigreeDrawingData}
                getConnectionWatcher={this.getConnectionWatcher}
                read_only={display_read_only}
                user={this.props.session.user}
                memberid={this.props.match.params.member_id}
                organization_id={this.props.session.user.organization_id}
                clinician_id={this.props.session.user.clinician_id}
                reRenderTopBarFunction={() => this.reRenderTopBarFunction()}
                history={this.props.history}
                fetchProbandTree={this.fetchProbandTree}
                member_id_toBeSelected={member_id_toBeSelected}
                session={this.props.session}
                dispatch={this.props.dispatch}
                patient_updates_view={false}
              />
            )}

            {display_read_only == true && (
              <div>
                <div className="patient-details-emr">
                  <p>
                    <span className="name">{proband.first_name} {proband.last_name}, </span>{" "}
                    <span>{proband.age}</span>
                  </p>
                </div>
              </div>
            )}

            {this.state.showRiskAssesmentTab && <RiskAssessmentTabs session={this.props.session} getPedigreeData={this.getPedigreeData} />}

            {this.state.showPatientUpdates && <PatientUpdates
              NotificationAction={this.handleNotificationAction}
              notificationUpdates={notification_updates}
              key={this.pedigree_wrapper_key}
              setRiskCriteria={this.setRiskCriteria}
              getPedigreeData={this.getPedigreeData}
              getPedigreeDrawingData={this.getPedigreeDrawingData}
              getConnectionWatcher={this.getConnectionWatcher}
              read_only={display_read_only}
              user={this.props.session.user}
              memberid={this.props.match.params.member_id}
              organization_id={this.props.session.user.organization_id}
              clinician_id={this.props.session.user.clinician_id}
              reRenderTopBarFunction={() => this.reRenderTopBarFunction()}
              history={this.props.history}
              fetchProbandTree={this.fetchProbandTree}
              member_id_toBeSelected={member_id_toBeSelected}
              session={this.props.session}
              dispatch={this.props.dispatch}
            />}

          </section>
        )}


      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  session: state.session,
  risk_settings: state.patient.risk_settings
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(redux_state, redux_actions)(PedigreePage);
