import React, { Component } from 'react';
import { connect } from "react-redux";
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import { createUUID } from '../react-flow-pedigree/utils';
import CopySurveyModal from './survey-copy-modal';
import DeleteSurveyModal from './survey-delete-modal';
import AddSurveyModal from './add-survey-modal';
import * as helper_search from "../../helpers/helper-search";
import SurveyBuilder from './survey-builder';

interface  ModalProps{
    searchFilter: any;
    dispatch: (action:any) => void;
    surveyList: Array<any>;
    questionList: Array<any>;
    columns: any;
    showAddSurveyModal: boolean;
    updateAddSurveyModal: () => void;
    setSurveyBuilderOpened: (surveyBuilderOpened: boolean) => void;
    setSurveyList: (surveyList: any) => void;
  }

interface State{
  surveyList: Array<any>;
  questionList: Array<any>;
  columns: Array<any>;
  searchText: string;
  showCopyModal: boolean;
  showdeleteSurveyModal: boolean;
  surveyToCopy: any;
  surveyToDeleteName: string;
  surveyToDeleteId: any;
  showSurveyBuilderScreen: Array<any>;
}

type Survey = {
  name: string;
  organization: string;
  id: number
  translation_key: string;
}

type CopySurveyPayload = {
  newSurveyName: string;
  surveyToCopy: string;
}

type DeleteSurveyPayload = {
  surveyToDelete: string
}


class SurveyList extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: [],
          questionList: [],
          columns: [],
          searchText: "",
          showCopyModal: false,
          surveyToCopy: null,
          surveyToDeleteName: "",
          showdeleteSurveyModal: false,
          surveyToDeleteId: null,
          showSurveyBuilderScreen: [false, "", undefined, ""],
        };

      this.openCopyModal = this.openCopyModal.bind(this);
      this.onClose = this.onClose.bind(this);
      this.continueAddSurvey = this.continueAddSurvey.bind(this);

    }
  componentWillUnmount(): void {
    helper_search.updateSearchText(this.props.dispatch, "");
  }



  openCopyModal(survey: Survey){

    this.setState({
      showCopyModal: true,
      showdeleteSurveyModal: false,
      surveyToCopy: survey.id
    })
  }

  openDeleteModal(survey: Survey){

    this.setState({
      showCopyModal: false,
      showdeleteSurveyModal: true,
      surveyToDeleteName: survey.name,
      surveyToDeleteId: survey.id
    })
  }

  onClose(){
    this.setState({
      showCopyModal: false,
      showdeleteSurveyModal: false
    })
  }

  async onSave(title: string){

    let payload: CopySurveyPayload = {
      newSurveyName: title,
      surveyToCopy: this.state.surveyToCopy
    }

    try{
      await userAccountApi.create_new_survey_copy(payload)
    }catch(err){
      console.log(err)
    }finally{
      this.onClose()
    }

  }

  async deleteSurvey(){
    let payload: DeleteSurveyPayload = {
      surveyToDelete: this.state.surveyToDeleteId,
    }
    try{
      await userAccountApi.delete_survey(payload);
      let surveyList = this.props.surveyList.filter((survey: Survey) => survey.id !== this.state.surveyToDeleteId)
      this.props.setSurveyList(surveyList)
    }catch(err){
      console.log(err)
    }finally{
      this.onClose()
    }
  }


  async continueAddSurvey(surveyName: string){
    try{
      this.setState({ showSurveyBuilderScreen: [true, surveyName, undefined, 'add'] }, () => this.props.setSurveyBuilderOpened(true))
    }catch(err){
      console.log(err)
    }finally{
      this.props.updateAddSurveyModal()
    }
  }

  handleEditSurveyClick(survey: Survey){
    try{
      this.setState({ showSurveyBuilderScreen: [true, survey.name, survey.id, 'edit'] }, () => this.props.setSurveyBuilderOpened(true))
    }catch(err){
      console.log(err)
    }
  }

  hideSurveyBuilderScreen(){
    try{
      // await userAccountApi.create_new_survey(surveyName)
      this.setState({showSurveyBuilderScreen: [false, '', undefined, '']}, () => this.props.setSurveyBuilderOpened(false))
    }catch(err){
      console.log(err)
    }finally{
      this.props.updateAddSurveyModal()
    }
  }

render() {
    return(
      <React.Fragment>

        {this.state.showSurveyBuilderScreen[0] ?
          <SurveyBuilder
            surveyName={this.state.showSurveyBuilderScreen[1]}
            surveyId={this.state.showSurveyBuilderScreen[2]}
            surveyAction={this.state.showSurveyBuilderScreen[3]}
            questionList={this.props.questionList}
            surveyList={this.props.surveyList}
            setSurveyList={(surveyList) => this.props.setSurveyList(surveyList)}
            hideSurveyBuilderScreen={() => this.hideSurveyBuilderScreen()}
          />
        :
        <div>
          <table id="surveyTable" className="table table-custom ">
              <thead>
                <tr>
                  {this.props.columns.map((item: any , index: number)=> {
                    return <th key={index}>{item.label}</th>
                  })}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.props.surveyList.map((survey: any) => {
                  return (
                    <React.Fragment key={createUUID()}>
                      <tr>
                        {this.props.columns.map((item: any) => {
                          if (item.value === 'name') {
                            return <td key={createUUID()}>{survey['name']}</td>
                          }
                          else if(item.value === 'organization'){
                            return <td key={createUUID()}>{survey['organizations']}</td>
                          }
                        })}
                        <td className="middle">
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.handleEditSurveyClick(survey)}><i className="fa fa-eye"></i>&nbsp;Edit</button>
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openCopyModal(survey)}><i className="fa fa-eye"></i>&nbsp;Copy</button>
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openDeleteModal(survey)}><i className="fa fa-eye"></i>&nbsp;Delete</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
        </div>}

        {this.state.showCopyModal &&(
            <CopySurveyModal
              modalTitle='Copy Survey'
              onClose={this.onClose}
              onSave={(name)=> this.onSave(name)}
            />
          )}
        {this.state.showdeleteSurveyModal &&(
            <DeleteSurveyModal
              survey={this.state.surveyToDeleteName}
              modalTitle='Delete Survey'
              onClose={this.onClose}
              onDelete={()=>this.deleteSurvey()}
            />
          )}
        {this.props.showAddSurveyModal &&(
            <AddSurveyModal
              title='Add Survey'
              surveyName=''
              onClose={() => this.props.updateAddSurveyModal()}
              continueAddSurvey={(surveyName) => this.continueAddSurvey(surveyName)}
            />
          )}
      </React.Fragment>

    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions, null, { forwardRef: true })(SurveyList);
