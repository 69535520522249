import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavTop from '../nav-top';
import SurveyList from './survey-list';
import SurveySearch from './survey-search';
import SurveyQuestionList from './survey-questions';
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import defaultQuestionColumns from "./default-question-colummns.json";
import { connect } from "react-redux";
import surveyQuestions from './survey-questions';

interface ModalProps {
    user: any;
    history: any;
    searchFilter: any;
  }


interface State{
  surveyList: any;
  questionList: any;
  surveyColumns: any;
  activeTab: string;
  openAddModal: boolean;
  questions: any;
  questionColumns: any;
  showAddSurveyModal: boolean;
  surveyBuilderOpened: boolean;
}

class SurveyListPage extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: [],
          questionList: [],
          surveyColumns: [],
          activeTab: 'surveys',
          questionColumns: [],
          questions: [],
          openAddModal: false,
          showAddSurveyModal: false,
          surveyBuilderOpened: false
        };

      this.componentDidMount = this.componentDidMount.bind(this);
      this.searchSurveys = this.searchSurveys.bind(this);
      this.fetchSurveys = this.fetchSurveys.bind(this);
      this.updateActiveTab = this.updateActiveTab.bind(this);
      this.fetchQuestions = this.fetchQuestions.bind(this);
      this.orderSurveys = this.orderSurveys.bind(this);
      this.orderQuestions = this.orderQuestions.bind(this);
    }
  async componentDidMount(){
    try {
      await this.fetchSurveys();
      await this.fetchQuestions();
    } catch (error) {
      console.error('Error in componentDidMount:', error);
    }
  }

  async fetchSurveys(){
    let payload = {
      search_text: this.props.searchFilter.search_text
    }
    try{
      let surveys = await userAccountApi.get_surveys_with_orgs(payload)
      let orderedSurveys = this.orderSurveys(surveys.surveys_serialized)
      this.setState({
        surveyList: orderedSurveys,
        questionList: surveys.questions_serialized,
        surveyColumns: defaultColumns
      })
    }catch(err){
      console.dir(err)
    }
  }
  orderSurveys(surveys: any){
    let orderedSurveys = surveys.sort((a: any, b: any) => {
      return (a.name > b.name) ? 1 : -1
    })
    return orderedSurveys
  }
  async searchSurveys(){
    if(this.state.activeTab === 'surveys'){
      await this.fetchSurveys()
    }
    if(this.state.activeTab === 'questions'){
      await this.fetchQuestions()
    }
  }

  async fetchQuestions(){

    let payload = {
      search_text: this.props.searchFilter.search_text
    }

    try{
      let questions = await userAccountApi.get_surveys_questions(payload)
      let ordered_questions = this.orderQuestions(questions)
      this.setState({
        questionList: ordered_questions,
        questions: questions,
        questionColumns: defaultQuestionColumns
      })
    }catch(err){
      console.dir(err)
    }
  }

  orderQuestions(questions: any) {
    let orderedQuestions = questions.sort((a: any, b: any) => {
      //First make sure empty labels are empty string if null
      const labelA = a.question_label || '';
      const labelB = b.question_label || '';
      // Make sure empty labels are at the end
      if (labelA && !labelB) {
        return -1;
      } else if (!labelA && labelB) {
        return 1;
      } else {
        return labelA > labelB ? 1 : -1;
      }
    });
    return orderedQuestions;
  }
  updateActiveTab = (tab: string) => {
    this.setState({activeTab: tab})
  }

render() {
    return ReactDOM.createPortal(
    <React.Fragment>
      <NavTop
        history={this.props.history}
        page='survey-results'
      />
        <section className="wow fadeIn section">
          <div className="container">

            {!this.state.surveyBuilderOpened && (
              <SurveySearch
                title={ this.state.activeTab === 'surveys' ? "Search Surveys..." : 'Search Questions...'}
                searchSurveys={this.searchSurveys}
              />
            )}

              <div className="row">

                {!this.state.surveyBuilderOpened && (
                  <div className="col-md-16" style={{ marginRight: '-13px', marginTop: '20px' }}>
                    <ul className="nav nav-tabs">
                      <li className={this.state.activeTab === 'surveys' ? "active" : ""}>
                        <button className="btn-link" onClick={() => this.updateActiveTab('surveys')}>
                          Surveys
                        </button>
                      </li>
                      <li className={this.state.activeTab === 'questions' ? "active" : ""}>
                        <button className="btn-link" onClick={() => this.updateActiveTab('questions')}>
                          Questions
                        </button>
                      </li>
                      {this.state.activeTab === 'questions' && (
                        <div className="ms-auto" style={{ float: 'right' }}>
                          <button onClick={() => this.setState({ openAddModal: true })} className="btn btn-light btn-xs" style={{ marginBottom: '7px' }} >
                            <i className="fa fa-plus-circle"> </i>
                            Add Question</button>
                        </div>
                      )}
                      {this.state.activeTab === 'surveys' && (
                        <div className="ms-auto" style={{ float: 'right' }}>
                          <button
                            onClick={() => this.setState({ showAddSurveyModal: true })}
                            className="btn btn-light btn-xs"><i className="fa fa-plus-circle"></i> Add Survey</button>
                        </div>
                      )}
                    </ul>

                  </div>
                )}

                <div className="col-md-12">
                  {this.state.activeTab === 'surveys' &&(
                    <SurveyList
                      surveyList={this.state.surveyList}
                      setSurveyList={(surveyList: any) => this.setState({ surveyList })}
                      questionList={this.state.questionList}
                      showAddSurveyModal={this.state.showAddSurveyModal}
                      updateAddSurveyModal={()=>this.setState({showAddSurveyModal: false})}
                      setSurveyBuilderOpened={(surveyBuilderOpened: boolean) => this.setState({surveyBuilderOpened})}
                      columns={this.state.surveyColumns}
                    />
                  )}
                  {this.state.activeTab === 'questions' && (
                    <SurveyQuestionList
                      questionList={this.state.questions}
                      columns={this.state.questionColumns}
                      openAddModal={this.state.openAddModal}
                      closeAddModal={()=>this.setState({openAddModal: false})}
                      updateQuestionList={this.fetchQuestions}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
    </React.Fragment>,

    document.body
    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyListPage);

