import { Position } from '@xyflow/react';
import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
import React from 'react';
import { isObject } from 'lodash';
import $ from 'jquery';
import {
  skipLogicCheck
} from './skip_logic_utils'
import {
  PersonNodeWidth,
  PersonNodeHeight,
  ConnectorNodeWidth,
  ConnectorNodeHeight,
  PedigreeStylesheet
} from './pedigree-constants';

/*********************Start Edge Utils*********************/
// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    position: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.position;

  // const w = intersectionNodeWidth / 2;
  // const h = intersectionNodeHeight / 2;

  // a bit of a hack, I had to set the height and width to 40px for person nodes
  // so the edge calculation is correct. 3 is the width & height of connector nodes
  let interWidth = intersectionNodeWidth;
  if (interWidth !== ConnectorNodeWidth) {
    interWidth = PersonNodeWidth;
  }

  let interHeight = intersectionNodeHeight;
  if (interHeight !== ConnectorNodeHeight) {
    interHeight = PersonNodeHeight;
  }
  const w = interWidth / 2;
  const h = interHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  let x = x2;//w * (xx3 + yy3) + x2;
  let y = h * (-xx3 + yy3) + y2;
  x = intersectionNodePosition.x;
  y = intersectionNodePosition.y;
//   console.log(intersectionNode);
  if (intersectionNode.nodeType == "Person")
  {
	  x += 19;
	  y += 19;
  }
  else
  {
	  x +=1;
    y +=1;
  }
  return { x, y };
// return {targetPosition.x,targetPosition.y};
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.position, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
	const sourceIntersectionPoint = getNodeIntersection(source, target);
	const targetIntersectionPoint = getNodeIntersection(target, source);

	const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
	const targetPos = getEdgePosition(target, targetIntersectionPoint);

	if (source.type === "default") {
    let p = source.position;
    //sx x offset shifts top of line stemming from default node right one pixel, aligning it with center of node
    //tx x offset shifts the default target node one pixel, centering it between
    let xOffset = 1.25;
    let targetOffset = .4
    //
    //targeting nodes connecting default nodes connecting to eachother
    //offset by 1px allowing for a straight line
    //target offset fixes the default nodes being offcentered
    //if not default left node thas being targeted keep target position
    let setTarget = false;
    if(target.type !== 'default' && target.targetPosition !=='left'){
      setTarget = true;
    }

    return {
      sx: p.x,
      sy: p.y + 1, // adding 1 is a hack to make sure the connector dot is on the edge line
      tx: (setTarget) ? targetIntersectionPoint.x + xOffset : targetIntersectionPoint.x + targetOffset,
      ty: targetIntersectionPoint.y,
      sourcePos,
      targetPos,
    };
  } else {
    let xOffset = 0.6;

    return {
      sx: sourceIntersectionPoint.x,
      sy: sourceIntersectionPoint.y,
      tx: targetIntersectionPoint.x + xOffset,
      ty: targetIntersectionPoint.y,
      sourcePos,
      targetPos,
    };
  }
}

export function getCenterPoint(left_node_pos, right_node_pos){
  let x1 = left_node_pos.x + 20
  let x2 = right_node_pos.x + 20
  let center_x = (x1 + x2) / 2
  let center_y = left_node_pos.y
  return {x: center_x, y: center_y}
}

/**********************End Edge Utils**********************/

export function generateUUID() {
  function part() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1).toUpperCase();
  };

  return {
      NewGuid: function () { return (part() + part() + "-" + part() + "-" + part() + "-" + part() + "-" + part() + part() + part()); },
  };
}

export function createUUID(){
  if(document.documentMode){
    return generateUUID().NewGuid()
  }else{
    return uuidv4()
  }
}

function fetchMulti(parentid, relationships){
  relationships = relationships.filter(rs => {
    let couples = rs.couples
    return couples.includes(parentid)
  })
  return [...new Set(relationships.map(item => item.id))];
}

function getParentId(father_id, mother_id, relationships){
  /*
  * This hack is to account for couples where the genders are unknown
  * so it could be possible that we need to check motherid and fatherid for the person
  */
  let parent = relationships.find(rs => rs.father === father_id && rs.mother === mother_id)
  if (parent === undefined || parent === "undefined") {
    parent = relationships.find(rs => rs.father === mother_id && rs.mother === father_id)
  }
  let parent_id = parent.id
  return parent_id
}

function wordWrap(str, maxWidth) {
  let newLineStr = "\n";
  let done = false;
  let res = '';
  while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
          if (testNewline(str.charAt(i))) {
              res = res + [str.slice(0, i), newLineStr].join('');
              str = str.slice(i + 1);
              found = true;
              break;
          }
          else if (testWhite(str.charAt(i))) {
              res = res + [str.slice(0, i), ' '].join('');
              str = str.slice(i + 1);
              found = true;
              break;
          }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
          res += [str.slice(0, maxWidth), newLineStr].join('');
          str = str.slice(maxWidth);
      }

  }

  return res + str + ` `;
}

function testNewline(x) {
  var newline = new RegExp(/^\n$/);
  return newline.test(x.charAt(0));
}

function testWhite(x) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

function getDiseaseSubtext(diseases){
  let diseases_subtext = ''
  for(let disease of diseases){
    let skip_logic = skipLogicCheck(disease)
    // adjustment here for subtext width
    let content = `${disease.disease_short_name + (disease.age_diagnosed_string ? ' ' + disease.age_diagnosed_string : disease.age_diagnosed ? ' ' + disease.age_diagnosed : '')}`;
    if(skip_logic){
      content += `\n${skip_logic}`;
    }

    diseases_subtext += `\n${wordWrap(content, 19)}`;
  }
  diseases_subtext = diseases_subtext.substring(1)
  return diseases_subtext
}


function getGeneticTestingString(profile, gene_panels){
  let content = '';
  let nonNegative = [];
  let noPanelResults = false;
  let panel_status = '';
// Loop through each gene panel and collect non-negative results
  gene_panels.forEach(panel => {
    panel_status = panel.panel_status

    const {member_genes} = panel
    noPanelResults =  panel.member_genes.every(geneTest => (geneTest.result === null || geneTest.result === ""));

    const filteredGenes = member_genes.filter(geneTest => geneTest.result !== "n" && geneTest.result !== null);
    nonNegative.push(...filteredGenes); // Spread to add multiple items
  });


  if (profile !== null) {
    const profileFiltered = profile.filter(geneTest => geneTest.result !== "n");
    nonNegative.push(...profileFiltered); // Spread to add multiple items
  }

  if (nonNegative.length !== 0){
    for(let gene of nonNegative){
      let name = gene.gene || gene.umls_name
      if(gene.result === "p") {
        gene.result = "Pathogenic"
      }
      else if(gene.result === "lp"){
        gene.result = "Likely Pathogenic"
      }
      else if(gene.result === "ln"){
        gene.result = "Likely Benign"
      }
      else if(gene.result === "u"){
        gene.result = "Unsure"
      }
      else if(gene.result === "vus"){
        gene.result = "VUS"
      }
      content += name + " - " + gene.result + ", "
    }
    content = content.slice(0, -2)
  }else if(noPanelResults && panel_status === "canceled"){
    content = "Genetic Testing Canceled"
  }else if(noPanelResults){
    content = "Genetic Testing Pending"
  }
  else if (nonNegative.length === 0 && ((profile !== null && profile.length !== 0) || gene_panels.length !== 0)){
    content = "Negative genetic testing"
  }
  else{
    content = '';
  }

  // wrap text so api endpoint would know how many spaces so it won't overlap
  // adjustment here for subtext width
  content = wordWrap(content, 19)

  return content;
}

function arraysEqual(a, b) {

  let aCopy = JSON.parse(JSON.stringify(a));
  let bCopy = JSON.parse(JSON.stringify(b));

  aCopy.sort();
  bCopy.sort();

  if (aCopy === bCopy) return true;
  if (aCopy == null || bCopy == null) return false;
  if (aCopy.length !== bCopy.length) return false;

  for (var i = 0; i < aCopy.length; ++i) {
    if (aCopy[i] !== bCopy[i]) return false;
  }
  return true;
}

function getDonorsList(donors, people, relationships, hidden_members, layout_version){

  let donors_list = [];

  if(donors.length > 0){
    let people_with_donors = people.filter(person => donors.some(donor => donor.child_id == person.id));

    for(let person of people_with_donors){
      let donors_of_person = donors.filter(donor => donor.child_id == person.id);

      let biological_father = people.find(p => p.id == person.father_id);
      let biological_mother = people.find(p => p.id == person.mother_id);

      let donor_info_array = [];

      let couple_id = null;

      if(layout_version == "simple"){
        couple_id = relationships.find(rs => (rs.left == biological_father.id + '' && rs.right == biological_mother.id + '') || (rs.right == biological_father.id + '' && rs.left == biological_mother.id + '')).id;
      }
      else{
        let couple_relationships =  relationships.filter(rs => rs.type === "couple");
        couple_id = couple_relationships.find(cr => (cr.father + '' == biological_father.id + '' && cr.mother + '' == biological_mother.id + '') || (cr.mother + '' == biological_father.id + '' && cr.father + '' == biological_mother.id + '')).id;
      }

      for(let d of donors_of_person){
        let type = 'half';
        let parent_id = biological_father.id;

        if(d.donor_type == 'sperm_donor'){
          //sperm donor is always type full
          type = 'full';
          parent_id = biological_father.id;

          //sperm donor only
          // if(donors_of_person.length == 1){
            // if parents are same sex partners, then it would depend if either are blood related to the proband or not
          if(biological_father.gender == biological_mother.gender){
            if(donors_of_person.length == 1){
              parent_id = biological_father.is_blood_related_to_proband ? biological_mother.id : biological_father.id;
            }
            else if(donors_of_person.length > 1){
              let other_donor = donors_of_person.find(dop => dop.id != d.id);

              //sperm donor and surrogate egg donor
              if(other_donor.donor_type == 'surrogate_egg_donor'){
                parent_id = biological_father.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
              }
            }
          }
          else{
            parent_id = biological_father.id;
          }
          // }
          // else if (donors_of_person.length > 1){
          //   // if parents are same sex partners and have other donors, then
          //   if(biological_father.gender == biological_mother.gender){
          //     let other_donor = donors_of_person.find(dop => dop.id != d.id);
          //     //sperm donor and egg donor
          //     if(other_donor.donor_type == 'egg_donor'){
          //       parent_id = biological_father.id;
          //     }
          //     //sperm donor and surrogate
          //     else if(other_donor.donor_type == 'surrogate'){
          //       parent_id = biological_father.id;
          //     }
          //     //sperm donor and surrogate egg donor
          //     else if(other_donor.donor_type == 'surrogate_egg_donor'){
          //       parent_id = biological_father.id;
          //     }
          //   }
          // }

        }
        else if(d.donor_type == 'egg_donor'){
          //egg donor only
          if(donors_of_person.length == 1){
            type = 'half';
            parent_id = biological_mother.id;
          }
          else if(donors_of_person.length > 1){
            let other_donors = donors_of_person.filter(dop => dop.id != d.id);

            if(other_donors.length == 1){
              //egg donor and sperm donor
              if(other_donors[0].donor_type == 'sperm_donor'){
                type = 'half';
                parent_id = biological_mother.id;
              }
              //egg donor and surrogate
              else if(other_donors[0].donor_type == 'surrogate'){
                type = 'full';

                // if parents are same sex partners, then it would depend if either are blood related to the proband or not
                if(biological_father.gender == biological_mother.gender){
                  if((biological_father.id == other_donors[0].donor_id && biological_father.id == d.donor_id) || (biological_mother.id == other_donors[0].donor_id && biological_mother.id == d.donor_id)){
                    parent_id = biological_father.id;
                  }
                  else{
                    parent_id = biological_mother.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
                  }
                }
                else{
                  parent_id = biological_mother.id;
                }
              }
            }
            else{
              // if sperm, egg, and surrogate are all present
              type = 'full';
              parent_id = biological_mother.id;
              let other_donors_include_surrogate = other_donors.find(other_donor => other_donor.donor_type == 'surrogate');

              if(other_donors_include_surrogate){
                // if parents are same sex partners, then it would depend if either are blood related to the proband or not
                if(biological_father.gender == biological_mother.gender){
                  if((biological_father.id == other_donors[0].donor_id && biological_father.id == d.donor_id) || (biological_mother.id == other_donors[0].donor_id && biological_mother.id == d.donor_id)){
                    parent_id = biological_mother.id;
                  }
                  else{
                    parent_id = biological_mother.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
                  }
                }
                else{
                  parent_id = biological_mother.id;
                }
              }
            }
          }
        }
        else if(d.donor_type == 'surrogate'){
          //surrogate only
          if(donors_of_person.length == 1){
            type = 'half';
            parent_id = biological_mother.id;
          }
          else if(donors_of_person.length > 1){
            let other_donors = donors_of_person.filter(dop => dop.id != d.id);

            if(other_donors.length == 1){
              //surrogate and sperm donor
              if(other_donors[0].donor_type == 'sperm_donor'){
                type = 'half';
                parent_id = biological_mother.id;
              }
              //surrogate and egg donor
              else if(other_donors[0].donor_type == 'egg_donor'){
                type = 'full';
                parent_id = biological_mother.id;

                // if parents are same sex partners, then it would depend if either are blood related to the proband or not
                if(biological_father.gender == biological_mother.gender){
                  if((biological_father.id == other_donors[0].donor_id && biological_father.id == d.donor_id) || (biological_mother.id == other_donors[0].donor_id && biological_mother.id == d.donor_id)){
                    parent_id = biological_mother.id;
                  }
                  else{
                    parent_id = biological_mother.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
                  }
                }
                else{
                  parent_id = biological_mother.id;
                }
              }
            }
            else{
              // if sperm, egg, and surrogate are all present
              type = 'full';
              parent_id = biological_mother.id;
              let other_donors_include_egg_donor = other_donors.find(other_donor => other_donor.donor_type == 'egg_donor');

              if(other_donors_include_egg_donor){
                // if parents are same sex partners, then it would depend if either are blood related to the proband or not
                if(biological_father.gender == biological_mother.gender){
                  if((biological_father.id == other_donors[0].donor_id && biological_father.id == d.donor_id) || (biological_mother.id == other_donors[0].donor_id && biological_mother.id == d.donor_id)){
                    parent_id = biological_mother.id;
                  }
                  else{
                    parent_id = biological_mother.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
                  }
                }
                else{
                  parent_id = biological_mother.id;
                }
              }
            }
          }
        }
        else if(d.donor_type == 'surrogate_egg_donor'){
          //surrogate egg donor only
          type = 'full';
          parent_id = biological_mother.id;

          // if parents are same sex partners, then it would depend if either are blood related to the proband or not
          if(biological_father.gender == biological_mother.gender){
            if(biological_father.id == d.donor_id){
              parent_id = biological_mother.id;
            }
            else if(biological_mother.id == d.donor_id){
              parent_id = biological_father.id;
            }
            else{
              parent_id = biological_mother.is_blood_related_to_proband ? biological_father.id : biological_mother.id;
            }
          }
          else{
            parent_id = biological_mother.id;
          }
        }

        let parent_is_hidden = hidden_members.find(hm => hm.id + '' == parent_id + '');

        let donor_info_object = {
          id: String(d.id),
          donor_id: String(d.donor_id),
          couple_id,
          // parent_id: d.donor_type == 'sperm_donor' ? biological_father.id + '' : biological_mother.id + '', // if sperm donor then should be biological father?
          // type: d.donor_type == 'sperm_donor' ? "full" : d.donor_type == 'surrogate' ? "half" : donors_of_person.length > 1 ? "half" : "full",
          parent_id: parent_is_hidden ? null : parent_id + '',
          type
        }

        if(!d.is_random_donor){
          donor_info_object.in_pedigree = true;
        }

        donor_info_array.push(donor_info_object);
      }

      let donor_record_already_pushed = donors_list.filter(d => d.donor_info.find(di => donor_info_array.find(dia => di.donor_id == dia.donor_id))).length > 0

      if(donor_record_already_pushed){
        let donor_records_existing = donors_list.filter(d => d.donor_info.find(di => donor_info_array.find(dia => di.donor_id == dia.donor_id)));

        for(let dr of donor_records_existing){
          let is_a_sibling = people.find(p => p.id == dr.child_id_list[0]);
          is_a_sibling = is_a_sibling.father_id == person.father_id && is_a_sibling.mother_id == person.mother_id;

          if(is_a_sibling){
            let donors_of_one_child = donors.filter(d => d.child_id == dr.child_id_list[0]).map(d => d.donor_id);
            let siblings_have_same_exact_donors = arraysEqual(donors_of_person.map(d => d.donor_id), donors_of_one_child);

            // if sibling have same exact donors, push to the existing donor record
            if(siblings_have_same_exact_donors){
              // =====================================================================
              // let donor_record_index = donors_list.findIndex(d => d.donor_info.find(di => donor_info_array.find(dia => di.id == dia.id)));
              let donor_record_index = donors_list.findIndex(d => d.donor_info.find(di => donor_info_array.find(dia => di.donor_id == dia.donor_id && di.couple_id == dia.couple_id && di.type == dia.type)));
              donors_list[donor_record_index].donor_info = donor_info_array;
              donors_list[donor_record_index].child_id_list.push(String(person.id));
              // dr.child_id_list.push(String(person.id));
              // =====================================================================


              // ===============================================
              // donor_info_array.forEach(donor_info => {
              //   donor_info.in_pedigree = true;
              // });
              // let donor_record = {
              //   donor_info: donor_info_array,
              //   child_id_list: [String(person.id)]
              // }
              // donors_list.push(donor_record)
              // ===============================================
            }
            // if they don't have the same exact donors, create a new donor record
            else{
              // set as in pedigree since it's already existing on the donors list
              donor_info_array.forEach(donor_info => {
                let donor_already_in_pedigree = donors_list.find(d => d.donor_info.find(di => di.donor_id == donor_info.donor_id));
                if(donor_already_in_pedigree){
                  donor_info.in_pedigree = true;
                }
              });
              // make sure in pedigree donors are first in the array
              donor_info_array = donor_info_array.sort((a, b) => {
                if(a.in_pedigree){
                  return -1;
                }
                return 0;
              });
              let donor_record = {
                donor_info: donor_info_array,
                child_id_list: [String(person.id)]
              }
              donors_list.push(donor_record)
            }
          }
          else{
            // set as in pedigree since it's already existing on the donors list
            donor_info_array.forEach(donor_info => {
              let donor_already_in_pedigree = donors_list.find(d => d.donor_info.find(di => di.donor_id == donor_info.donor_id));
              if(donor_already_in_pedigree){
                donor_info.in_pedigree = true;
              }
            });
            // make sure in pedigree donors are first in the array
            donor_info_array = donor_info_array.sort((a, b) => {
              if(a.in_pedigree){
                return -1;
              }
              return 0;
            });
            let donor_record = {
              donor_info: donor_info_array,
              child_id_list: [String(person.id)]
            }
            donors_list.push(donor_record)
          }
          break;
        }
      }
      else{
        // make sure in pedigree donors are first in the array
        donor_info_array = donor_info_array.sort((a, b) => {
          if(a.in_pedigree){
            return -1;
          }
          return 0;
        });
        let donor_record = {
          donor_info: donor_info_array,
          child_id_list: [String(person.id)]
        }
        donors_list.push(donor_record)
      }
    }

  }

  let test_donors_list = [
    {
      "donor_info": [
          {
              "id": "201",
              "donor_id": "50744",
              "couple_id": "r-17618",
              "parent_id": "50745",
              "type": "full"
          }
      ],
      "child_id_list": [
          "50746", "43740"
      ]
    }
  ]


  let test_donors_list_2 = [
    {
      "donor_info": [
        {
          "id": "199",
          "donor_id": "50744",
          "couple_id": "r-15168",
          "parent_id": "43739",
          "type": "full",
        }
      ],
      "child_id_list": [
        "43740"
      ]
    },
    {
      "donor_info": [
        {
          "id": "201",
          "donor_id": "50744",
          "couple_id": "r-17618",
          "parent_id": "50745",
          "type": "full",
          "in_pedigree": true
        }
      ],
      "child_id_list": [
        "50746"
      ]
    }
  ]

  let test_donors_list_3 = [
    {
      "donor_info": [
        {
          "id": "202",
          "donor_id": "50744",
          "couple_id": "r-15168",
          "parent_id": "43739",
          "type": "full"
        }
      ],
      "child_id_list": [
        "43740",
        "43741"
      ]
    }
  ]

  let test_donors_list_4 = [
    {
      "donor_info": [
        {
          "id": "208",
          "donor_id": "50750",
          "couple_id": "r-15168",
          "parent_id": "43739",
          "type": "half"
        },
      ],
      "child_id_list": [
        "43740",
        "43741"
      ]
    }
  ]

  let test_donors_list_5 = [
    {
      "donor_info": [
        {
          "id": "216",
          "donor_id": "50754",
          "couple_id": "r-15168",
          "parent_id": "43735",
          "type": "half",
        }
      ],
      "child_id_list": [
        "43740",
        "43741"
      ]
    }
  ]

  let test_donors_list_6 = [
    {
      "donor_info": [
        {
          "id": "236",
          "donor_id": "50766",
          "couple_id": "r-15168",
          "parent_id": "43739",
          "type": "full"
        }
      ],
      "child_id_list": [
        "43740",
        "43741"
      ]
    }
  ]

  return donors_list;
}

export function buildSmartDrawPayload(data){
  const {
    proband,
    profile,
    displayGeneticTesting,
    showNotes,
    showName,
    showAllPeople,
    displayProgenyArchiveData,
    progenyArchivedData,
    progenyArchivedPreferences,
    canvas,
    donors,
    current_nodes,
    sibling_orders,
    partners_option,
    is_emr_session,
    drawingVersion,
    twins_added,
  } = data;

  let people = Object.values(profile);
  let members = [];
  let relationships = [];

  let displayGeneticTestingBool = displayGeneticTesting == 'true';
  let showNotesBool = showNotes == 'true';
  let showNameBool = showName == undefined || showName == null || showName == 'identified'

  let all_non_blood_related_partners = [];

  for(let person of people){
    let partners = person.partners
    let non_blood_related_partners = partners.filter(partner => !partner.is_blood_related_to_proband);
    all_non_blood_related_partners = all_non_blood_related_partners.concat(non_blood_related_partners)
  }

  all_non_blood_related_partners = [...new Map(all_non_blood_related_partners.map(item => [item['id'], item])).values()];

  let all_non_blood_related_partners_modified = [];

  for(let non_blood_related_partner of all_non_blood_related_partners){
    let personObj = people.find(person => person.id === non_blood_related_partner.id)
    if(personObj) all_non_blood_related_partners_modified.push(personObj)
  }

  for(let person of people){

    let diseases_subtext = '';
    let genetic_testing_subtext = '';
    let notes_subtext = '';

    let subText = '';

    //separate the disease subtexts
    if(person.diseases.length > 0){
      let diseases = person.diseases;
      diseases_subtext += getDiseaseSubtext(diseases)

    }

    if((person.genetic_testing.length > 0 || person.gene_panels.length > 0) && displayGeneticTestingBool){
      genetic_testing_subtext += getGeneticTestingString(person.genetic_testing, person.gene_panels)
      subText += genetic_testing_subtext;
    }

    if(person.note && showNotesBool){
      notes_subtext += person.note
      // adjustment here for subtext width
      subText += '\n' + wordWrap(notes_subtext, 19)
    }

    let name = null;
    let age = (person.age_string && person.age_string !== '' && person.age_string !== "NaN") ? String(person.age_string) : (person.age && person.age !== 'NaN') ? String(person.age) : ''
    if (age && age !== '' && person.age_estimated) {
      age += ' est.'
    }
    if(showNameBool){
      if(!is_emr_session){
        if(person.first_name && person.age){
          name = `${person.first_name}, ${person.is_dead ? 'd. ' + age : age}`
        } else if(person.first_name){
          name = person.first_name
        } else if(person.age){
          name = person.is_dead ? 'd. ' + age : age
        } else if (person.is_dead && !person.first_name && !person.age){
          name = ''
        }
      }
      else{
        let ageString = person.age_string ? person.age_string : person.age ? person.age : ''
        if(ageString){
          name = person.is_dead ? 'd. ' + ageString : ageString
        }
      }
    }
    else{
      let ageString = person.age_string ? person.age_string : person.age ? person.age : ''
      if(ageString){
        name = person.is_dead ? 'd. ' + ageString : ageString
      }
    }
    if(person.cause_of_death){
      name += (name !== '') ? '\n' : '';
      name += wordWrap(`d. ${person.cause_of_death}`, 19);
    }
    name = name ? `${name} ` : ``

    let displayProgenyArchiveDataBool = displayProgenyArchiveData == 'true';
    if (progenyArchivedData !== null && progenyArchivedData !== undefined){
      if(isObject(progenyArchivedData)){
        if (displayProgenyArchiveDataBool && progenyArchivedPreferences.length > 0 && "family" in progenyArchivedData) {

          // get the members Progeny Archive Data
          let progeny_member_data = null;
          for (const [key, value] of Object.entries(progenyArchivedData["family"])) {
            if (value["member_id"]+"" === person.id+"") {
              progeny_member_data = value;
              break;
            }
          }

          // grab only the preferences that refer to subtext
          const subtext_preferences = [];
          const groupedTables = {};
          let tablesAdded = [];

          for (let x=0; x<progenyArchivedPreferences.length; x++) {
            if (progenyArchivedPreferences[x].preference_type === "subtext") {
              const tableId = progenyArchivedPreferences[x].progeny_table_id
              const fieldID = progenyArchivedPreferences[x].progeny_field_id
              if(tableId != 'st_data'){
                if(groupedTables[tableId] === undefined){
                  groupedTables[tableId]= []

                }
                groupedTables[tableId].push(fieldID)
              }
              subtext_preferences.push(progenyArchivedPreferences[x]);
            }
          }
          // sort the preferences by 'Order'
          const sorted_preferences = subtext_preferences.sort(function(a, b){return a.order - b.order});
          const data_dictionary = progenyArchivedData["data_dictionary"];

          if (progeny_member_data) {
            for (let i=0; i<sorted_preferences.length; i++) {
              const tableID = sorted_preferences[i].progeny_table_id
              const fieldName =  sorted_preferences[i].progeny_field_id
              // if table id is 'st_data' its a flat field otherwise table field
              if (tableID === "st_data") {
                let field_name = fieldName;
                if (data_dictionary[field_name]) field_name = data_dictionary[field_name];
                const field_value = progeny_member_data["st_data"][fieldName];

                if (field_value){
                  subText += `\n${field_name}: ${field_value}`;
                }
              }else if(tableID in groupedTables){
                if(tablesAdded.includes(tableID)) continue
                let table_name = data_dictionary[tableID]
                const tableData = progeny_member_data[tableID]
                  if(tableData){
                      subText += `\n${table_name}: `
                      for (const [row_key, row_value] of Object.entries(tableData)) {
                        groupedTables[tableID].forEach(value=>{
                          if(row_value[value]) subText += `${row_value[value]} `;
                        })
                        subText += `\n`
                      }
                    }
                  tablesAdded.push(tableID)
                }else {
                    let field_name = sorted_preferences[i].progeny_field_id;
                    if (data_dictionary[field_name]) field_name = data_dictionary[field_name];

                    const table_data = progeny_member_data[tableID];
                    const row_data = [];
                    if (table_data) {
                      // collect all the row data for the specified field
                      for (const [row_key, row_value] of Object.entries(table_data)) {
                        const row_val = row_value[sorted_preferences[i].progeny_field_id];
                        if (row_val) row_data.push(row_val);
                      }

                      // create a subtext line for each rows field data
                      for (let g=0; g<row_data.length; g++) {
                        if(row_data[g]){
                          subText += `\n${field_name}: ${row_data[g]}`;
                        }
                      }
                    }
                  }

            }
          }
        }
      }
    }

    let has_existing_proband_record = data.cloned_members.find(patient => patient.uuid_code == person.uuid_code)
    let check_if_manually_linked = data.member_links.find(link => link.member_one_uuid == person.uuid_code || link.member_two_uuid == person.uuid_code)

    // add a spacer for view record link under a person
    if(has_existing_proband_record || check_if_manually_linked){
      subText += '\n';
    }

    let member = {
      id: person.id + '',
      name: name,
      gender: String(person.gender).toLowerCase() === 'm' ? 'male' : (String(person.gender).toLowerCase() === 'f') ? 'female' : 'unknown',
      motherid: person.mother_id ? person.mother_id + '' : null,
      fatherid: person.father_id ? person.father_id + '' : null,
      parentid: null, //this one depends on the relationships object, insert value on this after making relationships object
      multi: null, //this one depends on the parentid, so insert value after getting parentid
      twinID: person.twin_id ? `t-${person.twin_id}` : null,
      subText: subText,
      diseaseList: diseases_subtext,
      hidden: true
    };

    // hide fake infertile/no children nodes with no record on the database

    let non_blood_related_partner = all_non_blood_related_partners_modified.filter(partner => partner.id + '' === member.id + '')
    let member_has_children = people.filter(pers => pers.mother_id + '' === member.id + '' || pers.father_id + '' === member.id + '').length > 0
    let member_partners = person.partners
    let member_partner_has_children_with_other_partners = false;


    for(let partner of member_partners){
      let children_with_other_partners = people.filter(pers => pers.mother_id + '' !== member.id + '' && pers.father_id + '' !== member.id + '' && (pers.father_id + '' === partner.id + '' || pers.mother_id + '' === partner.id + ''))
      if(children_with_other_partners.length > 0){
        member_partner_has_children_with_other_partners = true;
      }
    }

    let condition_for_affected_members = false;

    //need a new parameter here to check if we allow hiding or not for saved positions purposes
    if(showAllPeople !== null && showAllPeople !== undefined && showAllPeople === true){
      delete member.hidden
    }
    else{
      if(partners_option !== 'hide_partners' && partners_option !== 'show_affected_partners'){
        delete member.hidden
      }
      else{
        if(non_blood_related_partner.length === 0){
          delete member.hidden
        }
        else{
          condition_for_affected_members = (partners_option === 'show_affected_partners' && (non_blood_related_partner[0].diseases.length > 0 || non_blood_related_partner[0].genetic_testing.length > 0 || non_blood_related_partner[0].note)) || (member_has_children && member_partner_has_children_with_other_partners)
          if(condition_for_affected_members){
            delete member.hidden
          }
        }

        if (non_blood_related_partner.length > 0){
          let non_blood_related_partner_has_parents = non_blood_related_partner[0].father_id !== undefined && non_blood_related_partner[0].father_id !== null && non_blood_related_partner[0].mother_id !== undefined && non_blood_related_partner[0].mother_id !== null
          let non_blood_related_partner_siblings = people.filter((pers) => {
            if('mother_id' in pers && 'father_id' in pers){
              if (pers.father_id !== undefined && pers.mother_id !== undefined && pers.father_id !== null && pers.mother_id !== null){
                if ((pers.mother_id == non_blood_related_partner[0].mother_id && pers.father_id == non_blood_related_partner[0].father_id) || (pers.mother_id == non_blood_related_partner[0].father_id && pers.father_id == non_blood_related_partner[0].mother_id) && pers.id != non_blood_related_partner[0].id){
                  return true;
                }
                else{
                  return false;
                }
              }
              else{
                return false;
              }
            }
            else{
              return false;
            }

          })
          if(non_blood_related_partner[0].partners.length > 1 ||
            non_blood_related_partner_has_parents ||
            non_blood_related_partner_siblings.length > 0){
            delete member.hidden
          }

          if (non_blood_related_partner_has_parents) {
            let non_blood_related_father = all_non_blood_related_partners_modified.find(nbp => nbp.id == non_blood_related_partner[0].father_id)
            let non_blood_related_mother = all_non_blood_related_partners_modified.find(nbp => nbp.id == non_blood_related_partner[0].mother_id)

            // if both parents are non-blood-related partners, then it means we hide the child
            if (non_blood_related_father && non_blood_related_mother && !condition_for_affected_members){
              member.hidden = true;
            }

          }
        }

        // if member is a male, and has a partner, and they have a child that has an egg donor and a surrogate (or surrogate egg donor), then don't hide
        if (member.gender === 'male'){
          let relationships = people.find(p => p.id + '' == member.id + '').relationship_ids;
          for(let r of relationships){
            let children = people.filter(p => p.mother_id == r.mother_id && p.father_id == r.father_id);
            for(let c of children){
              let donors_of_c = donors.filter(d => d.child_id + '' == c.id + '');
              let has_egg_donor = donors_of_c.find(d => d.donor_type == 'egg_donor');
              let has_surrogate = donors_of_c.find(d => d.donor_type == 'surrogate');
              let has_surrogate_egg_donor = donors_of_c.find(d => d.donor_type == 'surrogate_egg_donor');

              if (has_egg_donor && has_surrogate){
                delete member.hidden;
                break;
              }

              if (has_surrogate_egg_donor){
                delete member.hidden;
                break;
              }

            }
          }
        }

        // if member is a female, and has a partner, and they have a child that has a sperm donor, then don't hide
        if (member.gender === 'female'){
          let relationships = people.find(p => p.id + '' == member.id + '').relationship_ids;
          for(let r of relationships){
            let children = people.filter(p => p.mother_id == r.mother_id && p.father_id == r.father_id);
            for(let c of children){
              let donors_of_c = donors.filter(d => d.child_id + '' == c.id + '');
              let has_sperm_donor = donors_of_c.find(d => d.donor_type == 'sperm_donor');

              if (has_sperm_donor){
                delete member.hidden;
                break;
              }

            }
          }
        }

        // if a member is a donor, then don't hide
        if (donors.find(d => d.donor_id + '' == member.id + '')){
          delete member.hidden;
        }

        // show the newly added person even if they are unsupported
        if('hidden' in person && person.hidden === false){
          delete member.hidden
        }

      }
    }

    //hide fake partner nodes
    if(!canvas){
      if(person.is_fake_node && (person.infertile || person.no_children)){
        member.hidden = true;
      }
      if(String(person.id).startsWith('fake_consanguineous_partner-')){
        delete member.hidden
      }
    }

    members.push(member)

  }


  //relationships
  //couples: filter out all profiles that have relationship ids, relationship "id" field = person.relationship_ids
  let peopleWithRelationships = people.filter(person => person.relationship_ids.length > 0)

  let hidden_members = members.filter(member => 'hidden' in member && member.hidden);

  for(let person of peopleWithRelationships){
    let personRelationships = person.relationship_ids
    for(let relationship of personRelationships){

      /*
      * This hack is to account for couples where the genders are unknown
      * so it could be possible that we need to check motherid and fatherid for the person
      */
      let children = people.filter(person => {
        return ((person.mother_id === relationship.mother_id && person.father_id === relationship.father_id) ||
        (person.mother_id === relationship.father_id && person.father_id === relationship.mother_id))
      });

      let children_ids = [];
      let childrenrs = [];
      let relationship_ids = [];

      for(let child of children){
        relationship_ids = relationship_ids.concat(child.relationship_ids)
      }

      let couples_between_children_exists = relationship_ids.some((item, idx) => {
        return relationship_ids.map(rel => rel.id).indexOf(item.id) != idx
      });
      for(let child of children){
        children_ids.push(child.id + '');
        if(child.relationship_ids.length > 0){
          for(let childrelationship of child.relationship_ids){
            // if(childrelationship.rkey !== )
            childrenrs.push(childrelationship.rkey)
          }
          if(couples_between_children_exists){
            childrenrs.push(child.id + '')
          }
        }
        else{
          childrenrs.push(child.id + '')
        }
      }

      childrenrs = [...new Set(childrenrs.map(item => item))];
      children_ids = [...new Set(children_ids.map(item => item))];

      //if relationship. is manually connected, then replace higher level partner with a fake node
      let mother = people.find(person => person.id == relationship.mother_id);
      let father = people.find(person => person.id == relationship.father_id);

      let mother_id = relationship.mother_id;
      let father_id = relationship.father_id;

      // if(mother.level != father.level){
      //   if(relationship.is_manually_connected){

      //     let temp_partner_id = null;
      //     let fake_member = {};

      //     if(mother.level < father.level){
      //       temp_partner_id = `fake-consanguineous-partner-${father.id}`;
      //       mother_id = temp_partner_id;

      //       fake_member = {
      //         id: temp_partner_id,
      //         name: "",
      //         gender: "female",
      //         motherid: null,
      //         fatherid: null,
      //         parentid: null,
      //         multi: null,
      //         twinID: null,
      //         subText: "",
      //         diseaseList: "",
      //       }

      //       members.push(fake_member);

      //       for(let c of childrenrs){
      //         for(let m of members){
      //           if(m.id + '' == c + ''){
      //             m.motherid = temp_partner_id;
      //           }
      //         }
      //       }

      //     }
      //     else if(father.level < mother.level){
      //       temp_partner_id = `fake-consanguineous-partner-${mother.id}`;
      //       father_id = temp_partner_id;

      //       fake_member = {
      //         id: temp_partner_id,
      //         name: "",
      //         gender: "male",
      //         motherid: null,
      //         fatherid: null,
      //         parentid: null,
      //         multi: null,
      //         twinID: null,
      //         subText: "",
      //         diseaseList: "",
      //       };

      //       members.push(fake_member);

      //       for(let c of childrenrs){
      //         for(let m of members){
      //           if(m.id + '' == c + ''){
      //             m.fatherid = temp_partner_id;
      //           }
      //         }
      //       }

      //     }
      //   }
      // }

      let ordered_child_nodes = [];
      // let children_ids = children.map(child => child.id + '');

      // if(current_nodes){
      //   let current_children_nodes = current_nodes.filter(node => children_ids.includes(node.id));
      //   ordered_child_nodes = current_children_nodes.sort((a, b) => parseFloat(a.position.x) - parseFloat(b.position.x)).map(node => {return node.id + ''});
      // }

      if(sibling_orders){
        let saved_child_order = sibling_orders.find(so => so.relationship_rkey == relationship.rkey);
        if(saved_child_order){
          ordered_child_nodes = saved_child_order.child_order.filter(child => children_ids.includes(child));
          // this is for newly added children that we still don't know the x position
          for (let child of children_ids){
            if (!ordered_child_nodes.includes(child)){

              if (current_nodes == null || current_nodes == undefined){
                break;
              }

              if (twins_added) {
                let selected_node_id = (JSON.parse(sessionStorage.getItem('famgenix_last_selected_node'))) + '';
                ordered_child_nodes.splice(ordered_child_nodes.indexOf(selected_node_id) + 1, 0, child)
              }
              else{
                let current_children_nodes = current_nodes.filter(node => children_ids.includes(node.id));
                if (current_children_nodes.length > 0) {
                  let children_left_to_right = current_children_nodes.sort((a, b) => parseFloat(a.position.x) - parseFloat(b.position.x));

                  let most_right_child = children_left_to_right[children_left_to_right.length - 1];

                  let add_to_right = true;

                  let most_right_child_x = most_right_child.position.x;
                  let most_right_child_relationships = people.find(person => person.id + '' == most_right_child.id + '').relationship_ids;

                  for(let r of most_right_child_relationships){
                    // if(child + '' == r.mother_id + ''){
                    if(most_right_child.id + '' == r.mother_id + ''){
                      let father_r = current_nodes.find(node => node.id + '' == r.father_id + '');
                      if (!father_r){
                        continue;
                      }
                      let x_father = father_r.position.x;
                      if (x_father > most_right_child_x){
                        add_to_right = false;
                        break;
                      }
                    }
                    else{
                      let mother_r = current_nodes.find(node => node.id + '' == r.mother_id + '');
                      if (!mother_r){
                        continue;
                      }
                      let x_mother = mother_r.position.x;
                      if (x_mother > most_right_child_x){
                        add_to_right = false;
                        break;
                      }
                    }
                  }

                  if (add_to_right){
                    ordered_child_nodes.push(child)
                  }
                  else{
                    ordered_child_nodes.unshift(child)
                  }
                }
              }

            }
          }
        }
      }

      let rs = {
        id: relationship.rkey,
        type: 'couple',
        mother: relationship.mother_id + '',
        father: relationship.father_id + '',
        children: childrenrs, //included couple ids
        child_order: ordered_child_nodes
      };
      relationships.push(rs);
    }
  }
  relationships = [...new Map(relationships.map(item => [item['id'], item])).values()];

  // console.log(JSON.parse(JSON.stringify(relationships)))

  // for(let relationship of relationships){
  //   let children = relationship.children
  //   for(let child of children){
  //     if(String(child).startsWith('r-')){
  //       let relationship_profile = personRelationships.find(rs => rs.rkey == child);
  //       if(relationship_profile.is_manually_connected){
  //         let mother = people.find(person => person.id == relationship_profile.mother_id);
  //         let father = people.find(person => person.id == relationship_profile.father_id);

  //         if(mother.level != father.level){
  //           if(mother.level < father.level){
  //             relationship.children = relationship.children.filter(c => c != child)
  //             relationship.children.push(mother.id)
  //           }
  //           else if(father.level < mother.level){
  //             relationship.children = relationship.children.filter(c => c != child)
  //             relationship.children.push(father.id)
  //           }
  //         }
  //       }
  //     }
  //     // else{
  //     //   let relationship_profile = personRelationships.find(rs => (rs.mother_id == child || rs.father_id == child) && rs.is_manually_connected);
  //     //   console.log(child)
  //     //   console.log(relationship_profile)
  //     //   if(relationship_profile){
  //     //     let mother = people.find(person => person.id == relationship_profile.mother_id);
  //     //     let father = people.find(person => person.id == relationship_profile.father_id);

  //     //     console.log(mother.id)
  //     //     console.log(father.id)

  //     //     if(mother.level != father.level){
  //     //       if(mother.level < father.level){
  //     //         relationship.children = relationship.children.filter(c => c != mother.id)
  //     //         relationship.children.push(relationship_profile.rkey)
  //     //       }
  //     //       else if(father.level < mother.level){
  //     //         relationship.children = relationship.children.filter(c => c != father.id)
  //     //         relationship.children.push(relationship_profile.rkey)
  //     //       }
  //     //     }
  //     //   }
  //     // }
  //   }
  // }

  let copy_relationships = relationships

  //check for supercouples
  for(let relationship of copy_relationships){
    let sameMotherRelationships = relationships.filter(rs => rs.mother === relationship.mother && relationship.type === 'couple')
    let sameFatherRelationships = relationships.filter(rs => rs.father === relationship.father && relationship.type === 'couple')

    if(sameMotherRelationships.length > 1){
      let id = createUUID(); //might need to have proper unique identifier here because of saving positions later on
      let couples = [];
      for(let sameMother of sameMotherRelationships){
        couples.push(sameMother.id)
      }
      let rs = {
        id: id,
        type: "supercouple",
        centerid: relationship.mother,
        couples: couples
      }
      relationships.push(rs)
    }

    if(sameFatherRelationships.length > 1){
      let id = createUUID();
      let couples = [];
      for(let sameFather of sameFatherRelationships){
        couples.push(sameFather.id)
      }
      let rs = {
        id: id,
        type: "supercouple",
        centerid: relationship.father,
        couples: couples
      }
      relationships.push(rs)
    }

  }

  let couple_relationships = relationships.filter(rs => rs.type === "couple")
  let super_couple_relationships = relationships.filter(rs => rs.type === "supercouple")

  let super_couple_relationships_unique = [...new Map(super_couple_relationships.map(item => [item['centerid'], item])).values()];

  relationships = couple_relationships.concat(super_couple_relationships_unique)

  for(let member of members){
    if(member.motherid && member.fatherid){
      member.parentid = getParentId(member.fatherid, member.motherid, relationships.filter(rs => rs.type === "couple"))
      member.multi = fetchMulti(member.parentid, relationships.filter(rs => rs.type === "supercouple"))
    }
    else{
      member.multi = [];
    }
  }

  for(let relationship of couple_relationships){
    let relationshipsAsChildren = relationship.children.filter(child => String(child).startsWith('r-'))

    super_couple_relationships_unique.map(rs => {
      // let intersection = rs.couples.filter(element => relationshipsAsChildren.includes(element))
      let intersection = rs.couples.every(function(item) {
        return relationshipsAsChildren.indexOf(item) !== -1;
      });
      if(intersection){
        if(relationshipsAsChildren.length > 1){
          relationship.children.push(rs.id)
        }
      }
    })

    const unique_supercouples = [...new Set(super_couple_relationships_unique.map(item => item.id))];
    let superCouplesAsChildren = relationship.children.filter(child => unique_supercouples.includes(child))
    // let superCouplesAsChildren = relationship.children.filter(child => !String(child).startsWith('r-') && typeof child === 'string')
    for(let couple of superCouplesAsChildren){
      let supercouple_item = super_couple_relationships_unique.find(rs => rs.id === couple)

      for(let cp of supercouple_item.couples){
        const index = relationship.children.indexOf(cp);
        if (index > -1) {
          relationship.children.splice(index, 1);
        }
      }
    }

    // sorting of whether put relationshipsAsChildren first before plain person ids or vice versa
    // relationship.children = relationship.children.sort((a, b) => {
    //   let relationship_ids = [];

    //   let children = people.filter(person => {
    //     return ((person.mother_id == relationship.mother && person.father_id == relationship.father) ||
    //     (person.mother_id == relationship.father && person.father_id == relationship.mother))
    //   });

    //   for(let child of children){
    //     relationship_ids = relationship_ids.concat(child.relationship_ids)
    //   }

    //   let couples_between_children_exists = relationship_ids.some((item, idx) => {
    //     return relationship_ids.map(rel => rel.id).indexOf(item.id) != idx
    //   });

    //   let male_children_with_relationships = children.filter(child => child.relationship_ids.length > 0 && child.gender.toLowerCase() == 'm');
    //   let female_children_with_relationships = children.filter(child => child.relationship_ids.length > 0 && child.gender.toLowerCase() == 'f');

    //   let typea = typeof a == 'string';
    //   let typeb = typeof b == 'string';

    //   if(couples_between_children_exists){
    //     if(male_children_with_relationships.length > female_children_with_relationships.length){
    //       return typeb - typea;
    //     }
    //     else if(female_children_with_relationships.length > male_children_with_relationships.length){
    //       return typea - typeb;
    //     }
    //     return typeb - typea;
    //   }
    //   else{
    //     return typea - typeb;
    //   }
    // });

    // always put first latest relationship
    relationship.children = relationship.children.sort((a,b) => {
      if(typeof a == 'string' && typeof b == 'string'){
        return b.localeCompare(a)
      }
      return 0;
    })
  }

  relationships = couple_relationships.concat(super_couple_relationships_unique)

  let probandCoupleID = null;
  let probandID = proband.id + '';

  let super_couple_relationship_with_proband = super_couple_relationships_unique.find(rs => rs.centerid === probandID)
  if(super_couple_relationship_with_proband){
    probandCoupleID = super_couple_relationship_with_proband.id + ''
  }
  else{
    let couple_relationships_with_proband = couple_relationships.find(rs => rs.mother === probandID || rs.father === probandID)
    if(couple_relationships_with_proband){
      probandCoupleID = couple_relationships_with_proband.id + ''
    }
    else{
      probandCoupleID = null
    }
  }

  // let relationships_copy_2 = cloneDeep(relationships)
  // for (let rl of relationships_copy_2){
  //   if (rl.id == 'r-4768'){
  //     rl.children = rl.children.filter(ch => ch != 10928)
  //     rl.children.push("r-fake_relationship-10928")
  //   }
  // }

  // relationships = cloneDeep(relationships_copy_2)
  // let donors_list = getDonorsList(donors, people, relationships, "v1");


  // for(let donor of all_donors){



  //   let child = people.find(person => person.id == donor.child_id);
  //   let father = people.find(person => person.id == child.father_id);
  //   let mother = people.find(person => person.id == child.mother_id);

  //   let donor_record = {
  //     donor_info: [
  //       {
  //         donor_id: String(donor.donor_id),
  //         parent_id: String(mother.id),
  //         type: "full"
  //       }
  //     ],
  //     child_id_list: [
  //       "4",
  //       "5"
  //     ]
  //   }

  //   let donor_record_already_pushed = donors.find(donor =>)

  //   donors.push(donor_record)
  // }

  let payload = {
    character_width: 5,
    character_height: drawingVersion == 'v2' ? 20 : 13,
    yscale: drawingVersion == 'v2' ? 95 : 65,
    xscale: drawingVersion == 'v2' ? 75 : 55,
    probandID,
    probandCoupleID,
  }

  if(drawingVersion == 'v2'){
    let donors_list = getDonorsList(donors, people, relationships, hidden_members, "v2");
    payload.donors = donors_list;
    payload.donor = true;
  }

  for(let member of members){
    // null if father or mother is hidden
    let father_is_hidden = hidden_members.find(m => m.id + '' == member.fatherid + '');
    let mother_is_hidden = hidden_members.find(m => m.id + '' == member.motherid + '');

    if(father_is_hidden){
      member.fatherid = null;
    }

    if(mother_is_hidden){
      member.motherid = null;
    }

    // if member is hidden, filter it out from the members array
    if('hidden' in member && member.hidden){
      members = members.filter(m => m.id + '' != member.id + '')
    }
  }

  for(let relationship of relationships){
    // if father or mother is hidden, set them to null in relationships
    let father_is_hidden = hidden_members.find(member => member.id + '' == relationship.father + '');
    let mother_is_hidden = hidden_members.find(member => member.id + '' == relationship.mother + '');

    if(relationship.type == 'couple'){
      if(father_is_hidden){
        relationship.father = null;
      }
      if(mother_is_hidden){
        relationship.mother = null;
      }
    }
  }

  members = members.sort((a, b) => a.id - b.id);
  payload.members = members;
  payload.relationships = relationships;

  return payload;
}

export function buildSmartDrawPayloadV2(data){
  const {
    proband,
    profile,
    displayGeneticTesting,
    showNotes,
    showName,
    showAllPeople,
    displayProgenyArchiveData,
    progenyArchivedData,
    progenyArchivedPreferences,
    canvas,
    donors,
    partners_option,
    is_emr_session
  } = data;

  let people = Object.values(profile);
  let personList = [];
  let coupleList = [];
  let xscale = 15;
  let yscale = 45;
  sessionStorage.setItem('subtextCollection', JSON.stringify([]));

  for(let person of people){

    let displayGeneticTestingBool = displayGeneticTesting == 'true';
    let showNotesBool = showNotes == 'true';
    let showNameBool = showName == undefined || showName == null || showName == 'identified'

    let name = null;
    let age = (person.age_string && person.age_string !== '' && person.age_string !== "NaN") ? String(person.age_string) : (person.age && person.age !== 'NaN') ? String(person.age) : ''
    if(showNameBool){
      if(!is_emr_session){
        if(person.first_name && person.age){
          name = `${person.first_name}, ${person.is_dead ? 'd. ' + age : age}`
        } else if(person.first_name){
          name = person.first_name
        } else if(person.age){
          name = person.is_dead ? 'd. ' + age : age
        }
      }
      else{
        let ageString = person.age_string ? person.age_string : person.age ? person.age : ''
        if(ageString){
          name = person.is_dead ? 'd. ' + ageString : ageString
        }
      }
    }
    else{
      let ageString = person.age_string ? person.age_string : person.age ? person.age : ''
      if(ageString){
        name = person.is_dead ? 'd. ' + ageString : ageString
      }
    }
    name = name ? `${name} ` : ``

    let personObject = {
      name,
      id: String(person.id),
    }

    let cause_of_death_subtext = '';
    let diseases_subtext = '';
    let genetic_testing_subtext = '';
    let notes_subtext = '';

    let subText = '';

    if(name){
      subText += name;
    }

    if(person.diseases.length > 0){
      let diseases = person.diseases;
      diseases_subtext += getDiseaseSubtext(diseases);
      subText += name ? '\n' + diseases_subtext : diseases_subtext;
    }

    if(person.cause_of_death){
      cause_of_death_subtext += person.cause_of_death
      subText += name || person.diseases.length > 0 ? '\n' + `d. ${cause_of_death_subtext} ` : `d. ${cause_of_death_subtext} `
    }
    if((person.genetic_testing || person.gene_panels) && displayGeneticTestingBool){
      genetic_testing_subtext += getGeneticTestingString(person.genetic_testing, person.gene_panels)
      subText += name || person.diseases.length > 0 || person.cause_of_death ? '\n' + genetic_testing_subtext : genetic_testing_subtext
    }

    if(person.note && showNotesBool){
      notes_subtext += person.note
      // adjustment here for subtext width
      subText += name || person.diseases.length > 0 || person.cause_of_death || person.genetic_testing.length > 0 ? '\n' + wordWrap(notes_subtext, 19) : wordWrap(notes_subtext, 19)
    }

    let displayProgenyArchiveDataBool = displayProgenyArchiveData == 'true';
    if (progenyArchivedData !== null && progenyArchivedData !== undefined){
      if(isObject(progenyArchivedData)){
        if (displayProgenyArchiveDataBool && progenyArchivedPreferences.length > 0 && "family" in progenyArchivedData) {

          // get the members Progeny Archive Data
          let progeny_member_data = null;
          for (const [key, value] of Object.entries(progenyArchivedData["family"])) {
            if (value["member_id"]+"" === person.id+"") {
              progeny_member_data = value;
              break;
            }
          }

          // grab only the preferences that refer to subtext
          const subtext_preferences = [];
          const groupedTables = {};
          let tablesAdded = [];

          for (let x=0; x<progenyArchivedPreferences.length; x++) {
            if (progenyArchivedPreferences[x].preference_type === "subtext") {
              const tableId = progenyArchivedPreferences[x].progeny_table_id
              const fieldID = progenyArchivedPreferences[x].progeny_field_id
              if(tableId != 'st_data'){
                if(groupedTables[tableId] === undefined){
                  groupedTables[tableId]= []

                }
                groupedTables[tableId].push(fieldID)
              }
              subtext_preferences.push(progenyArchivedPreferences[x]);
            }
          }
          // sort the preferences by 'Order'
          const sorted_preferences = subtext_preferences.sort(function(a, b){return a.order - b.order});
          const data_dictionary = progenyArchivedData["data_dictionary"];

          if (progeny_member_data) {
            for (let i=0; i<sorted_preferences.length; i++) {
              const tableID = sorted_preferences[i].progeny_table_id
              const fieldName =  sorted_preferences[i].progeny_field_id
              // if table id is 'st_data' its a flat field otherwise table field
              if (tableID === "st_data") {
                let field_name = fieldName;
                if (data_dictionary[field_name]) field_name = data_dictionary[field_name];
                const field_value = progeny_member_data["st_data"][fieldName];

                if (field_value){
                  subText += `\n${field_name}: ${field_value}`;
                }
              }else if(tableID in groupedTables){
                if(tablesAdded.includes(tableID)) continue
                let table_name = data_dictionary[tableID]
                const tableData = progeny_member_data[tableID]
                  if(tableData){
                      subText += `\n${table_name}: `
                      for (const [row_key, row_value] of Object.entries(tableData)) {
                        groupedTables[tableID].forEach(value=>{
                          if(row_value[value]) subText += `${row_value[value]} `;
                        })
                        subText += `\n`
                      }
                    }
                  tablesAdded.push(tableID)
                }else {
                    let field_name = sorted_preferences[i].progeny_field_id;
                    if (data_dictionary[field_name]) field_name = data_dictionary[field_name];

                    const table_data = progeny_member_data[tableID];
                    const row_data = [];
                    if (table_data) {
                      // collect all the row data for the specified field
                      for (const [row_key, row_value] of Object.entries(table_data)) {
                        const row_val = row_value[sorted_preferences[i].progeny_field_id];
                        if (row_val) row_data.push(row_val);
                      }

                      // create a subtext line for each rows field data
                      for (let g=0; g<row_data.length; g++) {
                        if(row_data[g]){
                          subText += `\n${field_name}: ${row_data[g]}`;
                        }
                      }
                    }
                  }

            }
          }
        }
      }
    }

    let has_existing_proband_record = data.cloned_members.find(patient => patient.uuid_code == person.uuid_code)
    let check_if_manually_linked = data.member_links.find(link => link.member_one_uuid == person.uuid_code || link.member_two_uuid == person.uuid_code)

    // add a spacer for view record link under a person
    if(has_existing_proband_record || check_if_manually_linked){
      subText += '\n';
    }

    if(subText){
      let invisibleContainer = document.createElement('div');
      invisibleContainer.setAttribute('id', `subtext-invisible-${person.id}`);
      invisibleContainer.style.visibility = 'hidden';

      let subtextCollection = JSON.parse(sessionStorage.getItem('subtextCollection'));
      subtextCollection.push({id: person.id, subtext: subText});
      sessionStorage.setItem('subtextCollection', JSON.stringify(subtextCollection));

      // pre-render subtext on background
      let subtextContainer = document.createElement('div');
      subtextContainer.setAttribute('id', `subtext-${person.id}`)
      subtextContainer.style.background = 'linear-gradient(90deg, #fafafa 50%, #fafafa 50%)';
      subtextContainer.style.color = '#000';
      subtextContainer.style.border = 'none';
      subtextContainer.style.maxWidth = '115px';
      subtextContainer.style.padding = '1px';
      subtextContainer.style.fontSize = '13px';
      subtextContainer.style.fontFamily = 'Inter, sans-serif';
      subtextContainer.style.wordWrap = 'break-word';
      subtextContainer.style.marginLeft = '-35px'
      subtextContainer.style.textAlign = 'center'
      subtextContainer.style.lineHeight = '15px'
      subtextContainer.style.whiteSpace = 'pre-wrap'
      subtextContainer.style.display = 'table'
      subtextContainer.style.position = 'relative'
      subtextContainer.innerHTML = subText;

      invisibleContainer.appendChild(subtextContainer);
      document.body.appendChild(invisibleContainer);

      let height = $(subtextContainer).height(); //px
      let width = $(subtextContainer).width(); //px

      invisibleContainer.remove();

      // add a space
      personObject.space = [];

      let bottom_space_for_subtext = {
        x: 0, //(Math.floor(width/2)) * -1,
        y: 1,
        height: Math.floor(height/xscale) == 0 ? 1 : Math.floor(height/xscale),
        width: Math.floor(width/yscale) == 0 ? 1 : Math.floor(width/yscale)
      }

      personObject.space.push(bottom_space_for_subtext);
    }

    personList.push(personObject);
  }

  let all_relationships = [];
  for(let person of people){
    all_relationships = all_relationships.concat(person.relationship_ids);
  }

  all_relationships = [...new Map(all_relationships.map(item => [item['id'], item])).values()];

  for(let relationship of all_relationships){
    let childrenFilter = people.filter(person => (person.father_id == relationship.father_id && person.mother_id == relationship.mother_id) || (person.mother_id == relationship.father_id && person.father_id == relationship.mother_id));
    let children = [];
    for(let child of childrenFilter){
      children.push({id: child.id + ''});
    }

    let relationshipObject = {
      // how to know which should be left and which should be right?
      id: `r-${relationship.id}`,
      left: relationship.father_id + '',
      right: relationship.mother_id + '',
      // center: (if single parent)
      children
    }

    coupleList.push(relationshipObject);
  }

  let donorList = getDonorsList(donors, people, coupleList, "simple");

  let payload = {
    proband: proband.id + '',
    xscale,
    yscale,
    personList,
    coupleList,
    donorList,
  }

  return payload;
}

export function createLine(line,id,i,adopted_in, style)
{
	let x1 = line["x1"];
	let x2 = line["x2"];
	let y1 = line["y1"];
	let y2 = line["y2"];
	let key = id + "_line_" + i;

  let k = createUUID();

	let result = <line key={k} id={key} x1={x1} y1={y1} x2={x2} y2={y2} style={style} strokeDasharray={adopted_in}></line>;
	return result;
}
export function createHook(line, id, i, markerEnd, style)
{
	let x1 = line["x1"];
	let x2 = line["x2"];
	let y1 = line["y1"];
	let y2 = line["y2"];
	let key = id + "_hook_" + i;
	let lineHeight = 10;
	let d=`M ${x1} ${y1} C ${x1-lineHeight} ${y1}, ${x2-lineHeight} ${y2}, ${x2} ${y2}`;

  let k = createUUID();

	let pathLine = <path key={k} id={key} className="react-flow__edge-path" d={d} markerEnd={markerEnd} style={style} />;
	return pathLine;
}
