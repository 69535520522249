import React, { Component } from 'react';
import { connect } from "react-redux";
import InputAndOr from './input-andor';
import disease_api from "../../api/disease-api";
import * as static_list_actions from "../../store/static_list/actions";
import ControlSelectDiseases from '../control-select-diseases';
import Select from "react-select";
import ControlSelectGene from '../control-select-gene';
import ControlSelectSurveys from '../control-select-surveys';
import Dropdown from 'react-dropdown';
import lodash, { fromPairs } from 'lodash';
import DatePicker from 'react-date-picker';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

let result_any_gene = [
  {value: 'any', label: 'Any result'},
  {value: 'p', label: 'Pathogenic for any'},
  {value: 'lp', label: 'Likely pathogenic any'},
  {value: 'vus', label: 'VUS for any'},
  {value: 'ln', label: 'Likely benign for any'},
  {value: 'n_ln', label: 'Negative or Likely benign for all'},
  {value: 'n', label: 'Negative for all'},
]

let result_specific_gene = [
  {value: 'any', label: 'Any result'},
  {value: 'p', label: 'Pathogenic'},
  {value: 'lp', label: 'Likely Pathogenic'},
  {value: 'vus', label: 'VUS'},
  {value: 'ln', label: 'Likely benign'},
  {value: 'n', label: 'Negative'},
]

let result_referral = [
  {value: 'any', label: 'Any'},
  {value: 'none', label: 'None'},
  {value: 'breast/ovarian', label: 'Breast/Ovarian'},
  {value: 'colorectal', label: 'Colorectal'},
  {value: 'other', label: 'Other'},
]

let text_options = [
  {value: '=', label: '='},
  {value: 'contains', label: 'Contains'},
  {value: 'is_blank', label: 'Is blank'},
  {value: 'is_not_blank', label: 'Is not blank'},
]

let yes_no_options = [
  {value: 'yes', label: 'Yes'},
  {value: 'no', label: 'No'},
  {value: 'is_blank', label: 'Is blank'},
  {value: 'is_not_blank', label: 'Is not blank'},
]

let toggle_options = [
  {value: 'selected', label: 'Selected'},
  {value: 'unselected', label: 'Unselected'}
]

let survey_operator_options = [
  {value: '<=', label: '≤'},
  {value: '<', label: '<'},
  {value: '=', label: '='},
  {value: '>', label: '>'},
  {value: '>=', label: '≥'},
  {value: 'between', label: 'Between'},
];

let minDate = new Date('1800-01-01' + "T12:00:00")

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const filterOptions = (search, input) => {
  if (input && input.length > 0) {
    if(search.data.fromUMLS){
      return true
    }else if(search.label){
      return search.label.toLowerCase().includes(input.toLowerCase())
    }
  }
  return true;
};

class AdvancedFilterInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disease_options: [],
      genetic_testing_options: [],
      survey_options: [],
      other_options: [],
      umls_option: {},
      static_selected_disease: null,
      umlsLoading: false,
      field_options: [{
        value: 'disease',
        label: 'Disease'
      },
      {
        value: 'genetic_testing',
        label: 'Genetic Testing'
      },
      {
        value: 'referral_criteria',
        label: 'Referral Criteria'
      },
      {
        value: 'survey',
        label: 'Survey'
      }],
      advanced_selected_field: '',
      Variant: this.props.item.Variant ? this.props.item.Variant : null,
      survey_answer_2: this.props.item.answer_2 ? this.props.item.answer_2 : null,
      survey_answer_3: this.props.item.answer_3 ? this.props.item.answer_3 : null,
      date_from: this.props.item.date_from ? this.props.item.date_from : null,
      date_to: this.props.item.date_to ? this.props.item.date_to : null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    // this.loadDiseasesFromAPI = this.loadDiseasesFromAPI.bind(this);
    this.buildDiseaseDropdown = this.buildDiseaseDropdown.bind(this);
    this.buildGeneDropdown = this.buildGeneDropdown.bind(this);
    this.handleClickFieldOption = this.handleClickFieldOption.bind(this);
    this.handleReferralCriteriaChange = this.handleReferralCriteriaChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleDateFromBlur = this.handleDateFromBlur.bind(this);
    this.handleDateToBlur = this.handleDateToBlur.bind(this);
  }

  async componentDidMount() {
    await this.buildDiseaseDropdown();
    await this.buildGeneDropdown();
    await this.buildSurveyOptions();
  }

  async handleChange(e) {
    let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value})
    let targetID = e.currentTarget.id
    await this.props.onChange(item)
    if (targetID && typeof targetID.focus === 'function') {
      targetID.focus();
  }  }

  handleReferralCriteriaChange(e){
    let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value})
    this.props.onChange(item)
  }

  handleSelectChange(option, name) {
    let option_value = (option !== null) ? option.value : null;

    let item = Object.assign({}, this.props.item, {[name]: option_value});

    if (option.details){
      item = Object.assign({}, this.props.item, {[name]: option_value, 'questionType': option.details.master_question.type, 'surveyName': option.survey_name, 'surveyLabel': option.label });
    }

    const is_from_umls = this.isDiseaseFromUmls(option.value);
    if(is_from_umls){
      this.setState({umls_option: option })
    }

    this.props.onChange(item);
  }

  handleVariantChange(e){
    this.setState({Variant: e.target.value})
    clearTimeout(this.timeout);
    this.timeout = setTimeout(()=> {
      let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value});
      this.props.onChange(item)

    }, 1000);
  }

  async handleSurveyAnswer2Blur(){
    let item = Object.assign({}, this.props.item, {'answer_2': this.state.survey_answer_2});
    this.props.onChange(item)
  }

  async handleSurveyAnswer3Blur(){
    let item = Object.assign({}, this.props.item, {'answer_3': this.state.survey_answer_3});
    this.props.onChange(item)
  }

  async handleVariantBlur(){
    let item = Object.assign({}, this.props.item, {'Variant': this.state.Variant});
    this.props.onChange(item)
  }

  async handleDateFromBlur(){
    let item = Object.assign({}, this.props.item, {'date_from': this.state.date_from});
    this.props.onChange(item)
  }

  async handleDateToBlur(){
    let item = Object.assign({}, this.props.item, {'date_to': this.state.date_to});
    this.props.onChange(item)
  }

  handleStateChange(e){
    this.setState({[e.target.name]: e.target.value})
  }

  isDiseaseFromUmls(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }

  async onChangeDate(e, field) {
    let state = lodash.cloneDeep(this.state);
    if(e == null) {
      state[field] = null;
    } else {
      let fullYear = e.getFullYear()
      let month = e.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }

      let day = e.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      const string_date = fullYear + "-" + month + "-" + day;
      state[field] = string_date;
    }

    this.setState({ [field]: state[field] }, () => {
      let item = Object.assign({}, this.props.item, {[field]: state[field]});
      this.props.onChange(item)
    });
  }

  // async loadDiseasesFromAPI() {
  //   try {
  //     let diseases = this.props.static_list.diseases;
  //     if (diseases.length === 0) {
  //       let data = await disease_api.get_disease();
  //       let umls = await disease_api.get_umls_diseases(this.props.session.user.clinician_id)
  //       diseases = data.diseases.sort((a, b) => {
  //         if (a.disease_name < b.disease_name) return -1;
  //         if (a.disease_name > b.disease_name) return 1;
  //         return 0;
  //       });


  //       const uniqueUMLS = Array.from(new Set(umls.umls_diseases.map(a=>a.umls_name))).map(name=>{
  //         return umls.umls_diseases.find(a =>a.umls_name === name)
  //       })

  //       let umls_diseases = uniqueUMLS.sort((a, b) => {
  //         if (a.umls_name < b.umls_name) return -1;
  //         if (a.umls_name > b.umls_name) return 1;
  //         return 0;
  //       })
  //       await this.props.dispatch(static_list_actions.initialize_diseases(diseases));
  //       await this.props.dispatch(static_list_actions.intitialize_umls(umls_diseases))
  //     }
  //     this.buildDiseaseDropdown();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async buildDiseaseDropdown() {
    // Arrange by group
    let diseases = this.props.static_list.diseases;
    let umlsOptions = []
    let umls_options = this.props.static_list.umls_diseases

    let diseases_by_category = {};
    for (var disease of diseases) {
      if (disease.disease_type in diseases_by_category) {
        diseases_by_category[disease.disease_type].push(disease);
      } else {
        diseases_by_category[disease.disease_type] = [disease];
      }
    }

    //Parse and Format
    let options = []; //[{ value: '', label: ' ' }]
    let other_options = [];
    let first_options = [
      {value: 'any_disease', label: 'Any Disease'},
      {value: 'no_disease', label: 'No Disease'},
    ];

    options.push({
      label: '',
      options: first_options
    });

    other_options = other_options.concat(first_options);

    let all_diseases_by_category = [];

    for (var category_name in diseases_by_category) {
      let fmt_category_name = category_name.replace("-", " ").replace("_", " ").toUpperCase()
      if(fmt_category_name == 'CANCER POLYPS') fmt_category_name = "CANCER/POLYPS"

      let extra_options = [
        {value: 'any_'+category_name, label: 'Any ' + fmt_category_name + ' Condition'},
        {value: 'no_'+category_name, label: 'No ' + fmt_category_name + ' Condition'}
      ];

      other_options = other_options.concat(extra_options);

      const cat_options = diseases_by_category[category_name].map(item => ({
        value: item.disease_code ? item.disease_code : item.umls_id,
        label: item.disease_name
      }));

      //collect all unique diseases of all probands
      all_diseases_by_category = all_diseases_by_category.concat(diseases_by_category[category_name])

      extra_options = extra_options.concat(cat_options);
      options.push({
        label: fmt_category_name,
        options: extra_options
      });
    }

    //Sort the categories
    options = options.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    let intial_umls = [
      {value: 'any_UMLS', label: 'Any UMLS disease'},
      {value: 'no_UMLS', label: 'No UMLS disease'}
    ]

    other_options =  other_options.concat(intial_umls);

    intial_umls.forEach(umls =>{
      umlsOptions.push({
        value: umls.value ,
        label: umls.label
      })
    })

    for(var umls of umls_options){
      if( umls.umls_id !== null && umls.umls_id !== undefined) {
        umlsOptions.push({
          value: umls.umls_id,
          label: umls.umls_name
        });
      }
    }

    //filter out the other diseases on the umls to avoid duplicates
    umlsOptions = umlsOptions.filter((umls) => !all_diseases_by_category.find((disease) => umls.value === disease.id));

    if (umls_options.length > 0) {
      options.push({
        label: "UMLS",
        options: umlsOptions
      });
    }

    // Add empty option
    // options = [{ value: "", label: " " }].concat(options);

    for(let opti of options){
      if(opti.label == 'OTHER CONDITIONS'){
        opti.options = opti.options.filter(opt => opt.value != 'any_other_conditions' && opt.value != 'no_other_conditions')
      }
    }

    if (options.length > 1) {
      this.setState({ disease_options: options, other_options: other_options });
    }
  }

  async buildGeneDropdown(){
    let genes = this.props.static_list.genetic_testings
    let proband_genes = this.props.static_list.genetic_testings_umls
    //Still supporting genes from standard list so need to check for gene_id as well and sort after
    let gene_list =[]
    if(proband_genes){
    for(let gene of proband_genes){
      if(gene.gene_id){
        let standardGeneName = genes.find(genes=>genes.id == gene.gene_id)
        gene_list.push(
          { 'value': gene.gene_id, 'label': `${standardGeneName.gene}` }
        )}
        else if(gene.umls_id){
        gene_list.push(
          { 'value': gene.umls_id, 'label': `${gene.umls_name}` }
        )
      }
    }

    let uniqueGenes = Array.from(new Set(gene_list.map(a=>a.label))).map(name=>{
      return gene_list.find(a => a.label === name )
    })

    //sort all genes alphabetically
    uniqueGenes = uniqueGenes.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    if(uniqueGenes.length > 0){
      this.setState({ genetic_testing_options: uniqueGenes })
    }
    }
  }

  async buildSurveyOptions(){
    let surveys = this.props.static_list.surveys
    //Still supporting genes from standard list so need to check for gene_id as well and sort after
    let survey_list = []
    if (surveys) {
      for (let survey of surveys) {

        let fmt_category_name = survey.name.replace("-", " ").replace("_", " ").toUpperCase()

        let cat_options = [];
        for(let survey_category of survey.survey_categories){
          let options = [];

          for(let item of survey_category.question_workflows){
            let label = item.master_question.notification_label;
            if(!label){
              label = item.master_question.question_label;
            }
            if(!label){
              label = item.master_question.question_uuid.split('_').join(' ');
              label = label.charAt(0).toUpperCase() + label.slice(1);
            }
            if(item.master_question.type == 'label'){
              continue;
            }
            options.push({
              value: item.master_question.question_uuid,
              label: label,
              details: item,
              survey_name: survey.name
            })
          }
          cat_options = cat_options.concat(options)
        }

        survey_list.push({
          label: fmt_category_name,
          options: cat_options
        });
        // //collect all unique diseases of all probands
        // all_diseases_by_category = all_diseases_by_category.concat(diseases_by_category[category_name])
      }

      if (survey_list.length > 0) {
        this.setState({ survey_options: survey_list })
      }
    }
  }

  handleClickFieldOption(field){
    this.setState({ advanced_selected_field: field })
  }

  isGeneUMLS(disease_id) {
    disease_id = disease_id + "";
    const is_from_umls = disease_id.substr(0,1) === "C";
    return is_from_umls
  }

  getResults(gene) {
    if(gene !== null){
      gene = gene.value
    }
    if (gene === '') return []

    if(gene === 'not-tested') return []

    if(gene === 'Any') return result_any_gene

    return result_specific_gene
  }

  getQuestionOptions(){

  }

  getAnswerOptions(survey_question){
    let survey_answer_options = [];

    if(survey_question){
      if(survey_question.details.master_question.type == 'select_one' || survey_question.details.master_question.type == 'select_all_that_apply' || survey_question.details.master_question.type == 'long_list_search'){
        if(survey_question.details.master_question.master_question_choices){
          survey_question.details.master_question.master_question_choices.map(survey_choice => {
            let choice = {
              value: survey_choice.id,
              label: survey_choice.choice
            }
            survey_answer_options.push(choice)
          })
        }
      }
      else if(survey_question.details.master_question.type == 'integer' || survey_question.details.master_question.type == 'float'){
        survey_answer_options = survey_operator_options;
      }
      else if(survey_question.details.master_question.type == 'yes_or_no'){
        survey_answer_options = yes_no_options;
      }
      else if(survey_question.details.master_question.type == 'toggle'){
        survey_answer_options = toggle_options
      }
      else if(survey_question.details.master_question.type == 'date'){
        survey_answer_options = survey_operator_options
      }
      else {
        survey_answer_options = text_options;
      }
    }

    return survey_answer_options;
  }

  render() {
    let field = this.state.advanced_selected_field

    let disease = null;
    if(this.props.item.cancerType) {
      if(this.state.disease_options){
        for(let cat of this.state.disease_options){
          let selected = cat.options.find(option => option.value == this.props.item.cancerType);
          if(selected){
            disease = selected;
          }
        }
      }

      if(disease == null || disease == undefined){
        const disease_id = this.props.item.cancerType
        let static_selected_disease = this.props.static_list.diseases.find(
          item => item.disease_code === disease_id || item.umls_id === disease_id
        );

        if (static_selected_disease) {
          disease = {value: static_selected_disease.id, label: static_selected_disease.disease_name};
        }
        else{
            static_selected_disease = null;
            const is_from_umls = this.isDiseaseFromUmls(this.props.item.cancerType);
            let umls_id = this.props.item.cancerType
            if (is_from_umls) {
              let static_selected_disease = this.props.static_list.umls_diseases.find(
                item => item.umls_id === umls_id
              );
              if(static_selected_disease){
                disease = {value: static_selected_disease.umls, label: static_selected_disease.umls_name};
              }
            }
        }
        if (disease === null) {
          for(let option of this.state.other_options) {
            if(option.value === this.props.item.cancerType) {
              disease = option;
            }
          }
        }
      }
    }

    const operator_options = [
      {value: 'any_age', label: 'At any age'},
      {value: '<=', label: '≤'},
      {value: '<', label: '<'},
      {value: '=', label: '='},
      {value: '>', label: '>'},
      {value: '>=', label: '≥'},
    ];

    let chosen_operator = null;
    for(var operator of operator_options) {
      if(this.props.item.relationalOperator && this.props.item.relationalOperator === operator.value) {
        chosen_operator = operator;
      }
    }

    const regex = /^no_.*/g;
    let ageDisabled = false;
    let disabled = false;
    if (this.props.item.cancerType && typeof(this.props.item.cancerType) === 'string' && this.props.item.cancerType.search(regex) !== -1) {
      disabled = true;
      ageDisabled = true
    }
    if(this.props.item.relationalOperator == 'any_age'){
      ageDisabled = true
    }

    let options_placeholder = '';

    let gene = null
    if(this.props.item.gene){
      const gene_id = parseInt(this.props.item.gene, 10)
      let static_selected_gene = this.props.static_list.genetic_testings.find(
        item => item.id === gene_id
      );

      if(static_selected_gene){
        gene = {value: static_selected_gene.id, label: static_selected_gene.gene }
      }
      else if(this.isGeneUMLS(this.props.item.gene)){
        let umls_id = this.props.item.gene
        let static_selected_gene = this.props.static_list.genetic_testings_umls.find(
            item => item.umls_id === umls_id
          );
        if(static_selected_gene){
          gene = {value: static_selected_gene.umls_id, label: static_selected_gene.umls_name};
        }
      }
      else if(this.props.item.gene === 'Any'){
        gene = {value: 'Any', label: 'Tested for any gene'}
      }
      else if(this.props.item.gene === 'not-tested'){
        gene = {value: 'not-tested', label: 'Not Tested'}
      }
      else if(this.props.item.gene === 'unsure'){
        gene = {value: 'unsure', label: 'Unsure'}
      }
    }

    let chosen_result =  'Result'
    let resultOptions = this.getResults(gene)
    for(let result of resultOptions) {
      if(this.props.item.gene !== "" && this.props.item.result && this.props.item.result === result.value){
        chosen_result = result
      }
    }
    let variant_name = this.state.Variant !== null ? this.state.Variant : this.props.item.Variant

    let date_from = '';
    if (this.state.date_from) {
      try {
        date_from = this.state.date_from !== null ? new Date(this.state.date_from + "T12:00:00") : new Date(this.props.item.date_from + "T12:00:00");
      } catch (error) {
        date_from = '';
      }
    }

    let date_to = '';
    if (this.state.date_to) {
      try {
        date_to = this.state.date_to !== null ? new Date(this.state.date_to + "T12:00:00") : new Date(this.props.item.date_to + "T12:00:00");

      } catch (error) {
        date_to = '';
      }
    }

    let field_type = this.state.field_options.find(field => field.value == this.props.item.fieldType)
    let field_value = '';
    if (field_type){
      field_value = field_type.value;
    }

    let referral_value = null;
    if (this.props.item.referral){
      referral_value = result_referral.find(result => result.value == this.props.item.referral);
    }

    let survey_question = null;
    if(this.props.item.questionUUID){
      for(let opt of this.state.survey_options) {
        let q = opt.options.find(question => question.value == this.props.item.questionUUID);
        if(q){
          survey_question = q;
          break;
        }
      }
    }

    let survey_answer_options = this.getAnswerOptions(survey_question);

    let survey_answer = "";
    if(this.props.item.answer){
      survey_answer = survey_answer_options.find(opt => opt.value == this.props.item.answer)
    }

    let survey_answer_2 = this.state.survey_answer_2 !== null ? this.state.survey_answer_2 : this.props.item.answer_2
    let survey_answer_3 = this.state.survey_answer_3 !== null ? this.state.survey_answer_3 : this.props.item.answer_3

    let show_survey_input_field = false;
    if(survey_question){
      show_survey_input_field = (survey_question.details.master_question.type == 'integer' || survey_question.details.master_question.type == 'float' || survey_question.details.master_question.type == 'date' || ((survey_question.details.master_question.type == 'text_short' || survey_question.details.master_question.type == 'text_long' || survey_question.details.master_question.type == 'phone') && (survey_answer && (survey_answer.value == 'contains' || survey_answer.value == '='))))
    }

    let survey_input_field_placeholder =  show_survey_input_field && survey_question ? survey_question.details.master_question.type == 'integer' || survey_question.details.master_question.type == 'float' ? "00" : survey_question.details.master_question.type == 'date' ? "00/00/0000" : "Text" : ""
    return (
      <React.Fragment >

        { this.props.itemIndex > 0 &&
          <InputAndOr
            value={this.props.item.andOr}
            onChange={(e) => this.handleChange(e)}
          />
        }

        {/* field type for advanced filters */}

        <div className="col-md-2 col-xs-12 no-padding-left">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              id="select-diseases-container"
              onChange={field => this.handleSelectChange(field, 'fieldType')}
              value={field_type}
              options={this.state.field_options}
              formatGroupLabel={formatGroupLabel}
              placeholder="Select Field"
              isLoading={this.props.isLoading}
              isDisabled={this.props.disabled}
              components={this.props.components}
              inputValue={this.props.inputValue}
              onInputChange={this.props.onInputChange}
              autoFocus={false}
              openMenuOnFocus
              filterOption={filterOptions}
            />
        </div>

        {field_value == 'disease' &&
          <>
            <div className="col-md-3 col-xs-12 no-padding-left">
              <div className="custom-select">
                <ControlSelectDiseases
                  options={this.state.disease_options}
                  onChange={disease => this.handleSelectChange(disease, 'cancerType')}
                  value={disease}
                  placeholder={"Disease"}
                  autofocus={false}
                />
              </div>
            </div>
            <div className="col-md-2 col-xs-12 no-padding-left">
              <div className="custom-select">
                <ControlSelectDiseases
                  disabled={disabled}
                  options={operator_options}
                  onChange={disease => this.handleSelectChange(disease, 'relationalOperator')}
                  value={chosen_operator}
                  placeholder=""
                  autofocus={false}
                />
              </div>
            </div>
            <div className="col-md-2 col-xs-12 no-padding-left">
              <input
                disabled={ageDisabled}
                id={`age-input-disease-${this.props.setIndex}-${this.props.itemIndex}`}
                name="age"
                value={this.props.item.age}
                onChange={(e) => this.handleChange(e)}
                type="text" className="form-control" placeholder="Age at Dx" />
            </div>
          </>
        }

        {field_value == 'genetic_testing' &&
          <>
            <div className="col-md-3 col-xs-12 no-padding-left">
              <ControlSelectGene
                inputProps={{ id: `gene-name-dropdown-${this.props.setIndex}-${this.props.itemIndex}` }}
                options={
                  [{ value: 'Any', label: 'Tested for any gene' },
                  { value: 'not-tested', label: 'Not Tested' },
                  ...this.state.genetic_testing_options
                  ]}
                onChange={(item) => {
                  this.handleSelectChange(item, 'gene')
                }}
                value={gene}
                placeholder={"Gene"}
              />
            </div>

            <div className="col-md-2 col-xs-12 no-padding-left">
              <Dropdown
                inputProps={{ id: `gene-result-dropdown-${this.props.setIndex}-${this.props.itemIndex}` }}
                options={[
                  { value: '', label: " " },
                  ...resultOptions
                ]}
                onChange={(item) => {
                  this.handleSelectChange(item, 'result')
                }}
                value={chosen_result}
              />
            </div>

            <div className="col-md-2 col-xs-12 no-padding-left">
              <input type="text"
                className="form-control normal-input-text"
                value={variant_name}
                placeholder="Variant"
                id="Variant"
                name="Variant"
                onChange={(e) => this.handleStateChange(e)}
                onBlur={(e) => this.handleVariantBlur(e)}
              />
            </div>
          </>
        }

        {field_value == 'referral_criteria' &&
          <>
          <div className="col-md-5 col-xs-12 no-padding-left">
              <ControlSelectSurveys
                className="custom-react-select-container"
                classNamePrefix="custom-react-select"
                onChange={(item) => {
                  this.handleSelectChange(item, 'referral')
                }}
                value={referral_value}
                options={result_referral}
                formatGroupLabel={formatGroupLabel}
                placeholder={'Referral Criteria'}
              />
            </div>
          </>
        }

        {field_value == 'survey' &&
          <>
            <div className="col-md-3 col-xs-12 no-padding-left">
              <ControlSelectSurveys
                inputProps={{ id: `survey-question-dropdown-${this.props.setIndex}-${this.props.itemIndex}` }}
                options={
                  [...this.state.survey_options]}
                onChange={(item) => {
                  this.handleSelectChange(item, 'questionUUID')
                }}
                value={survey_question}
                placeholder={"Survey"}
              />
            </div>

            <div className="col-md-2 col-xs-12 no-padding-left">
              <Dropdown
                inputProps={{ id: `survey-choice-dropdown-${this.props.setIndex}-${this.props.itemIndex}` }}
                options={[
                  { value: '', label: " " },
                  ...survey_answer_options
                ]}
                onChange={(item) => {
                  this.handleSelectChange(item, 'answer')
                }}
                value={survey_answer}
              />
            </div>

          {show_survey_input_field &&
            <>
            {survey_question.details.master_question.type == 'date' ?
              <>
                <div className="col-md-2 col-xs-12 no-padding-left">
                  <DatePicker
                      id="survey_filters_date_from"
                      name="date_from"
                      onChange={(e) => this.onChangeDate(e, 'date_from')}
                      // onBlur={(e) => this.handleDateFromBlur(e)}
                      value={date_from}
                      className="form-control"
                      calendarIcon={null}
                      clearIcon={null}
                      dayPlaceholder={"dd"}
                      monthPlaceholder={"mm"}
                      yearPlaceholder={"yyyy"}
                      disableCalendar={true}
                      showLeadingZeros={true}
                      minDate={minDate}
                  />
                </div>
                {survey_answer && survey_answer.value == 'between' &&
                  <div className="col-md-2 col-xs-12 no-padding-left">
                    <DatePicker
                      id="survey_filters_date_to"
                      name="date_to"
                      onChange={(e) => this.onChangeDate(e, 'date_to')}
                      // onBlur={(e) => this.handleDateToBlur(e)}
                      value={date_to}
                      className="form-control"
                      calendarIcon={null}
                      clearIcon={null}
                      dayPlaceholder={"dd"}
                      monthPlaceholder={"mm"}
                      yearPlaceholder={"yyyy"}
                      disableCalendar={true}
                      showLeadingZeros={true}
                      minDate={minDate}
                    />
                  </div>
                }
              </>
              :
              <>
                <div className="col-md-2 col-xs-12 no-padding-left">
                  <input type="text"
                    className="form-control"
                    value={survey_answer_2}
                    placeholder={survey_input_field_placeholder}
                    id={`survey-answer-2-input-${this.props.setIndex}-${this.props.itemIndex}`}
                    name='survey_answer_2'
                    onChange={(e) => this.handleStateChange(e)}
                    onBlur={(e) => this.handleSurveyAnswer2Blur(e)}
                  />
                </div>
                {survey_answer && survey_answer.value == 'between' &&
                  <div className="col-md-2 col-xs-12 no-padding-left">
                    <input type="text"
                      className="form-control"
                      value={survey_answer_3}
                      placeholder={survey_input_field_placeholder}
                      id={`survey-answer-3-input-${this.props.setIndex}-${this.props.itemIndex}`}
                      name='survey_answer_3'
                      onChange={(e) => this.handleStateChange(e)}
                      onBlur={(e) => this.handleSurveyAnswer3Blur(e)}
                    /></div>
                }
              </>
            }
            </>
          }
          </>
        }

        <div className="col-md-1 col-xs-12 no-padding-left margin-one">

          {this.props.showDeleteButton &&
            <a onClick={() => this.props.onDeleteItemClick()}
              href="#"><i className="fa fa-lg fa-close"></i></a>
          }

          {this.props.showAddButton &&
            <a href="#" onClick={() => this.props.onAddItemClick()}>
              <i className="fa fa-lg fa-plus"></i>
            </a>
          }

        </div>
      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  static_list: state.static_list,
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(AdvancedFilterInput);
