import React, { Component } from 'react';
import ReactDOM from 'react-dom';

interface State {

}
interface Props {
    onClose: (abort_or_wait: string) => void;
    resetQueryStartTime: () => void;
    setQueryLoopCancelled: (value: boolean) => void;
    ac: any;
}
const QueryTimeoutModal: React.FC<Props> = (props) =>{

    const handleWait = () => {
        props.resetQueryStartTime();
        props.onClose('wait');
    }

    const handleAbort = () => {
        if(props.ac){
            props.ac.abort();
        }
        props.setQueryLoopCancelled(true);
        props.onClose('abort');
    }


    return ReactDOM.createPortal(
        <div
            onClick={() => props.onClose('wait')}
            style={{ display: 'block' }}
            className="modal fade in"
            role="dialog"
        >
        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button onClick={() => props.onClose('wait')} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="fa fa-close"></i>
                    </button>
                    <h4 className="modal-title">Filter Timeout</h4>
                </div>
                <div className="modal-body">
                    <p>It seems like filtering is taking longer than expected. </p>
                    <p>Do you want to continue or cancel?</p>

                </div>
                <div className="modal-footer" style={{textAlign: 'center'}}>
              <button onClick={() => handleWait()} className="btn btn-light-outline no-margin-right">Continue</button>
              <button onClick={() => handleAbort()} className="btn btn-light-outline no-margin-right">Cancel</button>
            </div>
            </div>

        </div>
        </div>,
      document.body
    );
}

export default QueryTimeoutModal
