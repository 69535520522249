import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import { createUUID } from '../react-flow-pedigree/utils';

interface Props {
    category: any;
    categoryIndex: number;
    pageIndex: number;
    totalCategories: number;
    questionChoices: Array<any>;
    handleAddQuestion: (categoryIndex: number) => void;
    handleDeleteQuestion: (categoryIndex: number, questionIndex: number) => void;
    handleChangeQuestion: (categoryIndex: number, questionIndex: number, master_question_id: number) => void;
    setShowCategoryModal: (showCategoryModal: boolean) => void;
    onDragOver: (event: any) => void;
    onQuestionDrop: (event: any) => void;
    onDrag: (event: any, type: string, pageIndex?: number, categoryIndex?: number, questionIndex?: number) => void;
}

interface SurveyQuestionFieldProps {
    question: any;
    questionIndex: number;
    categoryIndex: number;
    pageIndex: number;
    totalCategories: number;
    totalQuestions: number;
    questionChoices: Array<any>;
    handleAddQuestion: (categoryIndex: number) => void;
    handleDeleteQuestion: (categoryIndex: number, questionIndex: number) => void;
    handleChangeQuestion: (categoryIndex: number, questionIndex: number, master_question_id: number) => void;
    onDragOver: (event: any) => void;
    onQuestionDrop: (event: any) => void;
    onDrag: (event: any, type: string, pageIndex?: number, categoryIndex?: number, questionIndex?: number) => void;
}

interface State {
    surveyInfo: any;
    showCategoryModal: boolean;
}

class SurveyCategoryField extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            surveyInfo: {
                pages: [
                    {
                        id: '1',
                        categories: [
                            {
                                id: '',
                                name: '',
                                label: '',
                                translation_key: '',
                                questions: [
                                    {
                                        id: '',
                                        type: '',
                                        question: '',
                                        options: [
                                            {
                                                id: '',
                                                option: ''
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
            },
            showCategoryModal: false,
        };
    }

    render() {
        return (
            <React.Fragment>

                <div className="panel-form-3" style={{ borderRadius: 12, width: '95%', marginTop: 10 }}>
                    <div className="row vcenter">
                        <div className="col-md-6 col-xs-12" style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 style={{ color: '#6A358A' }}>{this.props.category.name}</h3>
                            <a style={{ marginLeft: 10 }} onClick={() => this.props.setShowCategoryModal(true)}>
                                <i className="fa fa-lg fa-edit"></i>
                            </a>
                        </div>
                        <div className="col-md-4 col-md-offset-2 justify-right hidden"></div>
                    </div>
                    {this.props.category.questions.map((question: any, questionIndex: number) => {
                        return (
                            <div key={createUUID()}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SurveyQuestionField
                                        question={question}
                                        questionIndex={questionIndex}
                                        pageIndex={this.props.pageIndex}
                                        categoryIndex={this.props.categoryIndex}
                                        totalCategories={this.props.totalCategories}
                                        totalQuestions={this.props.category.questions.length}
                                        questionChoices={this.props.questionChoices}
                                        handleAddQuestion={(categoryIndex) => this.props.handleAddQuestion(categoryIndex)}
                                        handleDeleteQuestion={(categoryIndex, questionIndex) => this.props.handleDeleteQuestion(categoryIndex, questionIndex)}
                                        handleChangeQuestion={(categoryIndex, questionIndex, master_question_id) => this.props.handleChangeQuestion(categoryIndex, questionIndex, master_question_id)}
                                        onDragOver={(event: any) => this.props.onDragOver(event)}
                                        onQuestionDrop={(event) => this.props.onQuestionDrop(event)}
                                        onDrag={(event, type, pageIndex, categoryIndex, questionIndex) => this.props.onDrag(event, type, pageIndex, categoryIndex, questionIndex)}
                                    />
                                </div>
                            </div>)
                    })}
                </div>
            </React.Fragment>

        );
    };
}

const SurveyQuestionField = (props: SurveyQuestionFieldProps) => {

    let value = props.questionChoices.find((choice) => choice.value === props.question.master_question_id)
    return (

            <div className={"row margin-one padding-left--15"} style={{ width: '100%' }}>

                <div className="col-md-5 col-xs-12 no-padding-left">
                    <div className="custom-select">
                        <Select
                            className="custom-react-select-container"
                            classNamePrefix="custom-react-select"
                            onChange={field => props.handleChangeQuestion(props.categoryIndex, props.questionIndex, field.value)}
                            value={value}
                            options={props.questionChoices}
                            placeholder={"Select Question"}
                            autoFocus={false}
                            openMenuOnFocus
                        />
                    </div>
                </div>

                <div className="col-md-1 col-xs-12 no-padding-left margin-one" style={{ display: 'flex' }}>

                    <a onClick={() => props.handleDeleteQuestion(props.categoryIndex, props.questionIndex)}>
                        <i className="fa fa-lg fa-close"></i>
                    </a>

                    {props.totalQuestions - 1 === props.questionIndex &&
                        <a onClick={() => props.handleAddQuestion(props.categoryIndex)}>
                            <i className="fa fa-lg fa-plus"></i>
                        </a>
                    }

                    <a
                        data-type="question"
                        id={`${props.pageIndex}-${props.categoryIndex}-${props.questionIndex}`}
                        onDragOver={(event: any) => props.onDragOver(event)}
                        onDrop={(event) => props.onQuestionDrop(event)}
                        onDrag={(event) => props.onDrag(event, 'question', props.pageIndex, props.categoryIndex, props.questionIndex)}
                        draggable>
                        <svg className="list-icon" xmlns="http://www.w3.org/2000/svg" height="1.5em" width="1.5em" viewBox="0 50 180 600">
                            <path d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z" />
                        </svg>
                    </a>

                </div>

            </div>
    )
}


const redux_state = (state: any) => ({
    searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyCategoryField);
